import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Permission } from './permission.enum';
import { RouteService } from './route.service';

export class AuthorizationGuard implements CanActivate {
  constructor(private routeService: RouteService, private requiredPermission: Permission) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.routeService.RequiredPermission = this.requiredPermission;

    return true;
  }
}
