import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material';
import { TaniDashboardProjectService } from 'src/app/services/tani-dashboard-project.service';
import { LoadingSpinnerService } from 'src/app/util/loading-spinner/loading-spinner.service';
import { TimeUtil } from 'src/app/util/time-util';
import { CustomMatPaginatorComponent } from '../../custom-mat-paginator/custom-mat-paginator.component';
import { Router } from '@angular/router';
import { TimezoneService } from 'src/app/services/timezone.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tani-dashboard-projects',
  templateUrl: './tani-dashboard-projects.component.html',
  styleUrls: ['./tani-dashboard-projects.component.css']
})
export class TaniDashboardProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(CustomMatPaginatorComponent) paginator: CustomMatPaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;

  public numberOfProjects = 0;
  public dateFrom = new FormControl(this.timeUtil.getDateTimeUTCFormat((new Date())));
  public dateTo = new FormControl(this.timeUtil.getDateTimeUTCFormat((new Date())));
  public dateValidationMessage = '';
  public projectCompletedFilter = false;
  public columnsToDisplay = [
    'id', 'name', 'sessionProgress', 'nextSessionStartTime', 'endClient',
    'moderators', 'researchCoordinators', 'projectManager', 'subscriptionType'
  ];
  private timezoneServiceSubscription: Subscription;

  constructor(public dataSource: TaniDashboardProjectService,
    private spinService: LoadingSpinnerService,
    private timeUtil: TimeUtil,
    private router: Router,
    private timezoneService: TimezoneService
  ){}

  ngAfterViewInit(): void {
    this.loadData();
  }

  ngOnInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadData();
    });
    this.paginator.pageSize = 100;
    this.paginator.page.subscribe(() => {
      this.loadData();
    });
    this.timezoneServiceSubscription = this.timezoneService.timezone.subscribe(()=> {
      this.loadData();
    })
  }

  loadData() {
    if (this.timeUtil.isDateToBeforeDateFrom(this.dateTo.value, this.dateFrom.value)) {
      this.dateValidationMessage = 'Invalid date period. Date to should be after Date from.';
      return;
    }
    this.dateValidationMessage = '';
    this.dataSource.loadData(this.sort.active, this.sort.direction, this.dateFrom.value, this.dateTo.value, this.projectCompletedFilter, this.paginator.pageIndex, this.paginator.pageSize)
    .subscribe(r => {
      this.numberOfProjects = this.paginator.length = r.totalCount;
      this.paginator.moreExist = r.moreExists;
    });
  }

  resetFilter(){
    this.dateValidationMessage = '';
    this.dateFrom.reset(this.timeUtil.getDateTimeUTCFormat((new Date())));
    this.dateTo.reset(this.timeUtil.getDateTimeUTCFormat(new Date()));
    this.projectCompletedFilter = false;
    this.loadData();
  }

  getRecord(data): void {
    this.router.navigate(['/tani-sessions'], { queryParams: { 'project-id': data.id } });
  }

  ngOnDestroy(): void {
    this.timezoneServiceSubscription.unsubscribe();
  }
}
