import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LoginService } from '../services/login.service';
import { AppConfigService } from '../services/app-config.service';
import { EncoderService } from '../services/encoder.service';
import { LoadingSpinnerService } from '../util/loading-spinner/loading-spinner.service';


export class UploadDataSource implements DataSource<any> {
    private dataSessionSubject = new BehaviorSubject<string[]>([]);
    private subs: { [key: string]: Subscription } = {};

    constructor(private appConfig: AppConfigService, private loginService: LoginService, private encoderService: EncoderService,
                private spinnerService: LoadingSpinnerService, private sort: MatSort, private paginator: MatPaginator, private encoderKey) {
      this.paginator.length = 100;
      this.paginator.pageSize = 100;
      this.paginator.pageIndex = 0;

      // Subscription for sort event
      this.subs['sortSubscription'] = this.sort.sortChange
        .asObservable()
        .subscribe(result => {
          // TODO in version 2
        });

      // Subscription for page event
      this.subs['paginatorSubscription'] = this.paginator.page.asObservable().subscribe(res => {
        this.getResults();
      });
    }

    connect(): Observable<string[]> {
      this.getResults();
      return this.dataSessionSubject.asObservable();
    }

   /**
   * @ngdoc method
   * @name getResults
   * @methodOf UploadDataSource
   * @description This method should get results from server.
   */
    public getResults() {
      this.spinnerService.spinnerSubject.next(true);
      this.encoderService.getFiles(this.encoderKey).subscribe(response => {
        this.handleResponse(response);
      });
    }

    disconnect() {}

  /**
   * @ngdoc method
   * @name handleResponse
   * @methodOf UploadDataSource
   * @description This method should handle response from server
   */
  private handleResponse(response) {
    this.dataSessionSubject.next(response.filenames);
    this.spinnerService.spinnerSubject.next(false);
  }

  public unsubscribeAll(): void {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
  }
}
