import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AppConfigService } from "./app-config.service";
import { TimeUtil } from 'src/app/util/time-util';
import { TaniProjectTableModelDto } from "../models/tani/tani-project-table-dto";
import { TaniProjectTableModel } from "../models/tani/tani-project-table";

@Injectable()
export class TaniDashboardProjectService implements DataSource<TaniProjectTableModel> {
    private dataSource: BehaviorSubject<TaniProjectTableModel[]> = new BehaviorSubject<TaniProjectTableModel[]>(null);
    readonly pageOffset = 1;

    constructor(private httpClient: HttpClient,
        private timeUtil: TimeUtil,
        private appConfig: AppConfigService,
    ){}

    connect(collectionViewer: CollectionViewer): Observable<TaniProjectTableModel[]> {
        return this.dataSource.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        return this.dataSource.complete();
    }

    public loadData(
      sortBy: string,
      sortDirection: string,
      dateFrom: Date,
      dateTo: Date,
      showCompleted: boolean,
      pageIndex: number, itemsPerPage: number) {
        const httpParams = {
            'from': this.timeUtil.getDateFromForQueryParam(dateFrom),
            'to': this.timeUtil.getDateToForQueryParam(dateTo),
            'page': (pageIndex + this.pageOffset).toString(),
            'size': itemsPerPage,
            'sortBy': sortBy,
            'order': sortDirection,
            'showCompleted': showCompleted
        };

        const hdr = this.appConfig.getHeaders(httpParams);

        return this.httpClient.get<TaniProjectTableModelDto>(
            this.appConfig.getWalnutUrl('/tani/projects'), hdr)
        .pipe(tap(res => {
            this.dataSource.next(res.projects);
        }));
    }
}
