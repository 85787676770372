import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { AppConfigService } from '../../../services/app-config.service';
import { LoginService } from '../../../services/login.service';
import { EncoderService } from '../../../services/encoder.service';
import { PaginationService } from '../../../services/pagination.service';
import { UploadDataSource } from '../../../models/upload-data-source';
import { Encoder } from '../../../models/encoder';
import { LoadingSpinnerService } from '../../../util/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public columnsToDisplay = ['file', 'upload'];
  public pageSize = 10;
  public dataSource;
  public encoder: Encoder;

  constructor(private appConfig: AppConfigService, private loginService: LoginService, private encoderService: EncoderService,
              private router: Router, public spinnerService: LoadingSpinnerService, private paginationService: PaginationService,
              private snackBar: MatSnackBar) {}


  ngOnInit() {
    this.encoderService.encoderFileUploadDataOutput.subscribe(encoder => this.encoder = encoder);
    this.getDataSource();
  }

  ngOnDestroy(): void {
    this.dataSource.unsubscribeAll();
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf UploadComponent
   * @description This method should set dataSource
   */
  public getDataSource() {
    this.dataSource = new UploadDataSource(this.appConfig, this.loginService, this.encoderService,
                                           this.spinnerService, this.sort, this.paginator, this.encoder.encoderId);
    this.spinnerService.spinnerSubject.next(false);
  }

  /**
   * @ngdoc method
   * @name uploadFile
   * @methodOf UploadComponent
   * @description This method should upload selected file
   */
  public uploadFile(fileName) {

    this.spinnerService.spinnerSubject.next(true);
    this.encoderService.uploadFile(fileName, this.encoder.encoderId).subscribe(res => {
        setTimeout(() => {
          this.getDataSource();
          this.snackBar.open('File successfully uploaded.', 'Ok', {duration: 2000});
        }, 2000);
      });

  }

}
