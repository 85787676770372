import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { MandatoryClientDataDialogConfirmationModel } from "../models/mandatory-client-data-dialog-confirmation-model";
import { MandatoryClientDataModel } from "../models/mandatory-client-data-model";
import { AppConfigService } from "./app-config.service";

@Injectable()
export class MandatoryClientDataService{
    private dataSource: BehaviorSubject<MandatoryClientDataModel> = new BehaviorSubject<MandatoryClientDataModel>(null);

    constructor(private httpClient: HttpClient, private appConfig: AppConfigService){}

    private getMandatoryDataUrlPath(clientId: string): string{
        return  `/mandatoryclientdata/${encodeURIComponent(clientId)}`;
    }

    public loadData(clientId: string){
        return this.httpClient.get<MandatoryClientDataModel>(
            this.appConfig.getWalnutUrl(this.getMandatoryDataUrlPath(clientId)), this.appConfig.getHeaders())
        .pipe(tap(res => {
            this.dataSource.next(res);
        }));
    }

    public setData(mandatoryDialogData: MandatoryClientDataDialogConfirmationModel){
        return this.httpClient.put<MandatoryClientDataModel>(
            this.appConfig.getWalnutUrl(this.getMandatoryDataUrlPath(mandatoryDialogData.clientId)),
            mandatoryDialogData.mandatoryData,
            this.appConfig.getHeaders())
            .pipe(map(res => {}));
    }
}
