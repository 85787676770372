import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ElementRef, OnDestroy } from '@angular/core';
import { MatSort, MatPaginator, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';
import { EncoderService } from '../../services/encoder.service';
import { EncoderDataSource} from '../../models/encoder-data-source';
import { LoginService } from '../../services/login.service';
import { AppConfigService } from '../../services/app-config.service';
import { PaginationService } from '../../services/pagination.service';
import { AgentUpdateService } from '../../services/agent-update.service';
import { SelectedVersionsDialogComponent } from './selected-versions-dialog/selected-versions-dialog.component';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { DynamicPlaceholders } from 'src/app/models/search-filter.model';
import { keyframes } from '@angular/animations';

@Component({
  selector: 'app-encoders-version',
  templateUrl: './encoders-version.component.html',
  styleUrls: ['./encoders-version.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * @ngdoc component
 * @name encoders-version.component:EncodersVersionComponent
 * @description This component shows list of Encoders versions
 */
export class EncodersVersionComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('default', { read: ElementRef }) defaultHeader: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public columnsToDisplay = ['name', 'pcName', 'site', 'room', 'currentVersion', 'updateVersion', 'status', 'update', 'delete'];
  public encoderTypeFlag = 5;
  public pageSize = 10;
  public dataSource: EncoderDataSource;
  public selectedListStatus = null;
  public selectedEncodersForType: Map<string, number[]> = new Map<string, number[]>();
  public pageIndexForType: Map<string, number> = new Map<string, number>();
  public currentRoute: string = this.router.url;
  public encoderTypes: {value: string, label: string}[] = [
    {value: 'software', label: 'Software encoders'},
    {value: 'hardware', label: 'Hardware encoders'}
  ];
  public selectedType = 'software';
  public searchPlaceholder: DynamicPlaceholders = {
    keyWord: 'Search by Name, PC name, Facility, Room or Current version',
  };

  selectedVersionDialogRef: MatDialogRef<SelectedVersionsDialogComponent>;
  deleteModalDialogRef: MatDialogRef<DeleteModalComponent>;

  constructor(
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private encoderService: EncoderService,
    private router: Router,
    public spinnerService: LoadingSpinnerService,
    private paginationService: PaginationService,
    public dialog: MatDialog,
    public agentUpdateService: AgentUpdateService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getDataSource();
  }

  ngOnDestroy(): void {
    this.dataSource.unsubscribeAll();
  }

  /**
   * @ngdoc method
   * @name filterTable
   * @methodOf EncodersVersionComponent
   * @description This method should change the table filter
   */
  public changeSystemType(systemType: string): void {
    this.setPageIndex(systemType);
    this.dataSource.setSystemType(this.selectedType);
    this.dataSource.reloadData();
  }

  public setPageIndex(systemType: string) {
    this.pageIndexForType.set(this.selectedType, this.paginator.pageIndex);
    if (!this.pageIndexForType.has(systemType)) {
      this.pageIndexForType.set(systemType, 0);
    }
    this.selectedType = systemType;
    this.paginator.pageIndex = this.pageIndexForType.get(systemType);
  }

  public isSelected(encId: number): boolean {
    this.checkDataStructure();
    return this.selectedEncodersForType.get(this.selectedType).includes(encId);
  }

  private checkDataStructure() {
    if (!this.selectedEncodersForType.has(this.selectedType)) {
      this.selectedEncodersForType.set(this.selectedType, []);
    }
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf EncodersVersionComponent
   * @description This method should set dataSource
   */
  public getDataSource(): void {
    let key = '';
    const projectId = '';
    const encoderVersionStatus = this.encoderService.encodersVersionStatus.getValue() || 'ALL';

    this.encoderService.encodersVersionKeyword.subscribe(keyWord => {
      key = keyWord !== undefined && keyWord !== null ? keyWord : '';
      this.paginator.pageIndex = keyWord
          ? 0 : this.paginationService.encodersVersionPageNumber;
    });

    this.dataSource = new EncoderDataSource(
      this.paginationService,
      this.appConfig,
      this.loginService,
      this.encoderService,
      this.spinnerService,
      this.sort,
      this.paginator,
      this.currentRoute,
      this.selectedListStatus,
      key,
      projectId,
      this.selectedType,
      encoderVersionStatus
    );
  }
  public getSelectedEncoders(): number[] {
    this.checkDataStructure();
    return this.selectedEncodersForType.get(this.selectedType);
  }

  /**
   * @ngdoc method
   * @name detectDataSourceChange
   * @methodOf EncodersComponent
   * @description This method should get new data source form project-search component
   */
  public detectDataSourceChange(event: EncoderDataSource): void {
    this.dataSource = event;
  }

  /**
   * @ngdoc method
   * @name selectEncoder
   * @methodOf EncodersVersionComponent
   * @description This method should select/deselect encoder
   */
  public selectEncoder(encoderId): void {
    if (this.isSelected(encoderId)) {
      const index = this.getSelectedEncoders().indexOf(encoderId);
      if (index > -1) {
        this.getSelectedEncoders().splice(index, 1);
      }
    } else {
      this.getSelectedEncoders().push(encoderId);
    }
  }

  /**
   * @ngdoc method
   * @name updateSelectedEncoders
   * @methodOf EncodersVersionComponent
   * @description This method should open modal dialog on 'Update selected' button click
   */
  public updateSelectedEncoders(): void {
    this.spinnerService.spinnerSubject.next(true);

    this.agentUpdateService.modalType.next('Update');
    this.selectedVersionDialogRef = this.dialog.open(SelectedVersionsDialogComponent, {
      hasBackdrop: false,
      data: {
        systemType: this.selectedType
      }
    });

    this.selectedVersionDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'Update') {

          let version = '';

          this.agentUpdateService.softwareId.subscribe(softwareId => {
            version = softwareId;
          });

          this.agentUpdateService.assignEncoders(this.getSelectedEncoders(), version).subscribe(res => {
              this.spinnerService.spinnerSubject.next(true);
              setTimeout(() => {
                this.selectedEncodersForType.set(this.selectedType, []);
                this.dataSource.connect();
                this.spinnerService.spinnerSubject.next(false);
                this.snackBar.open('Version successfully assigned!', 'Ok', {duration: 2000});
              }, 2000);
            });
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });
  }


  /**
   * @ngdoc method
   * @name deleteEncoder
   * @methodOf EncodersVersionComponent
   * @description This method should delete selected encoder
   */
  public deleteEncoder(encoderId) {

    this.spinnerService.spinnerSubject.next(true);

    this.deleteModalDialogRef = this.dialog.open(DeleteModalComponent, {
      hasBackdrop: false
    });

    this.deleteModalDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'OK') {

          this.spinnerService.spinnerSubject.next(true);
          this.encoderService.deleteEncoder(encoderId).subscribe(res => { this.handleAfterDeleteEncoder(encoderId); });
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });
  }

  /**
   * @ngdoc method
   * @name mouseEnter
   * @methodOf EncodersVersionComponent
   * @description This method should show delete icon if row is hovered
   */
  public mouseEnter(encoder) {
    encoder.showImage = true;
  }

  /**
   * @ngdoc method
   * @name mouseLeave
   * @methodOf EncodersVersionComponent
   * @description This method should hide delete icon if row is hovered
   */
  public mouseLeave(encoder) {
    encoder.showImage = false;
  }

  /**
   * @ngdoc method
   * @name handleAfterDeleteEncoder
   * @methodOf EncodersVersionComponent
   * @description This method should handle response after delete version
   */
  public handleAfterDeleteEncoder(encoderId): void {
    setTimeout(() => {
      this.selectedEncodersForType.set(this.selectedType, this.getSelectedEncoders().filter(id => id !== encoderId));
      this.dataSource.reloadData();
      this.spinnerService.spinnerSubject.next(false);
      this.snackBar.open('Encoder successfully deleted!', 'Ok', {duration: 2000});
    }, 2000);
  }
}
