import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService} from './app-config.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Version } from './../models/encoder';
import { GhQueryEncoder } from './../util/gh.query.encoder';
import { URLSearchParams } from '@angular/http';

@Injectable()
export class AgentUpdateService {

  public modalType = new BehaviorSubject<string>(null);
  public softwareId = new BehaviorSubject<string>(null);
  public versionsTotalCount = new BehaviorSubject<number>(null);
  public versionsForDelete = new BehaviorSubject<string[]>(null);
  public filesTotalCount = new BehaviorSubject<number>(null);

  constructor(private http: HttpClient, private appConfig: AppConfigService) { }

   /**
     * @ngdoc method
     * @name getSoftwareVersions
     * @methodOf AgentUpdateService
     * @description This method should return software versions
     */
     public getSoftwareVersions(page, size, sortField, order, systemType: string): Observable<string[]> {
       let body = new HttpParams();
       body = body.set('page', page + 1);
       body = body.set('size', size);
       body = body.set('systemType', systemType);

     if (sortField !== undefined && sortField.length !== 0) {
       body = body.set('field', sortField);
     }

     if (order !== undefined && order.length !== 0) {
       body = body.set('order', order);
     }

     return this.http.get<any>(this.appConfig.getUrl(`/update/softwareversions`), this.appConfig.getHeaders(body)).pipe(map(res => {
       const versions = this.handleSoftwareVersionsResponse(res);
       return versions;
     }));
  }

  /**
   * @ngdoc method
   * @name handleSoftwareVersionsResponse
   * @methodOf AgentUpdateService
   * @description This method should handle response for software versions service call
   */
  public handleSoftwareVersionsResponse(res) {

    const versions = [];

    res.softwareVersions.forEach(function(version) {
      const versionObject = { id: 0, name: '', systemType: '' };
      versionObject.id = version.id;
      versionObject.name = version.version;
      versionObject.systemType = version.systemType;
      versions.push(versionObject);
    });

    this.versionsTotalCount.next(res.totalCount);

    return versions;
  }

  /**
   * @ngdoc method
   * @name assignEncoders
   * @params encoders, version
   * @methodOf AgentUpdateService
   * @description This method should assign encoder version
   */
  public assignEncoders(encoders, softwareUpdateId): Observable<any> {
    return this.http.post(this.appConfig.getUrl(`/update/assignencoders`),
      {'encoders': encoders, 'softwareUpdateId': softwareUpdateId}, this.appConfig.getHeaders()).pipe(map(res => {}));
  }

  /**
   * @ngdoc method
   * @name deleteVersions
   * @params versions
   * @methodOf AgentUpdateService
   * @description This method should delete versions
   */
  public deleteVersions(ids): Observable<any> {
    const urlSearchParams = new URLSearchParams('', new GhQueryEncoder());

    ids.forEach(function(id) {
      urlSearchParams.append('id', id);
    });

    const body = urlSearchParams.toString();
    return this.http.delete(this.appConfig.getUrl(`/update/softwareversion/delete?` + body),
      this.appConfig.getHeaders()).pipe(map(res => { return res; }));
  }

  /**
   * @ngdoc method
   * @name getFiles
   * @methodOf AgentUpdateService
   * @description This method should return files
   */
  public getFiles(sortField, order): Observable<any[]> {

    let body = new HttpParams();

    if (sortField !== undefined && sortField.length !== 0) {
      body = body.set('field', sortField);
    }

    if (order !== undefined && order.length !== 0) {
      body = body.set('order', order);
    }

    return this.http.get<any[]>(this.appConfig.getUrl(`/update/updatefile/withoutupdate`), this.appConfig.getHeaders(body)).pipe(map(files => {
      this.filesTotalCount.next(files.length);
      return files;
    }));
  }

  /**
   * @ngdoc method
   * @name deleteFiles
   * @params versions
   * @methodOf AgentUpdateService
   * @description This method should delete files
   */
  public deleteFiles(filesForDelete): Observable<any> {
    const urlSearchParams = new URLSearchParams('', new GhQueryEncoder());
    filesForDelete.forEach(function(file) {
      urlSearchParams.append('id', file.id);
    });

    const body = urlSearchParams.toString();
    return this.http.delete(this.appConfig.getUrl(`/update/updatefiles/delete?` + body),
      this.appConfig.getHeaders()).pipe(map(res => { return res; }));
  }

  /**
   * @ngdoc method
   * @name createVersion
   * @params version
   * @methodOf AgentUpdateService
   * @description This method should update encoder
   */
  public createVersion(version): Observable<any> {
    return this.http.put<Version>(this.appConfig.getUrl(`/update/softwareversion/save`), version,
      this.appConfig.getHeaders()).pipe(map(res => {}));
  }

  /**
   * @ngdoc method
   * @name createVersion
   * @params version
   * @methodOf AgentUpdateService
   * @description This method should update encoder
   */
  public updateArgumentLine(file): Observable<any> {
    return this.http.post(this.appConfig.getUrl(`/update/updatefile/update`), file,
      this.appConfig.getHeaders()).pipe(map(res => {}));
  }
}
