import { Component, OnInit, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatSort, MatPaginator, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { AppConfigService } from '../../../services/app-config.service';
import { AgentUpdateService } from '../../../services/agent-update.service';
import { UploadFileDialogComponent } from './../upload-file-dialog/upload-file-dialog.component';
import { UploadFilesDataSource } from '../../../models/upload-files-data-source';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { Version } from '../../../models/encoder';
import { TimeUtil } from '../../../util/time-util';
import { LoadingSpinnerService } from '../../../util/loading-spinner/loading-spinner.service';
import { FormGroup,  FormBuilder,  Validators  } from '@angular/forms';

import 'rxjs';
import 'rxjs/add/observable/fromEvent';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * @ngdoc component
 * @name upload-files.component:UploadFilesComponent
 * @description This component shows list of uploaded files
 */
export class UploadFilesComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public columnsToDisplay = ['file', 'argumentLine', 'editIcon', 'create'];
  public pageSize = 10;
  public dataSource: UploadFilesDataSource;
  public selectedFiles = [];
  public argumentLine = '';
  public versionGroup: FormGroup;

  uploadFileDialogRef: MatDialogRef<UploadFileDialogComponent>;
  deleteModalDialogRef: MatDialogRef<DeleteModalComponent>;

  constructor(
    private appConfig: AppConfigService, private loginService: LoginService, private router: Router,
    public spinnerService: LoadingSpinnerService,  private timeUtil: TimeUtil, public dialog: MatDialog,
    public agentUpdateService: AgentUpdateService, private snackBar: MatSnackBar, private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.dataSource = new UploadFilesDataSource(this.appConfig, this.loginService, this.agentUpdateService,
      this.spinnerService, this.sort, this.paginator);
  }

  ngOnDestroy(): void {
    this.dataSource.unsubscribeAll();
  }

  createForm(): void {
    this.versionGroup = this.fb.group({
      versionName: ['', Validators.compose([Validators.pattern(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/)])]
    });
  }

  /**
   * @ngdoc method
   * @name selectFiles
   * @methodOf UploadFilesComponent
   * @description This method should select/deselect files
   */
  public selectFiles(file) {
    if (this.selectedFiles.includes(file)) {
      const index = this.selectedFiles.indexOf(file);
      if (index > -1) {
        this.selectedFiles.splice(index, 1);
      }
    } else if (file.systemType === 'Hardware') {
      this.versionGroup.get('versionName').setValidators([Validators.pattern(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/)]);
      this.selectedFiles = [];
      this.selectedFiles.push(file);
    } else if (file.systemType === 'Software') {
      this.versionGroup.get('versionName').setValidators([Validators.pattern(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/)]);
      this.selectedFiles = this.selectedFiles.filter(({systemType}) => systemType !== 'Hardware');
      this.selectedFiles.push(file);
    }
    this.cancelCreateVersion();
  }

  /**
   * @ngdoc method
   * @name createVersion
   * @methodOf UploadFilesComponent
   * @description This method should create version for selected files
   */
  public createVersion() {
    const version = this.getVersionObject();

    if (this.versionGroup.valid || this.versionGroup.controls.versionName.value) {
      this.spinnerService.spinnerSubject.next(true);
      this.agentUpdateService.createVersion(version).subscribe(res => { this.handleResponse('Version successfully created.'); });
    }
  }

  /**
   * @ngdoc method
   * @name createVersionObject
   * @methodOf UploadFilesComponent
   * @description This method should create version object
   */

  private getVersionObject() {
    const version = new Version();
    version.id = 0;
    version.version = this.versionGroup.controls.versionName.value;
    version.updateFiles = this.selectedFiles;
    version.releaseDate = this.timeUtil.getDateTimeUTCFormat(new Date());
    version.systemType = this.selectedFiles.some(file => file.systemType === 'Hardware') ? 'Hardware' : 'Software';
    return version;
  }

  /**
   * @ngdoc method
   * @name cancelCreateVersion
   * @methodOf UploadFilesComponent
   * @description This method should cancel create version action
   */
  public cancelCreateVersion() {
    this.versionGroup.reset();
  }

  /**
   * @ngdoc method
   * @name updateFile
   * @methodOf UploadFilesComponent
   * @description This method should update file data
   */
  public updateFile(file) {

    file.arguments = file.newArgumentLine;

    this.spinnerService.spinnerSubject.next(true);
    this.agentUpdateService.updateArgumentLine(file).subscribe(res => {
        setTimeout(() => {
          this.spinnerService.spinnerSubject.next(false);
          this.snackBar.open('Argument line successfully changed.', 'Ok', {duration: 2000});
        }, 2000);
      });
  }

  /**
   * @ngdoc method
   * @name uploadFile
   * @methodOf UploadFilesComponent
   * @description This method should open modal for file upload on button click
   */
  public uploadFile() {
    this.spinnerService.spinnerSubject.next(true);

    this.uploadFileDialogRef = this.dialog.open(UploadFileDialogComponent, {
      hasBackdrop: false
    });

    this.uploadFileDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'Upload') {
           setTimeout(() => {
             this.dataSource.reloadData();
             this.snackBar.open('File successfully uploaded.', 'Ok', {duration: 2000});
          }, 2000);
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        } else {
          this.dataSource.reloadData();
          this.snackBar.open('Your file is not uploaded.', 'Error', {duration: 2000});
        }
      });
  }

  /**
   * @ngdoc method
   * @name deleteSelected
   * @methodOf UploadFilesComponent
   * @description This method should delete selected files
   */
  public deleteSelected() {
    this.spinnerService.spinnerSubject.next(true);

    const filesForDelete = [];

    this.selectedFiles.forEach(function(file) {
      filesForDelete.push(file);
    });

    this.deleteModalDialogRef = this.dialog.open(DeleteModalComponent, {
      hasBackdrop: false
    });

    this.deleteModalDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'OK') {

          this.spinnerService.spinnerSubject.next(true);
          this.agentUpdateService.deleteFiles(filesForDelete).subscribe(res => {
            this.handleResponse((this.selectedFiles.length === 1 ? 'File' : 'Files') + ' successfully deleted.');
          });

        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });

  }

  /**
   * @ngdoc method
   * @name handleResponse
   * @methodOf UploadFilesComponent
   * @description This method should handle response - clean arrays and refresh list after action
   */
  public handleResponse(message) {
    setTimeout(() => {
      this.spinnerService.spinnerSubject.next(false);
      this.snackBar.open(message, 'Ok', {duration: 2000});
      this.selectedFiles = [];
      this.dataSource.connect();
    }, 2000);
  }

  /**
   * @ngdoc method
   * @name isFileSelected
   * @methodOf VersionsComponent
   * @description This method should check if version is already selected
   */
  public isFileSelected(selectedFileId) {
    return this.selectedFiles.find(file => file.id === selectedFileId) !== undefined;
  }
}
