import { FeatureService } from './feature.service';
import { Injectable } from '@angular/core';
import { Feature } from './feature';
import { FeatureToggle } from './feature-toggle';
import { Permission } from './permission.enum';
import { RouteType } from './route-type.enum';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  constructor(private featureService: FeatureService) {}
  public Type: RouteType;
  public RequiredPermission: Permission;
  public feature: Feature;

  public get featureEnabled(): boolean {
    return (
      this.feature.toggle === FeatureToggle.Disabled ||
      this.featureService.enabled(this.feature.name)
    );
  }
}
