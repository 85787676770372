import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'falsy'
})
export class FalsyPipe implements PipeTransform {

  transform(value: any, defaultValue?: any): any {
    return value ? value : defaultValue ? defaultValue : '-';
  }

}
