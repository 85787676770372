import { Component, OnInit, Output, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MatRadioChange, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoginService } from '../../../services/login.service';
import { AppConfigService } from '../../../services/app-config.service';
import { AgentUpdateService } from '../../../services/agent-update.service';
import { LoadingSpinnerService } from '../../../util/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-selected-versions-dialog',
  templateUrl: './selected-versions-dialog.component.html',
  styleUrls: ['./selected-versions-dialog.component.css']
})

/**
 * @ngdoc component
 * @name selected-versions-dialog:SelectedVersionsDialogComponent
 * @description This component handles modal dialog for selected software versions
 */
export class SelectedVersionsDialogComponent implements OnInit {

  @Output() change: EventEmitter<MatRadioChange>;
  public disableButton = true;
  public modalType = '';
  form: FormGroup;
  public versions = [];
  public versionLoading = true;
  public systemType: string;
  constructor(public agentUpdateService: AgentUpdateService, public loginService: LoginService, public appConfig: AppConfigService,
              public spinnerService: LoadingSpinnerService, private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<SelectedVersionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {systemType: string}) {

    agentUpdateService.modalType.subscribe(modalType => {
      this.modalType = modalType;
    });

    this.systemType = data.systemType;
  }

  ngOnInit() {

    // TODO pagination for modal???
    const pageIndex = 0;
    const pageSize = 100;
    const sortField = 'update_version';
    const order = 'asc';

    this.form = this.formBuilder.group({
      message: ''
    });

    if (this.modalType === 'Update') {
      this.agentUpdateService.getSoftwareVersions(pageIndex, pageSize, sortField, order, this.systemType).subscribe(softwareVersions => {
          this.versions = softwareVersions;
          this.versionLoading = false;
        });
    } else if (this.modalType === 'Delete') {
      this.agentUpdateService.versionsForDelete.subscribe(versionsForDelete => {
        this.versions = versionsForDelete;
      });
    }
  }

  /**
   * @ngdoc method
   * @name submit
   * @methodOf SelectedVersionsDialogComponent
   * @description This method should submit modal dialog form and send 'OK' value if answer is 'OK'
   */
  submit(form) {
    this.dialogRef.close(this.modalType);
  }

  /**
   * @ngdoc method
   * @name cancel
   * @methodOf SelectedVersionsDialogComponent
   * @description This method should cancel modal dialog and send 'Cancel' value if answer is 'Cancel'
   */
  cancel() {
    this.dialogRef.close(`${'Cancel'}`);
  }

  /**
   * @ngdoc method
   * @name onVersionChange
   * @methodOf SelectedVersionsDialogComponent
   * @description This method should set version and enable button
   */
  onVersionChange(version: MatRadioChange) {
    this.disableButton = false;
    this.agentUpdateService.softwareId.next(version.value);
  }

}
