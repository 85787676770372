import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LoadingSpinnerService } from '../util/loading-spinner/loading-spinner.service';
import { LoginService } from '../services/login.service';
import { AppConfigService } from '../services/app-config.service';
import { PaginationService } from '../services/pagination.service';
import { AgentUpdateService } from '../services/agent-update.service';
import { SortingUtil } from '../util/sorting-util';

export class VersionsDataSource implements DataSource<any> {
    private dataSessionSubject = new BehaviorSubject<string[]>([]);
    private sortSubscription: any;
    private paginatorSubscription: any;
    private sortField: string;

    constructor(
      private paginationService: PaginationService,
      private appConfig: AppConfigService,
      private loginService: LoginService,
      private agentUpdateService: AgentUpdateService,
      private spinnerService: LoadingSpinnerService,
      private sort: MatSort,
      private paginator: MatPaginator,
      size,
      private systemType: string
    ) {

      this.paginator.length = 100;
      this.paginator.pageSize = size;
      this.paginator.pageIndex = this.systemType === 'hardware'
        ? this.paginationService.versionHardwarePageNumber
        : this.paginationService.versionSoftwarePageNumber;

      // Subscription for sort event
      this.sortSubscription = this.sort.sortChange
        .asObservable()
        .subscribe(result => {
          if (result) {
            this.sortField = SortingUtil.getSortingMaping()['versions'][result.active];
            this.getResults(this.paginator.pageIndex, this.sortField, result.direction);
          }
        });

      // Get total count
      this.agentUpdateService.versionsTotalCount.subscribe(totalCount => {
        this.paginator.length = totalCount;
      });

      // Subscription for page event
      this.paginatorSubscription = this.paginator.page.asObservable().subscribe(res => {
        if (this.systemType === 'hardware') {
          this.paginationService.versionHardwarePageNumber = this.paginator.pageIndex;
        } else {
          this.paginationService.versionSoftwarePageNumber = this.paginator.pageIndex;
        }
        this.getResults(this.paginator.pageIndex, this.sortField, this.sort.direction);
      });
    }

    connect(): Observable<string[]> {
      if (!this.sortField) {
        this.sortField = SortingUtil.getSortingMaping()['versions'][this.sort.active];
      }

      this.getResults(this.paginator.pageIndex, this.sortField, this.sort.direction);
      return this.dataSessionSubject.asObservable();
    }
   /**
   * @ngdoc method
   * @name getResults
   * @methodOf VersionsDataSource
   * @description This method should get results from server.
   */
    public getResults(pageIndex, sortField, order) {
      this.spinnerService.spinnerSubject.next(true);
      this.agentUpdateService.getSoftwareVersions(pageIndex, this.paginator.pageSize, sortField, order,  this.systemType).subscribe(response => {
        this.dataSessionSubject.next(response);
        this.spinnerService.spinnerSubject.next(false);
      });
    }

  disconnect() {}

  /**
   * @ngdoc method
   * @name fetchFreshData
   * @methodOf VersionsDataSource
   * @description
   * This method is used to fetch fresh data
   * from the service directly after version type switch or version deletion
   */
  public fetchFreshData(
    pageNumber: number,
    type: string
  ): void {
    this.paginator.pageIndex = pageNumber;
    this.systemType = type;
    if (type === 'hardware') {
      this.paginationService.versionHardwarePageNumber = pageNumber;
    } else {
      this.paginationService.versionSoftwarePageNumber = pageNumber;
    }
    this.getResults(pageNumber, this.sortField, this.sort.direction);
  }
}
