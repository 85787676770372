export class InputDefaultParams {
  resolutionId: number;
  fpsId: number;
  destinationServerId: number;
  wowzaAppId: number;
  videoSourceId: number;
  audioSourceId: number;
  colorSpaceId: number;
}

export class OutputDefaultParams {
  resolutionId: number;
  fpsId: number;
  profileId: number;
  videoBitrateId: number;
  audioBitrateId: number;
  audioId: number;
}

export class LocationDefaultParams {
  regionId: number;
  regionName: string;
  siteId: number;
  siteName: string;
  roomId: number;
  roomName: string;
}

export class DefaultParams {
  inputDefaultParams: InputDefaultParams;
  outputDefaultParams: OutputDefaultParams;
  locationDefaultParams: LocationDefaultParams;
}

export class SelectObject {
  id: number;
  value: string;
}
