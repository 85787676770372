import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSort } from '@angular/material';
import { filter } from 'rxjs/operators';
import { ClientTableModel } from 'src/app/models/client-table-model';
import { ClientService } from 'src/app/services/client-service';
import { MandatoryClientDataService } from 'src/app/services/mandatory-client-data-service';
import { LoadingSpinnerService } from 'src/app/util/loading-spinner/loading-spinner.service';
import { CustomMatPaginatorComponent } from '../custom-mat-paginator/custom-mat-paginator.component';
import { MandatoryClientDataDetailsComponent } from './mandatory-client-data-dialog/mandatory-client-data-dialog.component';

@Component({
  selector: 'app-mandatory-client-data',
  templateUrl: './mandatory-client-data.component.html',
  styleUrls: ['./mandatory-client-data.component.css']
})
export class MandatoryClientDataComponent implements OnInit {

  @ViewChild(CustomMatPaginatorComponent) paginator: CustomMatPaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;

  public filter = new FormControl('', []);
  public numberOfClients = 0;

  public columnsToDisplay = [
    'clientName', 'clientStatus'
  ];

  constructor(public dataSource: ClientService,
    private spinService: LoadingSpinnerService,
    private matDialog: MatDialog,
    private mandatoryDataService: MandatoryClientDataService) { }

  ngOnInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadData();
    });
    this.paginator.page.subscribe(() => {
      this.loadData();
    });}

  loadData() {
    this.spinService.spinnerSubject.next(true);
    this.dataSource.loadData(this.sort.direction, this.filter.value, this.paginator.pageIndex, this.paginator.pageSize)
    .subscribe(r => {
      this.numberOfClients = r.clients.length
      this.paginator.length = r.totalCount;
      this.paginator.moreExist = r.moreExists;
    }, e => {}, () => {
        this.spinService.spinnerSubject.next(false);
    });
  }

  resetFilter(){
    this.filter.setValue("");
    this.numberOfClients = 0;
  }

  clientRowClick(client: ClientTableModel) {
    const dialogRef = this.matDialog.open<MandatoryClientDataDetailsComponent,
    ClientTableModel>(MandatoryClientDataDetailsComponent, {
        width: '500px',
        data: client
    });
    dialogRef.afterClosed().pipe(filter(d => d != null)).subscribe(d => {
        this.mandatoryDataService.setData(d).subscribe(() => {
            this.loadData();
        });
    });
  }
}
