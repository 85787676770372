import { StorageSyncTransition } from './../storage-sync-transition.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectExceptionModel } from '../models/project-exception-model';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { tap } from 'rxjs/operators';
import { StorageExpiration } from '../models/storage-expiration.model';
import { ExpirationType } from '../models/expiration-type';
import { ProjectExceptionTableModel } from '../models/project-exception-table-model';
import { TimeUtil } from '../util/time-util';

@Injectable({
    providedIn: 'root'
})
export class ProjectExceptionService implements DataSource<ProjectExceptionModel> {
    readonly pageOffset = 1;
    private dataSource: BehaviorSubject<ProjectExceptionModel[]> = new BehaviorSubject<ProjectExceptionModel[]>(null);

    constructor(private httpClient: HttpClient, private appConfig: AppConfigService, private timeUtil: TimeUtil) {}

    connect(collectionViewer: CollectionViewer): Observable<ProjectExceptionModel[]> {
        return this.dataSource.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSource.complete();
    }

    public loadData(pageIndex: number, numberOfPages: number,
        sortField: string, sortDirection: string, keyword: string, expirationType: ExpirationType, nextTransition: StorageSyncTransition):
    Observable<ProjectExceptionTableModel> {
        const httpParams = {
            'size': numberOfPages.toString(),
            'page': (pageIndex + this.pageOffset).toString(),
            'field': sortField,
            'order': sortDirection
        };
        if (keyword && keyword.length > 0) {
            httpParams['keyword'] = keyword;
        }
        if (expirationType) {
            httpParams['expirationType'] = expirationType;
        }
        if (nextTransition) {
            httpParams['nextTransition'] = nextTransition;
        }
        const hdr = this.appConfig.getHeaders(httpParams);
        return this.httpClient.get<ProjectExceptionTableModel>(this.appConfig.getWalnutUrl('/ProjectExpiration'),
        hdr).pipe(tap(r => {
            this.dataSource.next(this.handleResponse(r.projects, r.defaultValue));
        }));
    }

    updateProjectStorageSettings(d: ProjectExceptionModel): Observable<any> {
        return this.httpClient.put(this.appConfig.getWalnutUrl(`/ProjectExpiration/${d.id}`),
        new StorageExpiration(d.expirationType, d.expirationValue));
    }

    private handleResponse(projects, defaultValue) {
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].storageSyncStateTime) {
                projects[i].storageSyncStateTime = this.timeUtil.getDateTime(projects[i].storageSyncStateTime);
            }
            if (projects[i].projectExpirationTime) {
                projects[i].projectExpirationTime = this.timeUtil.getDateTime(projects[i].projectExpirationTime);
            }
            if (projects[i].endTime) {
                projects[i].endTime = this.timeUtil.getDateTime(projects[i].endTime);
            }
            if (projects[i].expirationType === 'Default') {
                projects[i].expirationValue = defaultValue;
            }
            if (projects[i].clientExpirationType === 'Default') {
                projects[i].clientExpirationValue = defaultValue;
            }
        }
        return projects;
    }
}
