import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Confirms } from 'src/app/models/confirms.type';
import { MandatoryClientDataDialogConfirmationModel } from 'src/app/models/mandatory-client-data-dialog-confirmation-model';

@Component({
  selector: 'app-mandatory-client-data-dialog-confirmation',
  templateUrl: './mandatory-client-data-dialog-confirmation.component.html',
  styleUrls: ['./mandatory-client-data-dialog-confirmation.component.css']
})
export class MandatoryClientDataDialogConfirmationComponent {

  constructor(
    private dialogRef: MatDialogRef<MandatoryClientDataDialogConfirmationModel, Confirms>,
    @Inject(MAT_DIALOG_DATA) public data: MandatoryClientDataDialogConfirmationModel) { }


  submit() {
    this.dialogRef.close('OK');
  }

  cancel() {
    this.dialogRef.close('Cancel');
  }

}
