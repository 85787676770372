import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IComboBoxConfiguration } from '../combo-box.configuration';
import { ComboBoxItem } from '../model/combo-box.item';
import { MatSelect } from '@angular/material';
import { OutputType } from '@angular/core/src/view';

@Component({
    selector: 'app-combo-box',
    templateUrl : './combo-box.component.html',
    styleUrls: [
        './combo-box.component.css'
    ]
})
export class ComboBoxComponent implements OnInit {
    @Input()
    public config: IComboBoxConfiguration;

    @Output()
    public selectValueEvent = new EventEmitter<ComboBoxItem<any>>();

    @Input()
    public currentValue: ComboBoxItem<any>;

    constructor() {}

    onChangeStatus(d: MatSelect) {
        this.selectValueEvent.emit(d.value);
    }

    ngOnInit() {
        this.currentValue = this.config.getInitialValue();
    }
}
