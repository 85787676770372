import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatSort, MatPaginator, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from '../../../util/loading-spinner/loading-spinner.service';
import { LoginService } from '../../../services/login.service';
import { AppConfigService } from '../../../services/app-config.service';
import { PaginationService } from '../../../services/pagination.service';
import { AgentUpdateService } from '../../../services/agent-update.service';
import { SelectedVersionsDialogComponent } from './../selected-versions-dialog/selected-versions-dialog.component';
import { VersionsDataSource } from '../../../models/versions-data-source';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * @ngdoc component
 * @name versions.component:VersionsComponent
 * @description This component shows list of Encoders versions
 */
export class VersionsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public columnsToDisplay = ['version', 'systemType', 'delete'];
  public pageSize = 10;
  public dataSource: VersionsDataSource;
  public selectedVersionForType = new Map<string, any[]>();
  public encoderTypes: { value: string, label: string }[] = [
    { value: 'software', label: 'Software encoders' },
    { value: 'hardware', label: 'Hardware encoders' }
  ];
  public selectedType = 'software';

  selectedVersionDialogRef: MatDialogRef<SelectedVersionsDialogComponent>;

  constructor(
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private router: Router,
    public spinnerService: LoadingSpinnerService,
    private paginationService: PaginationService,
    public dialog: MatDialog,
    public agentUpdateService: AgentUpdateService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.dataSource = new VersionsDataSource(this.paginationService, this.appConfig, this.loginService,
      this.agentUpdateService, this.spinnerService, this.sort, this.paginator, this.pageSize, this.selectedType);
  }

  public filterTable(value): void {
    this.selectedType = value;
    this.dataSource.fetchFreshData(0, this.selectedType);
  }

  private checkDataStructure() {
    if (!this.selectedVersionForType.has(this.selectedType)) {
      this.selectedVersionForType.set(this.selectedType, []);
    }
  }

  /**
   * @ngdoc method
   * @name selectEncoder
   * @methodOf VersionsComponent
   * @description This method should select/deselect encoder
   */
  public selectVersions(selectedVersion) {
    const index = this.getVersion().findIndex(version => version.id === selectedVersion.id);
    if (index > -1) {
      this.getVersion().splice(index, 1);
    } else {
      this.getVersion().push(selectedVersion);
    }
  }

  public getVersion() {
    this.checkDataStructure();
    return this.selectedVersionForType.get(this.selectedType);
  }
  /**
   * @ngdoc method
   * @name isVersionSelected
   * @methodOf VersionsComponent
   * @description This method should check if version is already selected
   */
  public isVersionSelected(selectedVersion) {
    return this.getVersion().some(version => version['id'] === selectedVersion.id);
  }


  /**
   * @ngdoc method
   * @name deleteSelected
   * @methodOf VersionsComponent
   * @description This method should open modal dialog on 'Delete selected' button click
   */
  public deleteSelected() {

    this.spinnerService.spinnerSubject.next(true);

    this.agentUpdateService.modalType.next('Delete');
    this.agentUpdateService.versionsForDelete.next(this.getVersion());
    this.selectedVersionDialogRef = this.dialog.open(SelectedVersionsDialogComponent, {
      hasBackdrop: false,
      data: { systemType: this.selectedType }
    });

    this.selectedVersionDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'Delete') {

          const versionsForDelete = [];

          this.getVersion().forEach(function (version) {
            versionsForDelete.push(version.id);
          });

          this.spinnerService.spinnerSubject.next(true);
          this.agentUpdateService.deleteVersions(versionsForDelete).subscribe(res => { this.handleAfterDeleteVersion(); });
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });
  }

  /**
   * @ngdoc method
   * @name handleAfterDeleteVersion
   * @methodOf VersionsComponent
   * @description This method should handle response after delete version
   */
  public handleAfterDeleteVersion() {
    setTimeout(() => {
      this.dataSource.fetchFreshData(0, this.selectedType);
      this.spinnerService.spinnerSubject.next(false);
      this.snackBar.open((this.getVersion().length === 1 ? 'Version' : 'Versions') + ' successfully deleted!', 'Ok', { duration: 2000 });
      this.selectedVersionForType.set(this.selectedType, []);
    }, 2000);
  }
}
