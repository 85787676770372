import { NoPermissionsError } from './../no-permissions-error';
import { UserMessageService } from './../user-message.service';
import { LoadingSpinnerService } from './../util/loading-spinner/loading-spinner.service';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { AppConfigService} from './app-config.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { EMPTY, throwError, Observable } from 'rxjs';
import { Credentials } from '../credentials';
import { UserSession } from '../user-session';
import { UnauthorizedError } from '../unauthorized-error';

@Injectable()
export class LoginService {
  private http: HttpClient;
  constructor(
    httpBackend: HttpBackend,
    private appConfig: AppConfigService,
    private spinnerService: LoadingSpinnerService,
    private messageService: UserMessageService) {
    this.http = new HttpClient(httpBackend);
  }

  public login(credentials: Credentials): Observable<UserSession> {
    this.spinnerService.spinnerSubject.next(true);
    return this.http.post<UserSession>(this.appConfig.getUrl('/login'), credentials, this.appConfig.getHeaders()).pipe(
        finalize(() => {
          this.spinnerService.spinnerSubject.next(false);
        }),
        catchError(error => {
          switch (error.status) {
            case 400:
              return throwError(new UnauthorizedError());
            default:
              this.messageService.showError(`An error occurred (${error.status}), please try again later.`);

              return EMPTY;
          }
        }),
        tap(session => {
          if (session.permissions.length < 1) {
            throw new NoPermissionsError();
          }
        }));
  }

  public refresh(refreshToken: string): Observable<UserSession> {
    return this.http.post<UserSession>(
      this.appConfig.getUrl('/session/refresh'),
      { refreshToken: refreshToken },
      this.appConfig.getHeaders()).pipe(
        catchError(error => {
          switch (error.status) {
            case 400:
              return throwError(new UnauthorizedError());
            default:
              this.messageService.showError(`An error occurred (${error.status}), please try again later.`);

              return EMPTY;
          }
        }),
        tap(session => {
          if (session.permissions.length < 1) {
            throw new NoPermissionsError();
          }
        }));
  }
}
