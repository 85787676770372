import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})

/**
 * @ngdoc component
 * @name delete-dialog.component:DeleteModalComponent
 * @description This component handles modal dialog for delete actions
 */
export class DeleteModalComponent implements OnInit {

  form: FormGroup;
  public objectName = '';
  public currentRoute = '';

  constructor(private formBuilder: FormBuilder, private dialogRef: MatDialogRef<DeleteModalComponent>, private router: Router) {
    this.currentRoute = router.url.split('/')[2] !== undefined ?
      router.url.split('/')[1] + '/' + router.url.split('/')[2] :
      router.url.split('/')[1] ;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      message: ''
    });

    this.setObjectName();
  }

  /**
   * @ngdoc method
   * @name submit
   * @methodOf DeleteModalComponent
   * @description This method should submit modal dialog form and send 'OK' value if answer is 'OK'
   */
  submit(form) {
    this.dialogRef.close(`${'OK'}`);
  }

  /**
   * @ngdoc method
   * @name cancel
   * @methodOf DeleteModalComponent
   * @description This method should cancel modal dialog and send 'Cancel' value if answer is 'Cancel'
   */
  cancel() {
    this.dialogRef.close(`${'Cancel'}`);
  }

  /**
   * @ngdoc method
   * @name setObjectName
   * @methodOf DeleteModalComponent
   * @description This method should set object name for different deletions
   */
  setObjectName() {
    switch (this.currentRoute) {
      case 'encoders-version':
        this.objectName = 'encoder';
        break;
      case 'upload-files':
        this.objectName = 'files';
        break;
    }
  }

}
