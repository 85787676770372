import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { tap } from 'rxjs/operators';
import { AuditLogModel } from '../models/audit-log-model';
import { AuditLogTableModel } from '../models/audit-log-table-model';

@Injectable({
    providedIn: 'root'
})
export class AuditLogService implements DataSource<AuditLogModel> {
    readonly pageOffset = 1;
    private dataSource: BehaviorSubject<AuditLogModel[]> = new BehaviorSubject<AuditLogModel[]>(null);

    constructor(private httpClient: HttpClient, private appConfig: AppConfigService) {}

    connect(collectionViewer: CollectionViewer): Observable<AuditLogModel[]> {
        return this.dataSource.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSource.complete();
    }

    public loadData(pageIndex: number, numberOfPages: number,
        sortField: string, sortDirection: string, filter: string):
    Observable<AuditLogTableModel> {
        const httpParams = {
            'size': numberOfPages.toString(),
            'page': (pageIndex + this.pageOffset).toString(),
            'field': sortField,
            'order': sortDirection
        };
        if (filter && filter.length > 0) {
            httpParams['keyword'] = filter;
        }
        const hdr = this.appConfig.getHeaders(httpParams);
        return this.httpClient.get<AuditLogTableModel>(this.appConfig.getWalnutUrl('/AuditLog'),
        hdr).pipe(tap(r => {
            this.dataSource.next(r.auditLogs);
        }));
    }
}
