import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatSort, MatPaginator, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { EncoderService } from '../../services/encoder.service';
import { LoginService } from '../../services/login.service';
import { AppConfigService } from '../../services/app-config.service';
import { PaginationService } from '../../services/pagination.service';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';
import { EncoderDataSource} from '../../models/encoder-data-source';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { DynamicPlaceholders } from 'src/app/models/search-filter.model';

@Component({
  selector: 'app-encoders-new',
  templateUrl: './encoders-new.component.html',
  styleUrls: ['./encoders-new.component.css']
})

/**
 * @ngdoc component
 * @name encoders-new.component:EncodersNewComponent
 * @description This component shows list of New Encoders
 */
export class EncodersNewComponent implements OnInit, AfterViewInit, OnDestroy {

  public columnsToDisplay = ['mac', 'pcName'];
  public pageSize = 10;
  public dataSource: EncoderDataSource;
  public currentRoute: string = this.router.url;
  public selectedListStatus = 0;
  public encoderTypeFlag: number = 2;
  public searchPlaceholder: DynamicPlaceholders = {
    keyWord: 'Search by MAC and PC name',
  };

  deleteModalDialogRef: MatDialogRef<DeleteModalComponent>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('default', { read: ElementRef }) defaultHeader: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private encoderService: EncoderService,
    private router: Router,
    public spinnerService: LoadingSpinnerService,
    private paginationService: PaginationService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getDataSource();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.dataSource.unsubscribeAll();
  }

  /**
   * @ngdoc method
   * @name getRecord
   * @methodOf EncodersNewComponent
   * @description This method should navigate from "encoder mac" label to "/encoder-new-detail" page
   */
  public getRecord(data): void {
    this.router.navigate(['encoder/encoder-new-detail', data.id]);
  }

  /**
   * @ngdoc method
   * @name detectDataSourceChange
   * @methodOf EncodersNewComponent
   * @description This method should get new data source form project-search component
   */
  public detectDataSourceChange(event: EncoderDataSource): void {
    this.dataSource = event;
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf EncodersNewComponent
   * @description This method should set dataSource
   */
  public getDataSource(isFilter?): void {
    let key = '';

    this.encoderService.encodersNewKeyword.subscribe(keyWord => {
      key = keyWord !== undefined && keyWord !== null ? keyWord : '';
      this.paginator.pageIndex = keyWord
          ? 0 : this.paginationService.encodersNewPageNumber;
    });

    this.dataSource = new EncoderDataSource(
      this.paginationService,
      this.appConfig,
      this.loginService,
      this.encoderService,
      this.spinnerService,
      this.sort,
      this.paginator,
      this.currentRoute,
      isFilter,
      this.selectedListStatus,
      key
    );
  }

}
