import { HttpClient, HttpEvent, HttpEventType } from  '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface FileUpdateUploadedInterface {

}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private uploadFileUrl: Function = (encrypt, selectedType) => `/api/update/upload?encrypt=${encrypt}&systemType=${selectedType}`; 
  
  constructor(private httpClient: HttpClient) { }

  public upload(data, encrypt, selectedType) {
    let uploadURL: string = this.uploadFileUrl(encrypt, selectedType);

    return this.httpClient.post<any>(uploadURL, data, {
      reportProgress: true,
      observe: 'events'
    }).map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress: number = Math.round(100 * event.loaded / event.total);

          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    });
  }
}