import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ElementRef, OnDestroy } from '@angular/core';
import { MatSort, MatPaginator } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';
import { EncoderService } from '../../services/encoder.service';
import { EncoderDataSource} from '../../models/encoder-data-source';
import { LoginService } from '../../services/login.service';
import { AppConfigService } from '../../services/app-config.service';
import { PaginationService } from '../../services/pagination.service';
import { DynamicPlaceholders } from 'src/app/models/search-filter.model';

@Component({
  selector: 'app-encoders-faulty',
  templateUrl: './encoders-faulty.component.html',
  styleUrls: ['./encoders-faulty.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * @ngdoc component
 * @name encoders-faulty.component:EncodersFaultyComponent
 * @description This component shows list of Encoders At Risk
 */

export class EncodersFaultyComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('default', { read: ElementRef }) defaultHeader: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public columnsToDisplay = ['name', 'pcName', 'mac', 'lastErrorMessage'];
  public pageSize = 10;
  public dataSource;
  public keyWord = new FormControl('', []);
  public projectId = new FormControl('', []);
  public currentRoute: string = this.router.url;
  public selectedListStatus = 'ALL';
  public encoderTypeFlag = 4;
  public searchPlaceholder: DynamicPlaceholders = {
    keyWord: 'Search by encoder name, MAC, and PC name',
    id: 'Search by project ID'
  };

  constructor(private appConfig: AppConfigService, private loginService: LoginService, private encoderService: EncoderService,
              private router: Router, public spinnerService: LoadingSpinnerService, private paginationService: PaginationService) { }

  ngOnInit() {
    this.getDataSource();
  }

  ngOnDestroy(): void {
    this.dataSource.unsubscribeAll();
  }

  /**
   * @ngdoc method
   * @name getRecord
   * @methodOf EncodersFaultyComponent
   * @description This method should navigate from "encoders faulty" label to "/encoder-log" page
   */
  public getRecord(data) {
    this.encoderService.setEncoderFaultyData(data);
    this.router.navigate(['encoder/encoder-log', data.id]);
  }

  /**
   * @ngdoc method
   * @name detectDataSourceChange
   * @methodOf EncodersAtRiskComponent
   * @description This method should get new data source form project-search component
   */
  public detectDataSourceChange(event: EncoderDataSource): void {
    this.dataSource = event;
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf EncodersFaultyComponent
   * @description This method should set dataSource
   */
  public getDataSource(isFilter?) {
    let key = '';
    let id = '';

    this.encoderService.encodersWithErrorKeyword.subscribe(keyword => {
      key = keyword !== undefined && keyword !== null ? keyword : '';
    });

    this.encoderService.encodersWithErrorProjectId.subscribe(projectId => {
      id = projectId !== undefined && projectId !== null ? projectId : '';
    });

    this.paginator.pageIndex = key || id
      ? 0 : this.paginationService.encodersWithErrorsPageNumber;

    this.dataSource = new EncoderDataSource(
      this.paginationService,
      this.appConfig,
      this.loginService,
      this.encoderService,
      this.spinnerService,
      this.sort,
      this.paginator,
      this.currentRoute,
      this.selectedListStatus,
      key,
      id
    );
  }
}
