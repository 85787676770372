import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'expirationValue'})
export class ExpirationValuePipe implements PipeTransform {
    transform(value: any): string {
        switch (value.expirationType) {
            case 'Custom':
                return `${value.expirationValue} ${value.expirationValue > 1 ? 'days' : 'day'}`;
            case 'Default':
                return `Default (${value.expirationValue} ${value.expirationValue > 1 ? 'days' : 'day'})`;
            case 'Never':
                return 'Never';
        }
    }
}
