import { Component, OnInit } from '@angular/core';
import { FeatureService } from '../feature.service';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html'
})
export class LoggedInComponent implements OnInit {

  constructor(public featureService: FeatureService) { }

  ngOnInit() {
    this.featureService.loadData().subscribe();
  }

}
