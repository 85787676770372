import { Component, ChangeDetectionStrategy, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatSort, MatPaginator } from '@angular/material';
import { TimezoneService } from '../services/timezone.service';
import { AppConfigService } from '../services/app-config.service';
import { LoginService } from '../services/login.service';
import { ProjectService } from '../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeUtil } from '../util/time-util';
import { LoadingSpinnerService } from '../util/loading-spinner/loading-spinner.service';
import { PaginationService } from '../services/pagination.service';
import { ProjectDataSource } from '../models/project-data-source';
import { SingleProjectRouteConfiguration, ProjectsRoutesColumnsForToolbar } from '../models/project';
import { PROJECT_ROUTES_CONFIGURATION } from './projects.constants';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * @ngdoc component
 * @name projects.component:ProjectsComponent
 * @description This component shows list of projects
 */
export class ProjectsComponent implements OnInit, OnDestroy {
  private currentRoute: string;
  private config: SingleProjectRouteConfiguration;
  private isFilter = false;
  private skipInnerViewShowing = false;

  public projectTypeFlag: number = null;
  public columnsToDisplay: Array<string>;
  public columnsForToolbar: Array<ProjectsRoutesColumnsForToolbar>;
  public pageSize = 10;
  public dataSource;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('default', { read: ElementRef }) defaultHeader: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private timezoneService: TimezoneService,
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private projectService: ProjectService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private timeUtil: TimeUtil,
    private spinnerService: LoadingSpinnerService,
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.activatedRoute.snapshot.url[0].toString();
    if (!PROJECT_ROUTES_CONFIGURATION[this.currentRoute]) {
      this.router.navigate(['/']);
    }
    this.config = { ...PROJECT_ROUTES_CONFIGURATION.defaultValues, ...PROJECT_ROUTES_CONFIGURATION[this.currentRoute] };
    this.columnsToDisplay = this.config.columnsToDisplay;
    this.projectTypeFlag = this.config.projectTypeFlag;
    this.columnsForToolbar = this.config.columnsForToolbar;
    this.getDataSource();
  }

  ngOnDestroy(): void {
    this.dataSource.unsubscribeAll();
  }

  /**
   * @ngdoc method
   * @name getRecord
   * @methodOf ProjectsComponent
   * @description This method should navigate from "project name" label to "project-detail" page
   */
  public getRecord(id): void {
    if (!this.skipInnerViewShowing) {
      this.router.navigate([this.currentRoute, 'project-detail', id]);
    }
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf ProjectsComponent
   * @description This method should set dataSource
   */
  public getDataSource(): void {
    let dateFrom, dateTo, keyWord, encoderStatus;
    this.projectService[this.config.searchParamsMethodName].subscribe(params => {
      dateFrom = params && params.dateFrom || this.timeUtil.getDateTimeUTCFormat(new Date());
      dateTo = params && params.dateTo || this.timeUtil.getDateTimeUTCFormat(new Date());
      keyWord = params && params.keyWord || '';
      encoderStatus = params && params.status || 'ALL';
      this.paginator.pageIndex = keyWord ? 0 : this.paginationService[this.config.paginationMethodName];
    });

    this.spinnerService.spinnerSubject.next(false);
    this.dataSource = new ProjectDataSource(
      this.timezoneService,
      this.paginationService,
      this.timeUtil,
      this.appConfig,
      this.loginService,
      this.projectService,
      this.spinnerService,
      this.sort,
      this.paginator,
      this.projectTypeFlag,
      this.isFilter,
      dateFrom,
      dateTo,
      keyWord,
      encoderStatus
    );
  }

  /**
   * @ngdoc method
   * @name detectDataSourceChange
   * @methodOf ProjectsComponent
   * @description This method should get new data source form project-search component
   */
  public detectDataSourceChange(event: ProjectDataSource): void {
    this.dataSource = event;
  }

  /**
   * @ngdoc method
   * @name goToErrorsPage
   * @methodOf ProjectsComponent
   * @description This method should navigate to list of encoders with errors for specific project
   */
  public goToErrorsPage(id, numberOfErrors): void {
    if (numberOfErrors > 0) {
      this.skipInnerViewShowing = true;
      this.router.navigate(['encoder/encoder-log-for-project/', id]);
    }
  }
}
