import { Pipe, PipeTransform } from "@angular/core";
import { AuditLogModel } from "src/app/models/audit-log-model";

@Pipe({name: 'clientIdValue'})
export class ClientIdValuePipe implements PipeTransform {
    transform(value: AuditLogModel): string {
      let clientId = value.logDetails.find(log => log.type === "ClientId");
      return  !!clientId ? clientId.value : "-";
    }
}
