import { LoadingSpinnerService } from './util/loading-spinner/loading-spinner.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HandleAnyHttpResponse implements HttpInterceptor {
  constructor(private spinnerService: LoadingSpinnerService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return defer(() => {
      this.spinnerService.spinnerSubject.next(true);

      return next.handle(req).pipe(finalize(() => {
        this.spinnerService.spinnerSubject.next(false);
      }));
    });
  }
}
