import { ClientExpirationValuePipe } from './client-expiration-value.pipe';
import { ProjectExceptionModel } from '../../models/project-exception-model';
import { ExpirationValuePipe } from './expiration-value-pipe';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'projectExpirationValue'})
export class ProjectExpirationValuePipe implements PipeTransform {
    constructor(private expirationValuePipe: ExpirationValuePipe, private clientExpirationValuePipe: ClientExpirationValuePipe) {}
    transform(value: ProjectExceptionModel): string {
        if (value.expirationType === 'Default') {
            return this.clientExpirationValuePipe.transform(value);
        } else {
            return this.expirationValuePipe.transform(value);
        }
    }
}
