import { PipeTransform, Pipe } from '@angular/core';
import { TimeUtil } from '../../util/time-util';

@Pipe({name: 'DateTimeFormat'})
export class DatetimeFormatPipe implements PipeTransform {

  constructor(private timeUtil: TimeUtil) {}

  transform(value: string): string {
    return value ? this.timeUtil.getDateTime(value) : '';
  }
}
