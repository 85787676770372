import { NoPermissionsError } from './../no-permissions-error';
import { UserMessageService } from './../user-message.service';
import { UserService } from './../user.service';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { User } from '../models/user';
import { UnauthorizedError } from '../unauthorized-error';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {

  public user: User = new User();

  public username = new FormControl('', [Validators.required, Validators.email]);
  public password = new FormControl('', [Validators.required]);

  constructor(private messageService: UserMessageService, public userService: UserService) { }

  getErrorMessageForUsername() {
    return this.username.hasError('required') ? 'You must enter username' :
      this.username.hasError('email') ? 'Not a valid username' : '';
  }

  getErrorMessageForPassword() {
    return this.password.hasError('required') ? 'You must enter password' : '';
  }

  login() {

    this.user.username = this.username.value;
    this.user.password = this.password.value;

    this.userService.login(this.user).subscribe(
      () => this.messageService.showInfo('You have successfully logged in.'),
      error => {
        if (error instanceof UnauthorizedError) {
          this.messageService.showError('Invalid credentials, please try again.');
          return;
        }
        if (error instanceof NoPermissionsError) {
          this.messageService.showError('No permissions to view the page.');
          return;
        }
      });
  }
}
