import { Component, OnInit, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatSort, MatPaginator, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from '../../../util/loading-spinner/loading-spinner.service';
import { EncoderService } from '../../../services/encoder.service';
import { LoginService } from '../../../services/login.service';
import { AppConfigService } from '../../../services/app-config.service';
import { TimezoneService } from '../../../services/timezone.service';
import { EncoderLogDataSource} from '../../../models/encoder-log-data-source';
import { Encoder } from '../../../models/encoder';
import { TimeUtil } from '../../../util/time-util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-encoder-log',
  templateUrl: './encoder-log.component.html',
  styleUrls: ['./encoder-log.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * @ngdoc component
 * @name encoder-log.component:EncodersLogComponent
 * @description This component shows list of Encoder Logs
 */
export class EncoderLogComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public columnsToDisplayEncoder = ['projectId', 'message', 'encoderStatus', 'sentAtEncoderTime',
                                    'receivedAtServerTime', 'logLevel', 'fixed'];

  public columnsToDisplayProject = ['encoderName', 'pcName', 'encoderId', 'message', 'encoderStatus',
                                    'sentAtEncoderTime', 'receivedAtServerTime', 'logLevel', 'fixed'];
  public pageSize = 10;
  public dataSource: EncoderLogDataSource;
  public encoderId;
  public encoder: Encoder;
  public highestMessageId: number;
  public projectId = this.router.url.split('/')[3];
  public currentRoute = this.router.url.split('/')[2];
  private alive = true;
  private subs: { [key: string]: Subscription } = {};

  constructor(
    private timeUtil: TimeUtil,
    private encoderService: EncoderService,
    public spinnerService: LoadingSpinnerService,
    public route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private timezoneService: TimezoneService
  ) {
    this.subs['timezone'] = this.timezoneService.timezone.takeWhile(() => this.alive).subscribe(timezone => {
      this.dataSource.connect();
     });
  }

  ngOnInit() {
    this.getDataSource();
    if (this.currentRoute === 'encoder-log') {
      this.encoderService.encoderFaultyDataOutput.subscribe(encoder => this.encoder = encoder);
      this.encoderService.highestMessageId.subscribe(id => this.highestMessageId = id);
    }
  }

  ngOnDestroy(): void {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
    this.dataSource.unsubscribeAll();
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf EncoderLogComponent
   * @description This method should set dataSource
   */
  public getDataSource() {
    this.route.params.subscribe(params => {
      this.spinnerService.spinnerSubject.next(false);
      this.dataSource = new EncoderLogDataSource(this.appConfig, this.loginService, this.timeUtil, this.encoderService, this.spinnerService,
                                                 this.sort, this.paginator, this.currentRoute, params['id']);
    });
  }

  /**
   * @ngdoc method
   * @name refreshPage
   * @methodOf EncoderLogComponent
   * @description This method should fetch fresh data
   */
  public refreshPage() {
    this.dataSource.connect();
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf EncoderLogComponent
   * @description This method should mark errors fixed
   */
  public markErrorsFixed() {
    this.spinnerService.spinnerSubject.next(true);
    this.route.params.subscribe(params => {
      this.encoderService.markErrorsFixed(params['id'], this.highestMessageId).subscribe(res => {
        this.handleAfterErrorFixed();
      });
    });
  }

  /**
   * @ngdoc method
   * @name handleAfterErrorFixed
   * @methodOf EncoderLogComponent
   * @description This method should handle after error fixed
   */
  public handleAfterErrorFixed() {
    setTimeout(() => {
      this.snackBar.open((this.encoderService.logMessagesTotalCount.value === 1 ?
                                  'Error' : 'Errors') + ' fixed!', 'Ok', {duration: 2000});
      this.spinnerService.spinnerSubject.next(false);
      this.router.navigate(['/encoders-faulty']);
    }, 2000);
  }


}
