import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-simple-information-modal',
  templateUrl: './simple-information-modal.component.html',
  styleUrls: ['./simple-information-modal.component.css']
})
export class SimpleInformationModalComponent implements OnInit {

  form: FormGroup;
  public objectName = '';
  public currentRoute = '';

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<SimpleInformationModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      modalTitle: this.data.modalTitle,
      modalMessageContent: this.data.modalMessageContent,
      modalType: this.data.modalType
    });
  }

  /**
   * @ngdoc method
   * @name submit
   * @methodOf SimpleInformationModalComponent
   * @description This method should submit modal dialog form and send 'OK' value if answer is 'OK'
   */
  submit(form) {
    this.dialogRef.close(`${'OK'}`);
  }

  /**
   * @ngdoc method
   * @name cancel
   * @methodOf SimpleInformationModalComponent
   * @description This method should cancel modal dialog and send 'Cancel' value if answer is 'Cancel'
   */
  cancel() {
    this.dialogRef.close(`${'Cancel'}`);
  }
}
