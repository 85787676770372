import { Component, Input } from '@angular/core';
import { TaniRespondent } from 'src/app/models/tani/tani-respondent';

@Component({
    selector: 'app-tani-respondents-table-cell',
    templateUrl: './tani-respondents-table-cell.component.html',
    styleUrls: ['./tani-respondents-table-cell.component.css']
})
export class TaniRespondentsTableCellComponent {
    @Input() respondents: TaniRespondent[];
}
