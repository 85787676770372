import { RouteTypeGuard } from './route-type.guard';
import { Injectable } from '@angular/core';
import { RouteService } from './route.service';
import { RouteType } from './route-type.enum';

@Injectable({
  providedIn: 'root'
})
export class UnknownRouteGuard extends RouteTypeGuard {
  constructor(routeService: RouteService) {
    super(routeService, RouteType.Unknown);
  }
}
