import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DROPDOWN_OPTIONS_BY_ENCODER_TYPE, DROPDOWN_OPTIONS_BY_PROJECT_TYPE } from './dropdown-filter.constants';
import { EncoderService } from '../../services/encoder.service';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.css']
})
export class DropdownFilterComponent implements OnInit {
  public availableFilters;

  @Input() projectPage = false;
  @Input() typeFlag: number;
  @Output() emitDropdownFilterValueChange = new EventEmitter<any>();

  constructor(private encoderService: EncoderService, private projectService: ProjectService) { }

  ngOnInit(): void {
    const dropdownOptionsByPageType = this.projectPage ? DROPDOWN_OPTIONS_BY_PROJECT_TYPE : DROPDOWN_OPTIONS_BY_ENCODER_TYPE;
    const serviceName = this.projectPage ? 'projectService' : 'encoderService';
    if (this.projectPage && !this.typeFlag) { this.typeFlag = 1; }
    this.availableFilters = dropdownOptionsByPageType[this.typeFlag] || [];
    for (let index = 0; index < this.availableFilters.length; index++) {
      const filter = this.availableFilters[index];
      const storedValue = this[serviceName][filter.methodName].getValue();
      filter['currentValue'] = storedValue !== null ? storedValue : filter.options[0].value;
    }
  }

    /**
   * @ngdoc method
   * @name onChangeStatus
   * @methodOf EncodersSearchComponent
   * @description This method should emit status value to parent component
   */
  public onChangeStatus(event, id): void {
    if (event && event.source && event.source.selected) {
      event.label = id;
      this.emitDropdownFilterValueChange.next(event);
    }
  }
}
