import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientExceptionModel } from '../models/client-exception-model';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { tap } from 'rxjs/operators';
import { ClientExceptionTableModel } from '../models/client-exception-table-model';
import { StorageExpiration } from '../models/storage-expiration.model';
import { ExpirationType } from '../models/expiration-type';
import { ClientStatus } from '../models/client-status';

@Injectable({
    providedIn: 'root'
})
export class ClientExceptionService implements DataSource<ClientExceptionModel> {
    readonly pageOffset = 1;
    private dataSource: BehaviorSubject<ClientExceptionModel[]> = new BehaviorSubject<ClientExceptionModel[]>(null);

    constructor(private httpClient: HttpClient, private appConfig: AppConfigService) {}

    connect(collectionViewer: CollectionViewer): Observable<ClientExceptionModel[]> {
        return this.dataSource.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSource.complete();
    }

    public loadData(pageIndex: number, numberOfPages: number,
        sortField: string, sortDirection: string, filter: string,
        expirationType: ExpirationType, clientStatus: ClientStatus):
    Observable<ClientExceptionTableModel> {
        const httpParams = {
            'size': numberOfPages.toString(),
            'page': (pageIndex + this.pageOffset).toString(),
            'field': sortField,
            'order': sortDirection
        };
        if (filter && filter.length > 0) {
            httpParams['keyword'] = filter;
        }
        if (expirationType) {
            httpParams['expirationType'] = expirationType;
        }
        if (clientStatus) {
            httpParams['clientStatus'] = clientStatus;
        }
        const hdr = this.appConfig.getHeaders(httpParams);
        return this.httpClient.get<ClientExceptionTableModel>(this.appConfig.getWalnutUrl('/ClientExpirations'),
        hdr).pipe(tap(r => {
            this.dataSource.next(r.clients);
        }));
    }

    updateClientStorageSettings(d: ClientExceptionModel): Observable<any> {
        return this.httpClient.put(this.appConfig.getWalnutUrl(`/ClientExpirations/${encodeURIComponent(d.clientId)}`),
        new StorageExpiration(d.expirationType, d.expirationValue));
    }

}
