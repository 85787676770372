import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class UserMessageService {

  constructor(private snackBar: MatSnackBar) { }
  showInfo(message: string) {
    setTimeout(() => { // Snack bar can't be opened directly in Angular lifecycle events
      this.snackBar.open(message, 'Ok', { duration: 2000 });
    });
  }

  showError(message: string) {
    setTimeout(() => { // Snack bar can't be opened directly in Angular lifecycle events
      this.snackBar.open(message, 'Ok', { duration: 4000 });
    });
  }
}
