import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { DefaultParams, InputDefaultParams, OutputDefaultParams, LocationDefaultParams } from '../../models/default-params';
import { InputEncoderAllParams, OutputEncoderAllParams, FpsInput, ResolutionInput,
         ColorSpace , VideoSource, DestinationServer, LocationAllParams } from '../../models/all-params';
import { EncoderService } from '../../services/encoder.service';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';
import { Encoder } from '../../models/encoder';


@Component({
  selector: 'app-encoder-parameters',
  templateUrl: './encoder-parameters.component.html',
  styleUrls: ['./encoder-parameters.component.css']
})

export class EncoderParametersComponent implements OnInit, OnChanges {
  @Input() encoder: Encoder;
  @Input() isCompleted: boolean;
  @Output() encoderOutput = new EventEmitter<any>();
  @Input() destinationServerOptions: DestinationServer[];
  @Input() selectDestinationServerMessage: boolean;

  public allInputParams: InputEncoderAllParams;
  public allOutputParams: OutputEncoderAllParams;
  public allLocationParams: LocationAllParams;
  public videoSourceList: VideoSource[] = [];
  public videoSourceValue: number;
  public resolutionInputList: ResolutionInput[] = [];
  public resolutionInputValue: number;
  public colorSpaceList: ColorSpace[] = [];
  public colorSpaceValue: number;
  public fpsInputList: FpsInput[] = [];
  public fpsInputId: number;
  public wowzaAppInputValue: number;
  public destinationServerInputValue: number;
  public audioSourceInputValue: number;
  public inputDefaultParams: InputDefaultParams;
  public outputDefaultParams: OutputDefaultParams;
  public locationDefaultParams: LocationDefaultParams;
  public encoderForSending: Encoder;
  public resolutionValidationWarning = false;
  public colorSpaceValidationWarning = false;
  public fpsValidationWarning = false;
  public currentRoute: string;
  public fpsInputIdCopy: number;
  public encoderAllOutputParametersCopy: OutputEncoderAllParams;
  private twoCHDVRNTOptionId = 3;
  private stereoAudioChannelId = 2;

  constructor(public spinnerService: LoadingSpinnerService, private snackBar: MatSnackBar,
              private encoderService: EncoderService, private router: Router) {}

  ngOnInit() {
    this.currentRoute = this.router.url.split('/')[2];
    this.encoder = new Encoder();
    this.allInputParams = new InputEncoderAllParams();
    this.allOutputParams = new OutputEncoderAllParams();
    this.allLocationParams = new LocationAllParams();
    this.inputDefaultParams = new InputDefaultParams();
    this.outputDefaultParams = new OutputDefaultParams();
    this.locationDefaultParams = new LocationDefaultParams();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['encoder'] && typeof changes['encoder']['currentValue'] !== 'undefined' &&
        changes['encoder']['currentValue']['allParams']['inputEncoderAllParams'] ) {
      this.allInputParams = this.encoder.allParams.inputEncoderAllParams;
      this.allOutputParams = this.encoder.allParams.outputEncoderAllParams;
      this.allLocationParams = this.encoder.allParams.locationAllParams;
      this.inputDefaultParams = this.encoder.defaultParams.inputDefaultParams;
      this.outputDefaultParams = this.encoder.defaultParams.outputDefaultParams;
      this.locationDefaultParams = this.encoder.defaultParams.locationDefaultParams;
      this.populateInputParams(this.encoder.defaultParams.inputDefaultParams.videoSourceId,
                               this.encoder.defaultParams.inputDefaultParams.resolutionId,
                               this.encoder.defaultParams.inputDefaultParams.colorSpaceId,
                               this.encoder.defaultParams.inputDefaultParams.fpsId);
      if (this.encoder.defaultParams.locationDefaultParams.regionId) {
        if (this.isCompleted) {
          const destinationServers = [];
          this.encoder.allParams.locationAllParams.regions.forEach(function(element) {
            element.destinationServers.forEach(function(x) { destinationServers.push(x); });
          });
          this.destinationServerOptions = destinationServers;
        } else {
          this.destinationServerOptions = this.encoder.allParams.locationAllParams.regions.find(region =>
            region.id === this.encoder.defaultParams.locationDefaultParams.regionId).destinationServers;
        }
      }

      const copy = JSON.stringify(this.encoder.defaultParams.inputDefaultParams.fpsId);
      this.encoderAllOutputParametersCopy = JSON.parse(JSON.stringify(this.encoder.allParams.outputEncoderAllParams));
      this.fpsInputIdCopy = JSON.parse(copy);

    }

    if (changes['destinationServerOptions'] && this.destinationServerOptions) {
      this.destinationServerInputValue = null;
    }

  }

  public selectInputParam(event, value, dataType) {
    this.checkIfWowzaAppChangedToTwoChannel();
    if (event.source.selected) {
      switch (dataType) {
        case 'videoSource':
          this.videoSourceValue = value.id;
          this.resolutionInputList = value.resolutions;
          this.colorSpaceList = [];
          this.fpsInputList = [];
          this.resolutionInputValue = null;
          this.colorSpaceValue = null;
          this.fpsInputId = null;
          this.detectAndEmitParamsValueChange();
          this.onVideoSourceChange();
          break;

        case 'resolution':
          this.resolutionInputValue = value.id;
          this.colorSpaceList = value.colorSpaces;
          this.fpsInputList = [];
          this.colorSpaceValue = null;
          this.fpsInputId = null;
          this.detectAndEmitParamsValueChange();
          this.onResolutionChange();
          break;

        case 'colorSpace':
          this.colorSpaceValue = value.id;
          this.fpsInputList = value.fps;
          this.fpsInputId = null;
          this.detectAndEmitParamsValueChange();
          this.onColorSpaceChange();
          break;

        case 'fps':
          this.fpsInputId = value.id;
          this.detectAndEmitParamsValueChange();
          this.onFpsChange();
          break;
        default:
      }
    }
  }

  private populateInputParams(videoSource, resolution, colorSpace, fps) {
    this.videoSourceValue = videoSource;
    setTimeout(() => {
      this.resolutionInputValue = resolution;
      setTimeout(() => {
        this.colorSpaceValue = colorSpace;
        setTimeout(() => {
          this.fpsInputId = fps;
        }, 0);
      }, 0);
    }, 0);
    this.wowzaAppInputValue = this.inputDefaultParams.wowzaAppId;
    this.destinationServerInputValue = this.inputDefaultParams.destinationServerId;
    this.audioSourceInputValue = this.inputDefaultParams.audioSourceId;
  }

  private manageProfileSelection(profileId) {
    const profileParams = this.allOutputParams.profiles.find(function (profile) {
      return profile.id === profileId;
    });
    profileParams.profileId = profileParams.id;
    this.outputDefaultParams = profileParams;
    this.checkIfWowzaAppChangedToTwoChannel();
  }

  /**
   * @ngdoc method
   * @name detectAndEmitParamsValueChange
   * @methodOf EncoderParametersComponent
   * @params $event
   * @description This method should sent all changed params
   */
  private detectAndEmitParamsValueChange() {
      const inputParams = new InputDefaultParams;
      const encoderParams = new DefaultParams;
      inputParams.audioSourceId = this.audioSourceInputValue;
      inputParams.colorSpaceId = this.colorSpaceValue;
      inputParams.destinationServerId = this.destinationServerInputValue;
      inputParams.fpsId = this.fpsInputId;
      inputParams.resolutionId = this.resolutionInputValue;
      inputParams.videoSourceId = this.videoSourceValue;
      inputParams.wowzaAppId = this.wowzaAppInputValue;
      encoderParams.outputDefaultParams = this.outputDefaultParams;
      encoderParams.inputDefaultParams = inputParams;
      encoderParams.locationDefaultParams = this.locationDefaultParams;
      this.encoderOutput.emit(encoderParams);
  }

  /**
   * @ngdoc method
   * @name getAudioChannelsNameById
   * @methodOf EncoderParametersComponent
   * @description This method should return string value for audio channel name by audio channel id
   */
  public getAudioChannelsNameById(id) {
    let audioChannelName: string;
    switch (id) {
      case 1:
        audioChannelName = 'mono';
        break;
      case 2:
        audioChannelName = 'stereo';
        break;
    }
    return audioChannelName;
  }

  /**
   * @ngdoc method
   * @name onVideoSourceChange
   * @methodOf EncoderParametersComponent
   * @description This method should show validation warnings on video source change
   */
  public onVideoSourceChange() {
    if (this.currentRoute !== 'encoder-new-detail') {
      this.resolutionValidationWarning = true;
      this.onResolutionChange();
    }
  }

  /**
   * @ngdoc method
   * @name onResolutionChange
   * @methodOf EncoderParametersComponent
   * @description This method should show validation warnings on resolution change
   */
  public onResolutionChange() {
    if (this.currentRoute !== 'encoder-new-detail') {
      if (this.resolutionInputValue !== null) {
        this.resolutionValidationWarning = false;
      }
      this.colorSpaceValidationWarning = true;
      this.fpsValidationWarning = true;
    }
  }

  /**
   * @ngdoc method
   * @name onColorSpaceChange
   * @methodOf EncoderParametersComponent
   * @description This method should show validation warnings on color space change
   */
  public onColorSpaceChange() {
    if (this.currentRoute !== 'encoder-new-detail') {
      if (this.colorSpaceValue !== null) {
        this.colorSpaceValidationWarning = false;
      }
      this.fpsValidationWarning = true;
    }
  }

  /**
   * @ngdoc method
   * @name onFpsChange
   * @methodOf EncoderParametersComponent
   * @description This method should show validation warnings on fps change
   */
  public onFpsChange() {

    const fpsInputValue = this.fpsInputList.find(fps => fps.id == this.fpsInputId).value;

    if (this.currentRoute !== 'encoder-new-detail') {
      if (this.fpsInputIdCopy !== this.fpsInputId) {
        this.fpsInputIdCopy = null;
        if (this.encoder.allParams.outputEncoderAllParams.fps.find(fps => fps.value === fpsInputValue) !== undefined) {
          this.outputDefaultParams.fpsId = this.encoder.allParams.outputEncoderAllParams.fps.find(fps => fps.value === fpsInputValue).id;
        }
      }
      if (this.fpsInputId !== null) {
        this.fpsValidationWarning = false;
      }
    } else if (this.currentRoute === 'encoder-new-detail') {
      if (this.encoder.allParams.outputEncoderAllParams.fps.find(fps => fps.value === fpsInputValue) !== undefined) {
        this.outputDefaultParams.fpsId = this.encoder.allParams.outputEncoderAllParams.fps.find(fps => fps.value === fpsInputValue).id;
      }
    }
  }

  /**
   * @ngdoc method
   * @name checkIfWowzaAppChangedToTwoChannel
   * @methodOf EncoderParametersComponent
   * @description This method should set audio channel options if wowza app is 2CHDVRNT
   */
  public checkIfWowzaAppChangedToTwoChannel() {
    if (this.encoder.defaultParams.inputDefaultParams.wowzaAppId === this.twoCHDVRNTOptionId) {
      this.encoder.allParams.outputEncoderAllParams.audioChannels =
        this.encoderAllOutputParametersCopy.audioChannels.filter(element => element.id === this.stereoAudioChannelId);
      this.encoder.defaultParams.outputDefaultParams.audioId = this.stereoAudioChannelId;
      this.outputDefaultParams.audioId = this.stereoAudioChannelId;
    } else {
      this.encoder.allParams.outputEncoderAllParams.audioChannels = this.encoderAllOutputParametersCopy.audioChannels;
    }
  }
}
