export interface DestinationServer {
  id: number;
  value: string;
}

export interface WowzaApp {
  id: number;
  value: string;
}

export interface FpsInput {
  id: number;
  value: string;
}

export interface ColorSpace {
  id: number;
  value: string;
  fps: FpsInput[];
}

export interface ResolutionInput {
  id: number;
  value: string;
  colorSpaces: ColorSpace[];
}

export interface VideoSource {
  id: number;
  value: string;
  resolutions: ResolutionInput[];
}

export interface AudioSource {
  id: number;
  value: string;
}

export class InputEncoderAllParams {
  wowzaApps: WowzaApp[];
  videoSources: VideoSource[];
  audioSources: AudioSource[];
}

export interface ResolutionOutput {
  id: number;
  value: string;
}

export interface FpsOutput {
  id: number;
  value: string;
}

export interface Profile {
  id: number;
  value: string;
  resolutionId: number;
  fpsId: number;
  videoBitrateId: number;
  audioBitrateId: number;
  audioId: number;
  profileId: number;
}

export interface VideoBitrate {
  id: number;
  value: string;
}

export interface AudioBitrate {
  id: number;
  value: string;
}

export interface AudioChannel {
  id: number;
  value: string;
}

export class OutputEncoderAllParams {
  resolutions: ResolutionOutput[];
  fps: FpsOutput[];
  profiles: Profile[];
  videoBitrates: VideoBitrate[];
  audioBitrates: AudioBitrate[];
  audioChannels: AudioChannel[];
}

export class AllParams {
  inputEncoderAllParams: InputEncoderAllParams;
  outputEncoderAllParams: OutputEncoderAllParams;
  locationAllParams: LocationAllParams;
}

export class LocationAllParams {
  regions: Region[];
}

export class Region {
  id: number;
  value: string;
  destinationServers: DestinationServer[];
}
