import { DefaultParams } from '../models/default-params';

export class Project {
  id: number;
  projectId: number;
  name: string;
  startTime: number;
  endTime: number;
  actualStartTime: string;
  actualEndTime: string;
  facility: string;
  room: string;
  encoderName: string;
  encoderId: string;
  callInfo: string;
  url: string;
  region: string;
  projectTypeId: number;
  projectType: string;
  twoChannelAudio: boolean;
  status: string;
  canceled: boolean;
  completed: boolean;
  manuallyStopped: boolean;
  automated: boolean;
  systemType: string;
  projectParams: DefaultParams;
  rejoinSupported: boolean;
}

export class ProjectResponse {
  projects: Project[];
  totalCount: number;
}

export interface ProjectRoutesConfig {
  'intervu': SingleProjectRouteConfiguration;
  'live-streaming': SingleProjectRouteConfiguration;
  'projects-at-risk': SingleProjectRouteConfiguration;
  'defaultValues': SingleProjectRouteConfiguration;
  'all-projects': SingleProjectRouteConfiguration;
}

export interface SingleProjectRouteConfiguration {
  columnsToDisplay?: Array<string>;
  columnsForToolbar?: Array<ProjectsRoutesColumnsForToolbar>;
  projectTypeFlag: number;
  searchParamsMethodName: string;
  paginationMethodName: string;
}
export interface ProjectsRoutesColumnsForToolbar {
  name: string;
  value: string;
}
