import { IComboBoxConfiguration } from '../shared/combo-box/combo-box.configuration';
import { ComboBoxItem } from '../shared/combo-box/model/combo-box.item';
import { ClientStatus } from '../models/client-status';

export class ClientStatusFilterConfiguration implements IComboBoxConfiguration {
    readonly options: ComboBoxItem<ClientStatus>[];

    constructor() {
        this.options = [
            new ComboBoxItem<ClientStatus>('All', null),
            new ComboBoxItem<ClientStatus>('Active', 'Active'),
            new ComboBoxItem<ClientStatus>('Inactive', 'Inactive'),
        ];
    }
    getInitialValue(): ComboBoxItem<any> {
        return this.options[1];
    }
    getOptions(): ComboBoxItem<any>[] {
        return this.options;
    }
    getPlaceHolderText(): string {
        return 'Pick filter by client status';
    }
}
