import { Injectable } from '@angular/core';

@Injectable()
export class CommonUtil {

  constructor() { }

  /**
   * @ngdoc method
   * @name hasContainsPropertiesWithNullValues
   * @methodOf CommonUtil
   * @description This method should return true if object contains properties with null values
   */
  public containsPropertiesWithNullValues(object) {
    for (const member in object) {
      if (object[member] == null) {
        return true;
      }
    }
    return false;
  }

  /**
   * @ngdoc method
   * @name setZerosValuesToNull
   * @methodOf CommonUtil
   * @description This method should set all object values to null if value is 0.
   */
  public setZerosValuesToNull(object) {
    for (const member in object) {
      if (object[member] === 0) {
        object[member] = null;
      }
    }
  }


}
