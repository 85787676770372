import { RouteService } from './route.service';
import { Injectable } from '@angular/core';
import { Permission } from './permission.enum';
import { AuthorizationGuard } from './authorization.guard';

@Injectable({
  providedIn: 'root'
})
export class PurgingToolGuard extends AuthorizationGuard {
  constructor(routeService: RouteService) {
    super(routeService, Permission.PurgingRead);
  }
}
