import { Injectable } from '@angular/core';
import { root } from 'rxjs/internal/util/root';
import { Observable, BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TitleService {

    private titleSubject = new BehaviorSubject<string>('FocusVision');

    public title(): Observable<string> {
        return this.titleSubject.pipe(share());
    }

    public setTitle(title: string) {
        this.titleSubject.next(title);
    }
}
