export class MandatoryClientDataModel {
    public poNumberRequired: boolean;
    public jobNumberRequired: boolean;
    public notes:string;
    
    constructor (){
        this.poNumberRequired = false;
        this.jobNumberRequired = false;
        this.notes = "";
    }
}
