import { Component, Input } from '@angular/core';
import { TaniPerson } from 'src/app/models/tani/tani-person';

@Component({
  selector: 'app-tani-persons-table-cell',
  templateUrl: './tani-persons-table-cell.component.html',
  styleUrls: ['./tani-persons-table-cell.component.css']
})
export class TaniPersonsTableCellComponent {

  @Input() persons: TaniPerson[];

}
