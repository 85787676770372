import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionRepoService {
  get accessToken(): string {
    return localStorage.getItem('accessToken');
  }

  set accessToken(value: string) {
    localStorage.setItem('accessToken', value);
  }

  get refreshToken() {
    return localStorage.getItem('refreshToken');
  }

  set refreshToken(value: string) {
    localStorage.setItem('refreshToken', value);
  }

  clear(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
}
