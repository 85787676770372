import { ExpirationValuePipe } from './expiration-value-pipe';
import { PipeTransform, Pipe} from '@angular/core';
import { AuditLogModel } from '../../models/audit-log-model';

@Pipe({name: 'auditLogEvent'})
export class AuditLogEventPipe implements PipeTransform {
  constructor(private expirationValuePipe: ExpirationValuePipe) { }

  transform(value: AuditLogModel): string {
    const d = value.logDetails.reduce((dictionary, i) => {
      dictionary[i.type] = i.value;
      return dictionary;
    }, {});

    switch (value.type) {
      case 'ClientChange':
        return `User ${d['User']} changed setting for client ${d['ClientId']} to ${this.getExpiration(d)}`;
      case 'ProjectChange':
        return `User ${d['User']} changed setting for project ${d['ProjectId']} to ${this.getExpiration(d)}`;
      case 'StateChange':
        return `${this.getState(d['State'])} project ${d['ProjectId']}`;
      case 'FileMove':
        return JSON.parse(d["Success"]) ? `File ${d['SourcePath']} moved to ${d['DestinationPath']}` : `File move skipped: The source file on the path ${d['SourcePath']} does not exist`;
      case 'FileDelete':
        return JSON.parse(d["Success"]) ? `File ${d['Path']} deleted` : `File delete skipped: File on the path ${d['Path']} does not exist`;
      case 'DirectoryMove':
        return JSON.parse(d["Success"]) ? `Directory ${d['SourcePath']} moved to ${d['DestinationPath']}` : `Directory move skipped: Directory on the path ${d['SourcePath']} does not exist`;
      case 'DirectoryDelete':
        return JSON.parse(d["Success"]) ? `Directory ${d['Path']} deleted` : `Directory delete skipped: Directory on the path ${d['Path']} does not exist`;
    }
  }

  private getExpiration(d: any): string {
    return this.expirationValuePipe.transform({ expirationType: d['ExpirationType'], expirationValue: d['ExpirationValue']});
  }

  private getState(state: string): string {
    switch (state) {
      case 'Trashing':
        return 'Started trashing';
      case 'Trashed':
        return 'Completed trashing';
      case 'Restoring':
        return 'Started restoring';
      case 'Restored':
        return 'Completed restoring';
      case 'Emptying':
        return 'Started purging';
      case 'Empty':
        return 'Completed purging';
    }
  }
}
