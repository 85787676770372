import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TimezoneService {

  private timezoneSubject = new Subject<any>();

  get timezone() {
    return this.timezoneSubject.asObservable().pipe(shareReplay(1));
  }

  setTimezone(timezone) {
    this.timezoneSubject.next(timezone);
  }

}
