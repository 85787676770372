import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatSort} from "@angular/material";
import { CustomMatPaginatorComponent } from "../feature/custom-mat-paginator/custom-mat-paginator.component";
import { OptedOutDomainModel } from "../models/opted-out-domain.model";
import { OptedOutDomainsService } from "../services/opted-out-domains.service";
import { LoadingSpinnerService } from "../util/loading-spinner/loading-spinner.service";

@Component({
  selector: 'app-domains-list',
  templateUrl: './opted-out-domains.component.html',
  styleUrls: ['./opted-out-domains.component.css']
})

export class OptedOutDomainsComponent implements OnInit, AfterViewInit{
  public dataSource: OptedOutDomainsService;
  @ViewChild(CustomMatPaginatorComponent) paginator: CustomMatPaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;

  public filter = new FormControl('', []);
  public addDomainField = new FormControl('', [Validators.pattern('\\b((?=[a-z0-9-]{1,63}\\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\\.)+[a-z]{2,63}\\b')]);

  constructor(domainsService: OptedOutDomainsService, private spinService: LoadingSpinnerService){
    this.dataSource = domainsService;
  }

  public columnsToDisplay = [
    'Domain',
    'DeleteBtn'
  ];

  ngOnInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadData();
    });
    this.paginator.page.subscribe(() => {
      this.loadData();
    });
    this.addDomainField.valueChanges.subscribe(event => {
      this.addDomainField.setValue(event.toLowerCase(), {emitEvent: false});
   });
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinService.spinnerSubject.next(true);
    this.dataSource.loadData(this.sort.direction, this.filter.value, this.paginator.pageIndex, this.paginator.pageSize)
    .subscribe(r => {
        this.paginator.length = r.totalCount;
        this.paginator.moreExist = r.moreExists;
    }, e => {}, () => {
        this.spinService.spinnerSubject.next(false);
    });
  }

  resetFilter(){
    this.filter.setValue("");
    this.loadData();
  }

  addDomain(){
    if(this.isDomainValid()){
      this.dataSource.addDomain(this.addDomainField.value).subscribe(null, null,
        () => {
          this.addDomainField.setValue("");
          this.addDomainField.markAsPristine();
          this.loadData();
        }
      );
    }
  }

  deleteDomain(rowData: OptedOutDomainModel){
    this.dataSource.deleteDomain(rowData.id).subscribe(null, null,
      () => {
        this.loadData();
      }
    );
   }

  convertToLowerCase(){
    this.addDomainField.setValue(this.addDomainField.value.toLowerCase());
  }

  isDomainValid(): boolean{
    return (this.addDomainField.pristine || this.addDomainField.valid) && this.addDomainField.value !== "";
  }
}
