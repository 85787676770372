import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { TimeUtil } from '../util/time-util';
import { LoadingSpinnerService } from '../util/loading-spinner/loading-spinner.service';
import { LoginService } from '../services/login.service';
import { AppConfigService } from '../services/app-config.service';
import { PaginationService } from '../services/pagination.service';
import { ProjectService } from '../services/project.service';
import { Project } from './project';
import { SortingUtil } from '../util/sorting-util';
import { TimezoneService } from '../services/timezone.service';

export class ProjectDataSource implements DataSource<any> {
  private dataSessionSubject = new BehaviorSubject<Project[]>([]);
  private sortField: string;
  private subs: { [key: string]: Subscription } = {};

  constructor(private timezoneService: TimezoneService,
    private paginationService: PaginationService,
    private timeUtil: TimeUtil,
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private projectService: ProjectService,
    private spinnerService: LoadingSpinnerService,
    private sort: MatSort,
    private paginator: MatPaginator,
    private type,
    private isFilter,
    private dateFrom?,
    private dateTo?,
    private keyWord?,
    private encoderStatus?: string) {
    this.setPaginatorPageIndexDependingOn(type);
    this.paginator.length = 0;
    this.paginator.pageSize = 10;

    // Subscription for sort event
    this.subs['sort'] = this.sort.sortChange
      .asObservable()
      .subscribe(result => {
        if (result) {
          this.sortField = SortingUtil.getSortingMaping()['projects'][result.active];
          this.getResults(this.paginator.pageIndex, this.sortField, result.direction, this.type, this.dateFrom, this.dateTo, this.keyWord, this.encoderStatus);
        }
      });

    // Get total count
    this.subs['projectTotalCount'] = this.projectService.projectTotalCount.subscribe(totalCount => {
      this.paginator.length = totalCount;
    });

    if (isFilter) {
      this.paginator.pageIndex = 0;
      this.setProjectPageNumberDependingOn(type);
      this.connect();
    }

    // Subscription for page event
    this.subs['paginatorSubscription'] = this.paginator.page.asObservable().subscribe(res => {
      this.setProjectPageNumberDependingOn(type);
      this.getResults(this.paginator.pageIndex, this.sortField, this.sort.direction, this.type, this.dateFrom, this.dateTo, this.keyWord, this.encoderStatus);
    });

    this.subs['timezoneSubscription'] = this.timezoneService.timezone.subscribe(() =>
      this.getResults(this.paginator.pageIndex, this.sortField,
        this.sort.direction, this.type, this.dateFrom, this.dateTo, this.keyWord, this.encoderStatus));
  }
  // Initiate data source
  connect(): Observable<Project[]> {
    if (!this.sortField) {
      this.sortField = SortingUtil.getSortingMaping()['projects'][this.sort.active];
    }

    this.getResults(this.paginator.pageIndex, this.sortField, this.sort.direction, this.type, this.dateFrom, this.dateTo, this.keyWord, this.encoderStatus);
    return this.dataSessionSubject.asObservable();
  }

  // Method for getting results
  getResults(pageIndex, sortField, order, type, dateFrom?, dateTo?, keyWord?, encoderStatus?: string) {
    this.spinnerService.spinnerSubject.next(true);
    this.projectService.getProjects(pageIndex, sortField, order, type, dateFrom, dateTo, keyWord, encoderStatus).subscribe(result => {
      this.handleResponse(result);
    });
  }

  disconnect() { }


  /**
   * @ngdoc method
   * @name handleResponse
   * @methodOf ProjectDataSource
   * @description This method should handle response from server
   */
  private handleResponse(response) {
    for (let i = 0; i < response.length; i++) {
      response[i].startTime = this.timeUtil.getDateTime(response[i].startTime);
      response[i].endTime = this.timeUtil.getDateTime(response[i].endTime);
      response[i].actualStartTime = this.timeUtil.getDateTime(response[i].actualStartTime);
      response[i].actualEndTime = this.timeUtil.getDateTime(response[i].actualEndTime);
    }

    this.dataSessionSubject.next(response);
    this.spinnerService.spinnerSubject.next(false);
  }

  /**
   * @ngdoc method
   * @name setProjectPageNumberDependingOn
   * @methodOf ProjectDataSource
   * @description This method should set project page number to paginator page index depending on type
   */
  private setProjectPageNumberDependingOn(type) {
    switch (type) {
      case 1:
        this.paginationService.liveStreamingProjectsPageNumber = this.paginator.pageIndex;
        break;
      case 2:
        this.paginationService.interVuProjectsPageNumber = this.paginator.pageIndex;
        break;
      case 3:
        this.paginationService.projectsAtRiskPageNumber = this.paginator.pageIndex;
        break;
      default:
        this.paginationService.allProjectsPageNumber = this.paginator.pageIndex;
        break;
    }
  }

  /**
   * @ngdoc method
   * @name setPaginatorPageIndexDependingOn
   * @methodOf ProjectDataSource
   * @description This method should set paginator page index to project page number depending on type
   */
  private setPaginatorPageIndexDependingOn(type) {
    switch (type) {
      case 1:
        this.paginator.pageIndex = this.paginationService.liveStreamingProjectsPageNumber;
        break;
      case 2:
        this.paginator.pageIndex = this.paginationService.interVuProjectsPageNumber;
        break;
      case 3:
        this.paginator.pageIndex = this.paginationService.projectsAtRiskPageNumber;
        break;
      default:
        this.paginator.pageIndex = this.paginationService.allProjectsPageNumber;
        break;
    }
  }

  /**
   * @ngdoc method
   * @name fetchFreshData
   * @methodOf ProjectDataSource
   * @description this method is used to fetch fresh data from the service directly after status change or reset filter
   */
  public fetchFreshData(
    keyword: string = '',
    dateFrom: string = null,
    dateTo: string = null,
    page: number = 0,
    type: number = null,
    encoderStatus: string = 'ALL'
  ): void {
    this.paginator.pageIndex = page;
    this.changePage(type, page);
    this.keyWord = keyword;
    this.dateFrom = dateFrom ? dateFrom : this.timeUtil.getDateTimeUTCFormat(new Date());
    this.dateTo = dateTo ? dateTo : this.timeUtil.getDateTimeUTCFormat(new Date());
    this.encoderStatus = encoderStatus;
    this.getResults(
      this.paginator.pageIndex,
      this.sortField,
      this.sort.direction,
      this.type,
      this.dateFrom,
      this.dateTo,
      this.keyWord,
      this.encoderStatus
    );
  }

  /**
   * @ngdoc method
   * @name changePage
   * @methodOf ProjectDataSource
   * @description this method is used to change page number
   */
  public changePage(type: number, page: number) {
    this.paginator.pageIndex = page;
    this.setProjectPageNumberDependingOn(type);
    this.setPaginatorPageIndexDependingOn(type);
  }

  /**
   * @ngdoc method
   * @name changeFilters
   * @methodOf ProjectDataSource
   * @description this method is used to reset service values of filters after sort is reset
   */
  public changeFilters(word: string, dateFrom: Date, dateTo: Date, status: string) {
    this.keyWord = word;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.encoderStatus = status;
  }

  public unsubscribeAll(): void {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
  }
}
