import { DefaultParams } from './default-params';
import { AllParams } from './all-params';

export class Encoder {
    id: string;
    encoderId: string;
    name: string;
    site: string;
    status: number;
    statusName: string;
    pcName: string;
    mac: string;
    encoderProjectType: number;
    encoderType: number;
    startTime: string;
    region: string;
    regionAbbr: string;
    lastErrorMessage: string;
    systemType: string;
    fileUploadSupport: boolean;
    currentVersion: string;
    updateVersion: string;
    room: string;
    updateStatus: string;

    defaultParams: DefaultParams;
    allParams: AllParams;
}

export interface EncoderSelect {
  id: number;
  name: string;
}

export class EncoderResponse {
  availableEncoders: Encoder[];
  totalCount: number;
}

export class NewEncoderResponse {
  newEncoders: Encoder[];
  totalCount: number;
}

export class RiskEncoderResponse {
  riskEncoders: Encoder[];
  totalCount: number;
}

export class FaultyEncoderResponse {
  faultyEncoders: Encoder[];
  totalCount: number;
}

export class EncodersVersionResponse {
  encoderSoftwareVersions: Encoder[];
  totalCount: number;
}

export class EncoderLog {
  totalCount: number;
  messages: LogMessage[];
  highestMessageId: number;
}

export class LogMessage {
  encoderStatus: number;
  sentAtEncoderTime: string;
  receivedAtServerTime: string;
  message: string;
  projectId: string;
  logLevel: number;
  fixed: boolean;
  encoderId: string;
}

export class VersionsResponse {
  versions: string[];
  totalCount: number;
}

export class Version {
  id: number;
  version: string;
  updateFiles: string[];
  releaseDate: string;
  systemType: string;
}

export class EncoderFilesResponse {
  filenames: string[];
}
