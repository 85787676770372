import { Injectable } from '@angular/core';

@Injectable()
export class PaginationService {
  public liveStreamingProjectsPageNumber = 0;
  public interVuProjectsPageNumber = 0;
  public projectsAtRiskPageNumber = 0;
  public allProjectsPageNumber = 0;
  public encodersPageNumber = 0;
  public encodersNewPageNumber = 0;
  public encodersAtRiskPageNumber = 0;
  public encodersWithErrorsPageNumber = 0;
  public encodersVersionHardwarePageNumber = 0;
  public encodersVersionSoftwarePageNumber = 0;
  public versionHardwarePageNumber = 0;
  public versionSoftwarePageNumber = 0;
  public encodersVersionPageNumber = 0;
}
