import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService } from './loading-spinner.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent implements OnInit {
  public showLoadingSpinner: boolean;
  constructor(private spinnerService: LoadingSpinnerService) { }

  ngOnInit() {
    this.spinnerService.spinnerSubject.subscribe(res => {
      this.showLoadingSpinner = res;
    });
  }

}
