import {Injectable} from '@angular/core';

/**
 * @ngdoc utils
 * @name sorting-util.util.SortingUtil
 * @description This service should handle all constants and dummy data used in app
 **/

@Injectable()
export class SortingUtil {

  public static getSortingMaping() {
    return  {
      projects: {
        projectId: 'ProjectId',
        name: 'Name',
        time: 'StartTime',
        actualTime: 'ActualStartTime',
        facility: 'Facility',
        region: 'RegionAbbr',
        overridden: 'ManuallyOverrideTime',
        encoderName: 'encoderName',
        pcName: 'PcName',
        sentAtEncoderTime: 'sentAtEncoderTime',
        encoderId: 'encoderId',
        encoderStatus: 'encoderStatus',
        room: 'Room',
        status: 'Status',
        completed: 'Completed',
        numberOfErrors: 'numberOfErrors',
        videoInsightsRegion: 'videoInsightsRegionAbbr',
        videoInsights: 'IsVideoInsights',
        canceled: 'canceled',
        manuallyStopped: 'manuallyStopped',
        receivedAtServerTime: 'receivedAtServerTime',
        message: 'message',
        logLevel: 'logLevel',
        fixed: 'fixed',
        projectType: 'projectType',
        automated: 'automated'
      },
      encoders: {
        name: 'Name',
        pcName: 'PcName',
        facility: 'Facility',
        region: 'RegionAbbr',
        currentVersion: 'CurrentVersion',
        updateVersion: 'UpdateVersion',
        site: 'Site',
        room: 'Room',
        status: 'UpdateStatus',
        encoderProjectType: 'projectType',
        mac: 'mac',
        statusName: 'status',
        error: 'error',
        lastErrorMessage: 'LastErrorMessage',
        encoderStatus: "EncoderStatus",
        fixed: 'Fixed',
        logLevel: 'LogLevel',
        message: 'Message',
        projectId: 'ProjectId',
        receivedAtServerTime: 'ReceivedAtServerTime',
        sentAtEncoderTime: 'SentAtEncoderTime',
      },
      uploadFiles: {
        file: 'file_name',
        argumentLine: 'execute_argument'
      },
      versions: {
        version: 'update_version'
      }
    };
  }
}
