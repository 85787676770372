import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { positiveInt32Validator } from 'src/app/util/positiveInt32Validator';
import { CopyMarksService } from '../../services/copy-marks.service';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-copy-marks',
  templateUrl: './copy-marks.component.html',
  styleUrls: ['./copy-marks.component.css']
})
export class CopyMarksComponent implements OnInit {

  public filter = new FormControl('', []);
  public destinationProjectId = new FormControl('', [positiveInt32Validator]);
  public numberOfMarks = 0;

  constructor(public dataSource: CopyMarksService, private spinService: LoadingSpinnerService,
     private snackBar: MatSnackBar) { }
  
  public columnsToDisplay = [
    'formattedStartTime', 'markTitle'
  ];

  ngOnInit(): void {}

  loadData() {
    this.spinService.spinnerSubject.next(true);
    this.dataSource.loadData(this.filter.value)
    .subscribe(r => {
      this.numberOfMarks = r.marks.length;
    }, e => {}, () => {
        this.spinService.spinnerSubject.next(false);
    });
  }

  public copyMarks() {
    let destProjectId = this.destinationProjectId.value;
    this.destinationProjectId.reset();

    this.spinService.spinnerSubject.next(true);
    this.dataSource.copyMarksToProject(destProjectId).subscribe(res => {
          this.spinService.spinnerSubject.next(false);
          this.snackBar.open('Marks successfully copied.', 'Ok', {duration: 2000});
      });
  }

  resetFilter(){
    this.filter.setValue("");
    this.numberOfMarks = 0;
  }
}
