import { UserMessageService } from './user-message.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HandleAnyHttpError implements HttpInterceptor {
  constructor(private messageService: UserMessageService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error) => {
      this.messageService.showError(`An error occurred (${error.status}), please try again later.`);

      return EMPTY;
    }));
  }
}
