import { Component, Input, OnInit, Output } from '@angular/core';
import { ColumnStateModel } from 'src/app/models/column-state';
import { ColumnStateService } from 'src/app/services/column-state.service';

@Component({
  selector: 'app-column-visibility-toolbar',
  templateUrl: './column-visibility-toolbar.component.html',
  styleUrls: ['./column-visibility-toolbar.component.css']
})
export class ColumnVisibilityToolbarComponent implements OnInit {

  @Input() hideToolbar: boolean;
  @Input() allTableColumnsInput: string[];
  @Input() columnsForToolbar: any[];
  @Input() currentRoute: string;

  constructor(private checkBoxService: ColumnStateService) { }

  ngOnInit() {
    this.columnsForToolbar.forEach(column => {
      const state = this.checkBoxService.getState(this.currentRoute, column.value).checked;
      const index = this.allTableColumnsInput.findIndex(colName => colName === column.value);
      if (state) {
        if (index < 0) {
          this.allTableColumnsInput.push(column.value);
        }
      } else {
        if (index > -1) {
          this.allTableColumnsInput.splice(index, 1);
        }
      }
    });
  }

  /**
   * @ngdoc method
   * @name ColumnVisibilityToolbarComponent
   * @methodOf LiveStreamingComponent
   * @description This method should add od remove column by name
   */
  public showHideColumn(event: ColumnStateModel, name) {
    this.checkBoxService.getState(this.currentRoute, name).checked = event.checked;
    const colIndex = this.allTableColumnsInput.findIndex(col => col === name);
    if (colIndex > -1) {
      this.allTableColumnsInput.splice(colIndex, 1);
    } else {
      this.allTableColumnsInput.push(name);
    }
  }

  public getState(colName: any) {
      return this.checkBoxService.getState(this.currentRoute, colName).checked;
  }

}
