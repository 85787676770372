import { ExpirationValuePipe } from './expiration-value-pipe';
import { ProjectExceptionModel } from 'src/app/models/project-exception-model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientExpirationValue'
})
export class ClientExpirationValuePipe implements PipeTransform {
  constructor(private expirationValuePipe: ExpirationValuePipe) {}
  transform(value: ProjectExceptionModel): string {
    const clientExpirationRule = {expirationType: value.clientExpirationType, expirationValue: value.clientExpirationValue};
    const result = this.expirationValuePipe.transform(clientExpirationRule);
    if (value.clientExpirationType !== 'Default') {
      return `Client set to ${result}`;
    }
    return result;
  }

}
