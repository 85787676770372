import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-timezone-clock',
  templateUrl: './timezone-clock.component.html',
  styleUrls: ['./timezone-clock.component.scss']
})
export class TimezoneClockComponent implements OnInit {
  public timeZoneClock = '';
  constructor() {  }

  ngOnInit() {
    this.generateClock();
  }
  /**
   * @ngdoc method
   * @name generateClock
   * @methodOf TimezoneClockComponent
   * @description This method generates clock by getting current time and switching time zone to EST.
   */
  /* istanbul ignore next */
   private generateClock() {
      setTimeout(() => {
        // Create current time in moment js with est
        const momentTime = moment.tz(new Date(), 'EST').format(`h,m,s`);
        const momentArray = momentTime.split(',');
        // Split array and concat it to clock
        let h = momentArray[0]; // 0 - 23
        let m = momentArray[1]; // 0 - 59
        let s = momentArray[2]; // 0 - 59
        let session = 'AM';

        if (h == 0) {
            h = 12;
        }

        if (h > 12) {
            h = h - 12;
            session = 'PM';
        }

        h = (h < 10) ? '0' + h : h;
        m = (m < 10) ? '0' + m : m;
        s = (s < 10) ? '0' + s : s;

        const time = h + ':' + m + ':' + s + ' ' + session;
        this.timeZoneClock = time;
        this.generateClock();
    }, 1000);

  }
}
