import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLSearchParams } from '@angular/http';
import { GhQueryEncoder } from './../util/gh.query.encoder';
import { TimeUtil } from './../util/time-util';
import { Project, ProjectResponse } from './../models/project';
import { AppConfigService} from './app-config.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

/**
 * @ngdoc service
 * @name project.service.ProjectService
 * @requires HttpClient
 * @description This service should handle all project data from API
 **/
export class ProjectService {
  public projectList: Project[];
  public projectTotalCount = new BehaviorSubject<number>(null);
  public intervuProjectsSearchParams = new BehaviorSubject<any>(null);
  public liveProjectsSearchParams = new BehaviorSubject<any>(null);
  public atRiskProjectsSearchParams = new BehaviorSubject<any>(null);
  public allProjectsSearchParams = new BehaviorSubject<any>(null);
  public projectsEncoderStatus = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient, private appConfig: AppConfigService, private timeUtil: TimeUtil) {
    this.projectList = [];
  }

  /**
   * @ngdoc method
   * @name getProjects
   * @methodOf ProjectService
   * @params page, type, dateFrom? - optional, dateTo? - optional, keyWord? - optional
   * @returns Observable< ProjectResponse[]> Observable list of projects
   * @description This method should return list of projects projects from API
   */
  public getProjects(page, sortField, order, type, dateFrom?, dateTo?, keyWord?, encoderStatus?: string): Observable<Project[]> {

    const urlSearchParams = new URLSearchParams('', new GhQueryEncoder());

    urlSearchParams.append('page', page + 1);
    urlSearchParams.append('size', '10');
    urlSearchParams.append('type', type);

    if (sortField !== undefined && sortField.length !== 0) {
      urlSearchParams.append('field', sortField);
    }

    if (order !== undefined && order.length !== 0) {
      urlSearchParams.append('order', order);
    }

    if (dateFrom !== 'Invalid date' && dateFrom !== undefined && dateTo !== 'Invalid date' && dateTo !== undefined) {
      urlSearchParams.append('dateFrom', this.timeUtil.getDateFromForQueryParam(dateFrom));
      urlSearchParams.append('dateTo', this.timeUtil.getDateToForQueryParam(dateTo));
    }

    if (keyWord !== undefined && keyWord.length !== 0) {
      urlSearchParams.append('keyword', keyWord);
    }

    if (encoderStatus && encoderStatus !== 'ALL') {
      urlSearchParams.append('status', encoderStatus);
    }

    const body = urlSearchParams.toString();

    return this.http.get<ProjectResponse>(this.appConfig.getUrl(`/projects/?` + body), this.appConfig.getHeaders())
      .pipe(map(res => {
        this.projectTotalCount.next(res.totalCount);
        return res.projects;
      },  error => {
        console.log('error');
      }
    ));
  }

  /**
   * @ngdoc method
   * @name getOneProject
   * @params id
   * @methodOf ProjectService
   * @returns Observable< Project[]> Observable list of projects
   * @description This method should return single projects from API
   */
  public getOneProject(id): Observable<Project> {
    return this.http.get<Project>(this.appConfig.getUrl(`/projects/${id}`), this.appConfig.getHeaders())
      .pipe(map(res => {
        return res;
    }));
  }

  /**
   * @ngdoc method
   * @name updateProject
   * @params id, Project
   * @methodOf ProjectService
   * @returns TODO
   * @description This method should update project
   */
  public updateProject(id, project): Observable<any> {
    return this.http.put<Project>(this.appConfig.getUrl(`/projects/${id}`), project, this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name startRecordingLive
   * @params id
   * @methodOf ProjectService
   * @returns TODO
   * @description This method should start recording for project
   */
  public startRecordingLive(id): Observable<any> {
    return this.http.post(this.appConfig.getUrl(`/projects/${id}/start`), this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name stopRecordingLive
   * @params id
   * @methodOf ProjectService
   * @returns TODO
   * @description This method should stop recording for project
   */
  public stopRecordingLive(id): Observable<any> {
    return this.http.post(this.appConfig.getUrl(`/projects/${id}/stop`), this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name rejoinInterVuProject
   * @params id
   * @methodOf ProjectService
   * @returns TODO
   * @description This method sends rejoin request to server
   */
  public rejoinInterVuProject(id): Observable<any> {
    return this.http.post(this.appConfig.getUrl(`/projects/${id}/rejoin`), this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name overrideTimes
   * @params id, timesData
   * @methodOf ProjectService
   * @returns TODO
   * @description This method should override times
   */
  public overrideTimes(id, timesData): Observable<any> {
    timesData.id = id;
    return this.http.patch<Project>(this.appConfig.getUrl(`/projects/${id}/actual_times`), timesData, this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name updateAutomated
   * @params id, automatedData
   * @methodOf ProjectService
   * @returns TODO
   * @description This method should update automated field
   */
  public updateAutomated(id, automatedData): Observable<any> {
    return this.http.patch<Project>(this.appConfig.getUrl(`/projects/${id}/automated`), automatedData, this.appConfig.getHeaders());
  }
}
