import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EncoderService } from '../../../../services/encoder.service';

@Component({
  selector: 'app-activate-encoder-dialog',
  templateUrl: './activate-encoder-dialog.component.html',
  styleUrls: ['./activate-encoder-dialog.component.css']
})
export class ActivateEncoderDialogComponent implements OnInit {

  form: FormGroup;
  public activationTypeMessageOnEncoderModal = '';
  constructor(public encoderService: EncoderService, private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<ActivateEncoderDialogComponent>) {

    encoderService.activationTypeMessageOnEncoderModal.subscribe(message => {
      this.activationTypeMessageOnEncoderModal = message;
    });

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      message: ''
    });
  }

  /**
   * @ngdoc method
   * @name submit
   * @methodOf ActivateEncoderDialogComponent
   * @description This method should submit modal dialog form and send 'OK' value if answer is 'OK'
   */
  submit(form) {
    this.dialogRef.close(`${'OK'}`);
  }

  /**
   * @ngdoc method
   * @name cancel
   * @methodOf ActivateEncoderDialogComponent
   * @description This method should cancel modal dialog and send 'Cancel' value if answer is 'Cancel'
   */
  cancel() {
    this.dialogRef.close(`${'Cancel'}`);
  }

}
