import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClientTableModel } from 'src/app/models/client-table-model';
import { Confirms } from 'src/app/models/confirms.type';
import { MandatoryClientDataDialogConfirmationModel } from 'src/app/models/mandatory-client-data-dialog-confirmation-model';
import { MandatoryClientDataModel } from 'src/app/models/mandatory-client-data-model';import { MandatoryClientDataService } from 'src/app/services/mandatory-client-data-service';
import { MandatoryClientDataDialogConfirmationComponent } from '../mandatory-client-data-dialog-confirmation/mandatory-client-data-dialog-confirmation.component';

@Component({
  selector: 'app-mandatory-client-data-dialog',
  templateUrl: './mandatory-client-data-dialog.component.html',
  styleUrls: ['./mandatory-client-data-dialog.component.css']
})
export class MandatoryClientDataDetailsComponent {
  private clientModel: ClientTableModel;
  public mandatoryData: MandatoryClientDataModel = new MandatoryClientDataModel();

  constructor(@Inject(MAT_DIALOG_DATA) public data: ClientTableModel,
    private dialogRef: MatDialogRef<MandatoryClientDataDetailsComponent>,
    private dialog: MatDialog,
    public dataSource: MandatoryClientDataService,) {
      this.clientModel = data;
      this.dataSource.loadData(this.clientModel.clientId).subscribe(man => {
        this.mandatoryData = man;
    });
    }

  save(): void {
    const dialogRef = this.dialog.open<MandatoryClientDataDialogConfirmationComponent, MandatoryClientDataDialogConfirmationModel, Confirms>
    (MandatoryClientDataDialogConfirmationComponent, {
        width: '500px',
        data: new MandatoryClientDataDialogConfirmationModel(
          this.clientModel.clientName,
          this.mandatoryData,
          'Confirm changes',
          this.clientModel.clientId
        )
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result === 'OK') {
            this.dialogRef.close(new MandatoryClientDataDialogConfirmationModel(
              this.clientModel.clientName,
              this.mandatoryData,
              'Confirm changes',
              this.clientModel.clientId));
        }
    });
  }

  cancel(): void {
      this.dialogRef.close();
  }
}
