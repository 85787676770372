import { RouteType } from './../route-type.enum';
import { RouteService } from './../route.service';
import { Component} from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Router, NavigationStart} from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { TimezoneService } from './../services/timezone.service';
import { UserService } from './../user.service';
import { Permission } from './../permission.enum';
import { FeatureService } from '../feature.service';

@Component({
  selector: 'app-encoder-root',
  templateUrl: './encoder-root.component.html',
  styleUrls: ['./encoder-root.component.css']
})
export class EncoderRootComponent {

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);

  public Permission = Permission;
  public RouteType = RouteType;
  public timezones = [{name: 'Local', value: moment.tz.guess()}, {name: 'ET', value: 'America/New_York'}];
  public selectedTimezone = this.timezones[1].value;
  public currentRoute;
  public showMenu = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    router: Router,
    private timezoneService: TimezoneService,
    public routeService: RouteService,
    public userService: UserService,
    public featureService: FeatureService
    ) {
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          this.currentRoute = event.url.split('/')[2] !== undefined ?
            event.url.split('/')[1] + '/' + event.url.split('/')[2] :
            event.url.split('/')[1] ;
        }
      });
  }

  onTimeZoneChange() {
    this.timezoneService.setTimezone(this.selectedTimezone);
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

}
