import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Encoder } from './encoder';
import { EncoderService } from '../services/encoder.service';
import { LoginService } from '../services/login.service';
import { AppConfigService } from '../services/app-config.service';
import { LoadingSpinnerService } from '../util/loading-spinner/loading-spinner.service';
import { PaginationService } from '../services/pagination.service';
import { SortingUtil } from '../util/sorting-util';

export class EncoderDataSource implements DataSource<any> {
  private dataSessionSubject = new BehaviorSubject<Encoder[]>([]);
  private sortField: string;
  private route: string;
  private subs: { [key: string]: Subscription } = {};
  private projectIdDefaultValue = undefined;

  constructor(
    private paginationService: PaginationService,
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private encoderService: EncoderService,
    private spinnerService: LoadingSpinnerService,
    private sort: MatSort,
    private paginator: MatPaginator,
    private currentRoute: string,
    private isListOfActive?: string,
    private keyWord?,
    private projectId?,
    private systemType?: string,
    private encoderVersionStatus?: string,
    private encodersAtRiskStatus?: string,
    private encodersProjectType?: string
  ) {
    this.route = this.currentRoute.substr(1);
    this.setPaginatorPageIndexDependingOn(currentRoute);
    this.paginator.length = 100;
    this.paginator.pageSize = 10;

    // Subscription for sort event
    this.subs['sortSubscription'] = this.sort.sortChange
      .asObservable()
      .subscribe(result => {
        if (result) {
          this.sortField = SortingUtil.getSortingMaping()['encoders'][result.active];
          this.reloadData();
        }
      });

    // Get total count
    this.subs['encodersTotalCount'] = this.encoderService.encodersTotalCount.subscribe(totalCount => {
      this.paginator.length = totalCount;
    });

    // Subscription for page event
    this.subs['paginatorSubscription'] = this.paginator.page.asObservable().subscribe(res => {
      this.setEncoderPageNumberDependingOn(currentRoute);
      this.reloadData();
    });
  }

  connect(): Observable<Encoder[]> {
    if (!this.sortField) {
      this.sortField = SortingUtil.getSortingMaping()['encoders'][this.sort.active];
    }
    this.reloadData();
    return this.dataSessionSubject.asObservable();
  }

  /**
  * @ngdoc method
  * @name getResults
  * @methodOf EncoderDataSource
  * @description This method should get results from server.
  */
  public getResults(route, pageIndex, sortField, order, systemType: string, isListOfActive?, keyWord?, projectId?, encoderVersionStatus?, encodersAtRiskStatus?, encodersProjectType?) {
    this.spinnerService.spinnerSubject.next(true);
    this.encoderService.getEncoders(route, pageIndex, sortField, order, systemType, isListOfActive, keyWord, projectId, encoderVersionStatus, encodersAtRiskStatus, encodersProjectType).subscribe(result => {
      this.handleResponse(result);
    });
  }

  public reloadData(): void {
    this.getResults(
      this.route,
      this.paginator.pageIndex,
      this.sortField,
      this.sort.direction,
      this.systemType,
      this.isListOfActive,
      this.keyWord,
      this.projectId,
      this.encoderVersionStatus,
      this.encodersAtRiskStatus,
      this.encodersProjectType
    );
  }

  disconnect() { }

  /**
   * @ngdoc method
   * @name getEncoderStatusNameById
   * @methodOf EncoderDataSource
   * @description This method should return string value for encoder status name by encoder status id
   */
  public getEncoderStatusNameById(id) {
    let encoderStatusName: string;
    switch (id) {
      case 1:
        encoderStatusName = 'ACTIVATED';
        break;
      case 2:
        encoderStatusName = 'DEACTIVATED';
        break;
    }
    return encoderStatusName;
  }

  public setSystemType(systemType: string): void {
    this.systemType = systemType;
  }

  /**
   * @ngdoc method
   * @name handleResponse
   * @methodOf EncoderDataSource
   * @description This method should handle response from server
   */
  private handleResponse(response) {
    for (let i = 0; i < response.length; i++) {
      response[i].statusName = this.getEncoderStatusNameById(response[i].status);
    }

    this.dataSessionSubject.next(response);
    this.spinnerService.spinnerSubject.next(false);
  }

  /**
   * @ngdoc method
   * @name setPaginatorPageIndexDependingOn
   * @methodOf EncoderDataSource
   * @description This method should set paginator page index to encoder page number depending on current route
   */
  private setPaginatorPageIndexDependingOn(currentRoute) {
    switch (currentRoute.substr(1)) {
      case 'encoders':
        this.paginator.pageIndex = this.paginationService.encodersPageNumber;
        break;
      case 'encoders-new':
        this.paginator.pageIndex = this.paginationService.encodersNewPageNumber;
        break;
      case 'encoders-at-risk':
        this.paginator.pageIndex = this.paginationService.encodersAtRiskPageNumber;
        break;
      case 'encoders-faulty':
        this.paginator.pageIndex = this.paginationService.encodersWithErrorsPageNumber;
        break;
      case 'encoders-version':
        this.paginator.pageIndex = this.systemType === 'hardware' ?
          this.paginationService.encodersVersionHardwarePageNumber
          : this.paginationService.encodersVersionSoftwarePageNumber;
        break;
    }
  }


  /**
   * @ngdoc method
   * @name setProjectPageNumberDependingOn
   * @methodOf EncoderDataSource
   * @description This method should set encoder page number to paginator page index depending on current route
   */
  private setEncoderPageNumberDependingOn(currentRoute) {
    switch (currentRoute.substr(1)) {
      case 'encoders':
        this.paginationService.encodersPageNumber = this.paginator.pageIndex;
        break;
      case 'encoders-new':
        this.paginationService.encodersNewPageNumber = this.paginator.pageIndex;
        break;
      case 'encoders-at-risk':
        this.paginationService.encodersAtRiskPageNumber = this.paginator.pageIndex;
        break;
      case 'encoders-faulty':
        this.paginationService.encodersWithErrorsPageNumber = this.paginator.pageIndex;
        break;
      case 'encoders-version':
        if (this.systemType === 'hardware') {
          this.paginationService.encodersVersionHardwarePageNumber = this.paginator.pageIndex;
        } else {
          this.paginationService.encodersVersionSoftwarePageNumber = this.paginator.pageIndex;
        }
        break;
    }
  }

  /**
   * @ngdoc method
   * @name fetchFreshData
   * @methodOf EncoderDataSource
   * @description
   * This method is used to fetch fresh data
   * from the service directly after status chang, reset filter or page refresh.
   */
  public fetchFreshData(keyword: string, projectId: string, pageNumber: number = 0,
    status: string = null, route: string, encoderVersionStatus: string, encodersAtRiskStatus: string, encodersProjectType: string): void {
    this.paginator.pageIndex = pageNumber;
    this.setEncoderPageNumberDependingOn(route);
    this.setPaginatorPageIndexDependingOn(route);
    this.keyWord = keyword;
    this.projectId = projectId;
    this.isListOfActive = status;
    this.encoderVersionStatus = encoderVersionStatus;
    this.encodersAtRiskStatus = encodersAtRiskStatus;
    this.encodersProjectType = encodersProjectType;
    this.reloadData();
  }

  /**
   * @ngdoc method
   * @name changePage
   * @methodOf EncoderDataSource
   * @description this method is used to page number
   */
  public changePage(route: string, page: number) {
    this.paginator.pageIndex = page;
    this.setEncoderPageNumberDependingOn(route);
    this.setPaginatorPageIndexDependingOn(route);
  }

  /**
   * @ngdoc method
   * @name changeFilters
   * @methodOf EncoderDataSource
   * @description this method is used to reset service values of filters after sort is reset
   */
  public changeFilters(word: string, projectId: number, status: string, encoderVersionStatus: string, encodersAtRiskStatus: string, encodersProjectType: string) {
    this.keyWord = word;
    this.projectId = projectId;
    this.isListOfActive = status;
    this.encoderVersionStatus = encoderVersionStatus;
    this.encodersAtRiskStatus = encodersAtRiskStatus;
    this.encodersProjectType = encodersProjectType;
  }

  public unsubscribeAll(): void {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
  }
}
