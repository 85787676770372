import { Component, Inject, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatButtonToggleChange, MatDialogRef, MatDialog } from '@angular/material';
import { ProjectExceptionModel } from 'src/app/models/project-exception-model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ExpirationProjectInformation } from 'src/app/models/expiration-project-information.model';
import { PurgingDialogConfirmationComponent } from '../purging-dialog-confirmation/purging-dialog-confirmation.component';
import { PurgingDialogComfirmationModel } from 'src/app/models/purging-dialog-comfirmation.model';
import { Confirms } from 'src/app/models/confirms.type';

@Component({
    selector: 'app-dialog-purging-manage-project-data',
    templateUrl: './purging-project-data-dialog.component.html',
    styleUrls: [
        './purging-project-data-dialog.component.css'
    ]
})
export class PurgingProjectDataDialogComponent  implements OnInit, AfterViewInit {
    readonly dataToApply: ProjectExceptionModel;
    public expireInDays: FormControl;
    public formGroup: FormGroup;

    @ViewChild('customValueInput')
    private customValueInput: ElementRef;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ExpirationProjectInformation,
                private dialogRef: MatDialogRef<PurgingProjectDataDialogComponent>,
                private dialog: MatDialog) {
        this.dataToApply = { ...data.projectSettings };
        if (!this.dataToApply.expirationValue) {
            this.dataToApply.expirationValue = this.data.defaultValue;
        }
    }
    ngAfterViewInit(): void {
        if (this.dataToApply.expirationType === 'Custom') {
            this.formGroup.get('expireInDays').setValue(this.data.projectSettings.expirationValue);
        }
    }

    @ViewChild('customValueInput') set content(input: ElementRef) {
        if (input) {
            this.customValueInput = input;
            input.nativeElement.value = this.data.projectSettings.expirationValue;
        }
    }

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            'expireInDays' : new FormControl(this.data.defaultValue,
                {updateOn: 'blur', validators: Validators.compose([Validators.required, Validators.min(0), Validators.max(2147483647)])})
        });
    }

    save(): void {
        if (this.dataToApply.expirationType === 'Custom') {
            if (!this.formGroup.valid) {
                if (this.formGroup.get('expireInDays').errors.required) {
                    alert('Days to expire is required');
                } else if (this.formGroup.get('expireInDays').errors.min) {
                    alert('Days to expire has too low value');
                } else {
                    alert('Days to expire has too high value');
                }
                return;
            }
            this.dataToApply.expirationValue = this.formGroup.get('expireInDays').value;
        }

        const dialogRef = this.dialog.open<PurgingDialogConfirmationComponent, PurgingDialogComfirmationModel, Confirms>
        (PurgingDialogConfirmationComponent, {
            data: new PurgingDialogComfirmationModel(this.dataToApply.id.toString(), 'Project',
                this.data.projectSettings, this.dataToApply, 'Confirm changes')
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'OK') {
                this.dialogRef.close(this.dataToApply);
            }
        });
    }

    valueSelected(data: MatButtonToggleChange) {
        this.dataToApply.expirationType = data.value;
        if (this.dataToApply.expirationType === 'Default') {
            this.dataToApply.expirationValue = this.data.defaultValue;
        } else if (this.dataToApply.expirationType === 'Custom') {
            this.formGroup.get('expireInDays').setValue(this.data.projectSettings.expirationValue);
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
