import { UserMessageService } from './user-message.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable()
export class Handle403 implements HttpInterceptor {
  constructor(private userService: UserService, private messageService: UserMessageService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => {
      if (error.status !== 403) {
        return throwError(error);
      }

      this.userService.deleteSession();
      this.messageService.showError('Permissions changed, please login again.');

      return EMPTY;
    }));
  }
}
