import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService} from './app-config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Site, Room } from './../models/location';

@Injectable()
export class LocationService {

  constructor(private http: HttpClient, private appConfig: AppConfigService) { }

   /**
     * @ngdoc method
     * @name getSites
     * @methodOf LocationService
     * @description This method should return sites by region id and keyword
     */
  public getSites(regionId, keyword): Observable<Site[]> {
     let body = new HttpParams();
     body = body.set('regionId', regionId);
     body = body.set('keyWord', keyword);
     return this.http.get<Site[]>(this.appConfig.getUrl(`/location/sites`), this.appConfig.getHeaders(body)).pipe(map(sites => {
       return sites;
     }));

  }

  /**
   * @ngdoc method
   * @name getRooms
   * @methodOf LocationService
   * @description This method should return rooms by site id and keyword
   */
  public getRooms(siteId): Observable<Room[]> {
    let body = new HttpParams();
    body = body.set('siteId', siteId);
    return this.http.get<Room[]>(this.appConfig.getUrl(`/location/rooms`), this.appConfig.getHeaders(body)).pipe(map(rooms => {
      return rooms;
    }));
  }
}
