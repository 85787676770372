import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { MarksToCopyDto } from "../models/marks-to-copy-dto";
import { MarksToCopyTableModel } from "../models/marks-to-copy-table-model";
import { MarksToCopyTableModelDto } from "../models/marks-to-copy-table-model-dto";
import { AppConfigService } from "./app-config.service";
import * as moment from 'moment-timezone';

@Injectable({
    providedIn : 'root'
})
export class CopyMarksService implements DataSource<MarksToCopyTableModel>{
    private dataSource: BehaviorSubject<MarksToCopyTableModel[]> = new BehaviorSubject<MarksToCopyTableModel[]>(null);
    constructor(private httpClient: HttpClient, private appConfig: AppConfigService){}

    connect(collectionViewer: CollectionViewer): Observable<MarksToCopyTableModel[]> {
        return this.dataSource.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        return this.dataSource.complete();
    }

    private getCopyMarksUrlPath(projectId: string): string{
        return  `/projects/${encodeURIComponent(projectId)}/marks_to_copy`;
    } 

    public loadData(projectId: string) : Observable<MarksToCopyTableModelDto>{
        return this.httpClient.get<MarksToCopyTableModelDto>(
            this.appConfig.getWalnutUrl(this.getCopyMarksUrlPath(projectId)), this.appConfig.getHeaders())
        .pipe(tap(res => {
            res.marks.forEach(mark => {
                    mark.formattedStartTime = moment(new Date(mark.startTimeSeconds * 1000)).format("hh:mm:ss");
            });
            this.dataSource.next(res.marks);
        }));
    }

    public copyMarksToProject(projectId: string){    
        return this.httpClient.post<MarksToCopyTableModelDto>(
            this.appConfig.getWalnutUrl(this.getCopyMarksUrlPath(projectId)), 
            new MarksToCopyDto(this.dataSource.getValue().map(({id}) => id)), 
            this.appConfig.getHeaders() )
        .pipe(map(res => {}));
    }
}
