import { EncoderRootComponent } from './root/encoder-root.component';
import { SpecificRouteGuard } from './specific-route.guard';
import { Routes } from '@angular/router';
import { EncodersComponent } from './feature/encoders/encoders.component';
import { EncodersNewComponent } from './feature/encoders-new/encoders-new.component';
import { EncodersAtRiskComponent } from './feature/encoders-at-risk/encoders-at-risk.component';
import { ProjectInnerViewComponent } from './projects/project-inner-view/project-inner-view.component';
import { EncoderInnerViewComponent } from './feature/encoders/encoder-inner-view/encoder-inner-view.component';
import { EncodersFaultyComponent } from './feature/encoders-faulty/encoders-faulty.component';
import { EncoderLogComponent } from './feature/encoders-faulty/encoder-log/encoder-log.component';
import { EncodersVersionComponent } from './feature/encoders-version/encoders-version.component';
import { VersionsComponent } from './feature/encoders-version/versions/versions.component';
import { UploadFilesComponent } from './feature/encoders-version/upload-files/upload-files.component';
import { UploadComponent } from './feature/encoders/upload/upload.component';
import { ProjectsComponent } from './projects/projects.component';
import { PurgingClientExceptionComponent } from './purging/components/purging-client-exception/purging-client-exception.component';
import { PurgingProjectExceptionComponent } from './purging/components/purging-project-exception/purging-project-exception.component';
import { PurgingAuditLogComponent } from './purging/components/purging-audit-log/purging-audit-log.component';
import { DefaultRouteGuard } from './default-route.guard';
import { EncoderAutomationGuard } from './encoder-automation.guard';
import { PurgingToolGuard } from './purging-tool.guard';
import { UnknownRouteGuard } from './unknown-route.guard';
import { OptedOutDomainsComponent } from './opted-out-domains/opted-out-domains.component';
import { CopyMarksComponent } from './projects/copy-marks/copy-marks.component';
import { MandatoryClientDataComponent } from './feature/mandatory-client-data/mandatory-client-data.component';
import { TaniDashboardProjectsComponent } from './feature/tani-dashboard/tani-dashboard-projects/tani-dashboard-projects.component';
import { TaniDashboardSessionsComponent } from './feature/tani-dashboard/tani-dashboard-sessions/tani-dashboard-sessions.component';
import { FeatureEnabledGuard } from './feature-enabled.guard';
import { FeatureToggleGuard } from './feature-toggle.guard';

export const appRoutes: Routes = [
  // It appears either canActivate or canActivateChild can be used to guard both component and children, using only canActivate to simplify
  // Either component, redirectTo or chilren property has to be set to use the guard
  // If any guard returns false no navigation occurs
  { path: '', component: EncoderRootComponent, children: [
      { path: '', canActivate: [DefaultRouteGuard], children: [] },
      { path: '', canActivate: [SpecificRouteGuard], children: [
        { path: '', canActivate: [EncoderAutomationGuard], children: [
          { path: 'live-streaming', component: ProjectsComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'intervu', component: ProjectsComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'projects-at-risk', component: ProjectsComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'all-projects', component: ProjectsComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoders', component: EncodersComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoders-new', component: EncodersNewComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoders-at-risk', component: EncodersAtRiskComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoders-faulty', component: EncodersFaultyComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'live-streaming/project-detail/:id', component: ProjectInnerViewComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'intervu/project-detail/:id', component: ProjectInnerViewComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'projects-at-risk/project-detail/:id', component: ProjectInnerViewComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'all-projects/project-detail/:id', component: ProjectInnerViewComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoder/encoder-detail/:id', component: EncoderInnerViewComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoder/upload-file/:id', component: UploadComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoder/encoder-new-detail/:id', component: EncoderInnerViewComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoder/encoder-log/:id', component: EncoderLogComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoder/encoder-log-for-project/:id', component: EncoderLogComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'encoders-version', component: EncodersVersionComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'versions', component: VersionsComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'upload-files', component: UploadFilesComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'opted-out-domains', component: OptedOutDomainsComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'copy-marks', component: CopyMarksComponent, canActivate: [FeatureEnabledGuard] },
          { path: 'mandatory-client-data', component: MandatoryClientDataComponent, canActivate: [FeatureToggleGuard], data: { featureName: 'MandatoryClientData' }},
          { path: 'tani-projects', component: TaniDashboardProjectsComponent, canActivate: [FeatureToggleGuard], data: { featureName: 'TaniDashboard' }},
          { path: 'tani-sessions', component: TaniDashboardSessionsComponent, canActivate: [FeatureToggleGuard], data: { featureName: 'TaniDashboard' }}
        ]},
        { path: '', canActivate: [PurgingToolGuard], children: [
            { path: 'purge-client-exception', component: PurgingClientExceptionComponent, canActivate: [FeatureEnabledGuard] },
            { path: 'purge-project-exception', component: PurgingProjectExceptionComponent, canActivate: [FeatureEnabledGuard] },
            { path: 'purge-audit-log', component: PurgingAuditLogComponent, canActivate: [FeatureEnabledGuard] }
        ]},
      ]},
      { path: '**', canActivate: [UnknownRouteGuard], children: [] },
    ]
  },
];
