import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoadingSpinnerService } from '../../../util/loading-spinner/loading-spinner.service';
import { UploadService } from './upload.service';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.css']
})
export class UploadFileDialogComponent implements OnInit {
  public encrypt = false;
  public fileIsTooLargeMessage = '';
  public disableButton = true;
  public selectedType: string = 'software';
  public encoderTypes: {value: string, label: string}[] = [
    {value: 'software',label: 'Software encoders'},
    {value: 'hardware', label: 'Hardware encoders'}
  ];
  public uploadResponse: string|{} = { status: '', message: '', };
  private form: FormGroup;

  constructor(
    private formBuilder: FormBuilder, 
    private dialogRef: MatDialogRef<UploadFileDialogComponent>,
    public spinnerService: LoadingSpinnerService, 
    private uploadService: UploadService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      file: ''
    });
  }

  /**
   * @ngdoc method
   * @name filterTable
   * @methodOf UploadFileDialogComponent
   * @description Method to change selected type
   */
  filterTable(value): void {
    this.selectedType = value;
  }

  /**
   * @ngdoc method
   * @name cancel
   * @methodOf UploadFileDialogComponent
   * @description This method should cancel modal dialog and send 'Cancel' value if answer is 'Cancel'
   */
  cancel(): void {
    this.dialogRef.close(`${'Cancel'}`);
  }

  /**
   * @ngdoc method
   * @name onFileChange
   * @methodOf UploadFileDialogComponent
   * @description This method shud get te file from event and set it in form
   */
  onFileChange(event): void {
    this.fileIsTooLargeMessage = '';
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > 200 * 1024 * 1024 ) {
        this.fileIsTooLargeMessage = 'File is to large. File size must be less than 200MB.';
        this.form.get('file').setValue('');
        this.disableButton = true;
      } else {
        const file = event.target.files[0];
        this.form.get('file').setValue(file)
        this.disableButton = false;
      }
    }
  }

  /**
   * @ngdoc method
   * @name onSubmit
   * @methodOf UploadFileDialogComponent
   * @description This method should submit form data
   */
  onSubmit(): void {
    const formData = new FormData();
    const encrypt = this.selectedType === 'hardware' ? null : this.encrypt;
    
    this.disableButton = true;
    this.spinnerService.spinnerSubject.next(true);
    formData.append('file', this.form.get('file').value);

    this.uploadService.upload(formData, encrypt, this.selectedType).subscribe(
      (res) => {
        this.uploadResponse = res;
        if (res && res.file && res.id)
          this.dialogRef.close(`${'Upload'}`);
      },
      (err) => {
        console.error(err);
        this.dialogRef.close(`${'Error'}`);
      }
    );
  }

  /**
   * @ngdoc method
   * @name getFileName
   * @methodOf UploadFileDialogComponent
   * @description Returns file name from form control file
   */
  getFileName(): string {
    return this.form.get('file').value.name;
  }

  /**
   * @ngdoc method
   * @name getFile
   * @methodOf UploadFileDialogComponent
   * @description Returns file from form control file
   */
  getFile(): File {
    return this.form.get('file').value;
  }
}
