import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, MatPaginator } from '@angular/material';
import { TimeUtil } from '../../util/time-util';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';
import { ProjectService } from '../../services/project.service';
import { LoginService } from '../../services/login.service';
import { AppConfigService } from '../../services/app-config.service';
import { PaginationService } from '../../services/pagination.service';
import { ProjectDataSource } from '../../models/project-data-source';

@Component({
  selector: 'app-project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./project-search.component.css']
})
export class ProjectSearchComponent implements OnInit {
  @Input() dataSourceInput: ProjectDataSource;
  @Input() sortInput: MatSort;
  @Input() paginatorInput: MatPaginator;
  @Input() projectTypeFlagInput: number;
  @Input() defaultHeaderInput: ElementRef;
  @Output() dataSourceOutput = new EventEmitter<any>();

  public dateFrom = new FormControl(new Date());
  public dateTo = new FormControl(new Date());
  public keyWord = new FormControl('', []);
  private searchKeyword: string;
  private dateFromCache: Date;
  private dateToCache: Date;
  public dateValidationMessage = '';
  public isSearchButtonDisabled = true;
  public isFilter = true;
  private encoderStatus;

  constructor(
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private timeUtil: TimeUtil,
    public spinnerService: LoadingSpinnerService,
    private projectService: ProjectService,
    private paginationService: PaginationService
  ) { }

  ngOnInit() {
    this.onFilterInputChange();
    this.setSearchParamsDependingOnProjectTypeFlagInput();
  }

  /**
   * @ngdoc method
   * @name setSearchParamsOnView
   * @methodOf ProjectSearchComponent
   * @description This method should set search params for showing on HTML page after switching page
   */
  private setSearchParamsOnView(params) {
    this.dateFrom = params ? new FormControl(new Date(params.dateFrom)) : new FormControl(new Date());
    this.dateTo = params ? new FormControl(new Date(params.dateTo)) : new FormControl(new Date());
    this.keyWord = params ? new FormControl(params.keyWord, []) : new FormControl('', []);
    this.searchKeyword = this.keyWord.value;
    this.dateFromCache = this.dateFrom.value;
    this.dateToCache = this.dateTo.value;
    const encoderStatus = params && params.status || 'ALL';
    if (encoderStatus !== this.encoderStatus) {
      this.encoderStatus = encoderStatus;
      this.projectService.projectsEncoderStatus.next(this.encoderStatus);
    }
  }

  /**
   * @ngdoc method
   * @name search
   * @methodOf ProjectSearchComponent
   * @description This method should search projects by input parameters on "Search" button click
   */
  public search() {
    this.onFilterInputChange();
    if (!this.isSearchButtonDisabled) {
      if (this.timeUtil.isDateToBeforeDateFrom(this.dateTo.value, this.dateFrom.value)) {
        this.dateValidationMessage = 'Invalid date period. Date to should be after Date from.';
      } else {
        this.isFilter = true;
        this.dateValidationMessage = '';
        this.spinnerService.spinnerSubject.next(true);
        setTimeout(() => {
          const pageNo = 0;
          this.spinnerService.spinnerSubject.next(false);
          this.getDataSource(this.dateFrom.value, this.dateTo.value, this.searchKeyword, pageNo, this.encoderStatus);
        }, 2000);
      }
    }
  }

  /**
   * @ngdoc method
   * @name clearFilter
   * @methodOf ProjectSearchComponent
   * @description This method should clear filter and set picker on current date on "Clear filter" button click
   */
  public clearFilter() {
    const { sort: defaultSort, direction: defaultDirection } = this.defaultHeaderInput.nativeElement.dataset;
    const { active, direction } = this.sortInput;
    const dontResetSort = active === defaultSort && direction === defaultDirection;
    const pageNo = 0;

    this.isFilter = true;
    this.dateValidationMessage = '';
    this.keyWord = new FormControl('', []);
    this.dateFrom = new FormControl(new Date());
    this.dateTo = new FormControl(new Date());
    this.searchKeyword = '';
    this.dateFromCache = this.dateFrom.value;
    this.dateToCache = this.dateTo.value;

    if (!dontResetSort) {
      this.dataSourceInput.changePage(this.projectTypeFlagInput, pageNo);
      this.prepareAndSetSearchParams(this.dateFrom.value, this.dateTo.value, this.searchKeyword, this.encoderStatus);
      this.dataSourceInput.changeFilters(this.searchKeyword, this.dateFrom.value, this.dateTo.value, this.encoderStatus);
      this.defaultHeaderInput.nativeElement.click();
    } else {
      this.getDataSource(this.dateFromCache, this.dateToCache, this.searchKeyword, pageNo, this.encoderStatus);
    }
  }

  /**
   * @ngdoc method
   * @name onFilterInputChange
   * @methodOf ProjectSearchComponent
   * @description This method should set isSearchButtonDisabled parameter on any filter input change
   */
  public onFilterInputChange(): boolean {
    if (!this.checkIsSearchButtonDisabled()) {
      this.searchKeyword = this.keyWord.value;
      this.dateFromCache = this.dateFrom.value;
      this.dateToCache = this.dateTo.value;
      return true;
    } else {
      return false;
    }
  }

  public checkIsSearchButtonDisabled(): boolean {
    if (this.keyWord.value !== '') {
      this.isSearchButtonDisabled =
        (this.dateTo.value === null && this.dateFrom.value !== null) || (this.dateTo.value !== null && this.dateFrom.value == null);
    } else if (this.dateTo.value !== null && this.dateFrom.value !== null) {
      this.isSearchButtonDisabled = !this.timeUtil.isYearValid(this.dateTo.value) || !this.timeUtil.isYearValid(this.dateFrom.value);
    } else {
      this.isSearchButtonDisabled = true;
    }
    return this.isSearchButtonDisabled;
  }

  /**
   * @ngdoc method
   * @name refreshList
   * @methodOf ProjectSearchComponent
   * @description This method should refresh list on Refresh button click
   */
  public refreshList() {
    this.isFilter = false;
    this.getDataSource(this.dateFromCache, this.dateToCache, this.searchKeyword, this.paginatorInput.pageIndex, this.encoderStatus);
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf ProjectSearchComponent
   * @description This method should set dataSource
   */
  public getDataSource(dateFromParam?, dateToParam?, keyWord?, page?: number, encoderStatus?: string) {
    this.dataSourceInput.fetchFreshData(keyWord, dateFromParam, dateToParam, page, this.projectTypeFlagInput, encoderStatus);
    this.dataSourceOutput.emit(this.dataSourceInput);
  }

  /**
   * @ngdoc method
   * @name prepareAndSetSearchParams
   * @methodOf ProjectSearchComponent
   * @description This method should prepare and set search params
   */
  private prepareAndSetSearchParams(dateFrom, dateTo, keyWord, status) {
    const searchParams = {
      dateFrom,
      dateTo,
      keyWord,
      status
    };
    this.projectService.projectsEncoderStatus.next(status);
    switch (this.projectTypeFlagInput) {
      case 1:
        this.projectService.liveProjectsSearchParams.next(searchParams);
        break;
      case 2:
        this.projectService.intervuProjectsSearchParams.next(searchParams);
        break;
      case 3:
        this.projectService.atRiskProjectsSearchParams.next(searchParams);
        break;
      default:
        this.projectService.allProjectsSearchParams.next(searchParams);
        break;
    }
  }

  /**
   * @ngdoc method
   * @name setSearchParamsDependingOnProjectTypeFlagInput
   * @methodOf ProjectSearchComponent
   * @description This method should prepare and set search params depending on project type flag input
   */
  private setSearchParamsDependingOnProjectTypeFlagInput() {
    switch (this.projectTypeFlagInput) {
      case 1:
        this.projectService.liveProjectsSearchParams.subscribe(params => {
          this.setSearchParamsOnView(params);
        });
        break;
      case 2:
        this.projectService.intervuProjectsSearchParams.subscribe(params => {
          this.setSearchParamsOnView(params);
        });
        break;
      case 3:
        this.projectService.atRiskProjectsSearchParams.subscribe(params => {
          this.setSearchParamsOnView(params);
        });
        break;
      default:
        this.projectService.allProjectsSearchParams.subscribe(params => {
          this.setSearchParamsOnView(params);
        });
        break;
    }
  }

    /**
   * @ngdoc method
   * @name onChangeStatus
   * @methodOf EncodersSearchComponent
   * @description This method should change list of projects based on encoder status
   */
  public onChangeStatus(event): void {
    if (event.source.selected) {
      this.paginatorInput.pageIndex = 0;
      this[event.label] = event.value;
      this.getDataSource(this.dateFromCache, this.dateToCache, this.searchKeyword, this.paginatorInput.pageIndex, this.encoderStatus);
    }
  }

}
