import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Feature } from './feature';
import { FeatureToggle as FeatureToggle } from './feature-toggle';
import { AppConfigService } from './services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  public initialized = false;
  private toggles: Map<string, boolean> = new Map<string, boolean>();
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService
  ) {}

  public enabled(name: string): boolean {
    return this.toggles[name] === true;
  }

  public loadData() {
    return this.httpClient
      .get<Map<string, boolean>>(
        this.appConfig.getWalnutUrl('/FeatureToggles'),
        this.appConfig.getHeaders()
      )
      .pipe(
        tap((res) => {
          this.toggles = res;
          this.initialized = true;
        })
      );
  }
}
