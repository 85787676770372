import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EncoderService } from '../../services/encoder.service';

@Component({
  selector: 'app-encoder-dialog',
  templateUrl: './encoder-dialog.component.html',
  styleUrls: ['./encoder-dialog.component.css']
})

/**
 * @ngdoc component
 * @name encoder-dialog.component:EncoderNewDialogComponent
 * @description This component handles modal dialog for new encoder
 */
export class EncoderNewDialogComponent implements OnInit {

  form: FormGroup;
  public activateOrDeactivateInformation = '';

  constructor(public encoderService: EncoderService, private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<EncoderNewDialogComponent>) {
    encoderService.cancelButtonMessageOnEncoderModal.subscribe(message => {
      this.activateOrDeactivateInformation = message;
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      message: ''
    });
  }

  /**
   * @ngdoc method
   * @name submit
   * @methodOf EncoderNewDialogComponent
   * @description This method should submit modal dialog form and send 'OK' value if answer is 'OK'
   */
  submit(form) {
    this.dialogRef.close(`${'OK'}`);
  }

  /**
   * @ngdoc method
   * @name cancel
   * @methodOf EncoderNewDialogComponent
   * @description This method should cancel modal dialog and send 'Cancel' value if answer is 'Cancel'
   */
  cancel() {
    this.dialogRef.close(`${'Cancel'}`);
  }

}
