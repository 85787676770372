import { ProjectExceptionModel } from './models/project-exception-model';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'storageSyncState'
})
export class StorageSyncStatePipe implements PipeTransform {

  transform(value: ProjectExceptionModel): string {
    let result = value.storageSyncState;
    if (value.storageSyncState === 'Trashed') {
      result = `${result} ${moment(value.trashedAt).fromNow()}`;
    }
    return result;
  }

}
