import { HandleAnyHttpResponse } from './handle-any-http-response';
import { HandleAnyHttpError } from './handle-any-http-error';
import { Handle404 } from './handle-404';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { appRoutes } from './route-list';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
         MatFormFieldModule, MatInputModule, MatSortModule, MatTableModule, MatGridListModule, MatRadioModule,
         MatPaginatorModule, MatSelectModule, MatProgressSpinnerModule, MatSnackBarModule, MatCheckboxModule,
         MatTooltipModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule, MatAutocompleteModule,
         MatButtonToggleModule} from '@angular/material';
import { EncodersComponent } from './feature/encoders/encoders.component';
import { EncodersNewComponent } from './feature/encoders-new/encoders-new.component';
import { EncodersAtRiskComponent } from './feature/encoders-at-risk/encoders-at-risk.component';
import { LoginComponent } from './login/login.component';
import { ProjectService } from './services/project.service';
import { EncoderService } from './services/encoder.service';
import { LoadingSpinnerService } from './util/loading-spinner/loading-spinner.service';
import { ProjectInnerViewComponent } from './projects/project-inner-view/project-inner-view.component';
import { EncoderInnerViewComponent } from './feature/encoders/encoder-inner-view/encoder-inner-view.component';
import { AppConfigService } from './services/app-config.service';
import { LoginService } from './services/login.service';
import { LoadingSpinnerComponent } from './util/loading-spinner/loading-spinner.component';
import { TimezoneClockComponent } from './util/timezone-clock/timezone-clock.component';
import { TimeUtil } from './util/time-util';
import { CommonUtil } from './util/common-util';
import { EncoderParametersComponent } from './feature/encoder-parameters/encoder-parameters.component';
import { StreamService } from './services/stream.service';
import { LocationService } from './services/location.service';
import { PaginationService } from './services/pagination.service';
import { EncoderNewDialogComponent } from './feature/encoder-dialog/encoder-dialog.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProjectSearchComponent } from './projects/project-search/project-search.component';
import { EncodersSearchComponent } from './shared/encoders-search/encoders-search.component';
import { EncodersFaultyComponent } from './feature/encoders-faulty/encoders-faulty.component';
import { EncoderLogComponent } from './feature/encoders-faulty/encoder-log/encoder-log.component';
import { EncodersVersionComponent } from './feature/encoders-version/encoders-version.component';
import { SelectedVersionsDialogComponent } from './feature/encoders-version/selected-versions-dialog/selected-versions-dialog.component';
import { AgentUpdateService } from './services/agent-update.service';
import { VersionsComponent } from './feature/encoders-version/versions/versions.component';
import { UploadFilesComponent } from './feature/encoders-version/upload-files/upload-files.component';
import { UploadFileDialogComponent } from './feature/encoders-version/upload-file-dialog/upload-file-dialog.component';
import { AutofocusModule } from 'angular-autofocus-fix';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActivateEncoderDialogComponent } from './feature/encoders/encoder-inner-view/activate-encoder-dialog/activate-encoder-dialog.component';
import { DeleteModalComponent } from './feature/delete-modal/delete-modal.component';
import { UploadComponent } from './feature/encoders/upload/upload.component';
import { OverriddenTimesModalComponent } from './projects/overridden-times-modal/overridden-times-modal.component';
import { ColumnVisibilityToolbarComponent } from './projects/column-visibility-toolbar/column-visibility-toolbar.component';
import { SimpleInformationModalComponent } from './feature/simple-information-modal/simple-information-modal.component';
import { SortingUtil } from './util/sorting-util';
import { ProjectsComponent } from './projects/projects.component';
import { DropdownFilterComponent } from './shared/dropdown-filter/dropdown-filter.component';
import { PurgingClientExceptionComponent } from './purging/components/purging-client-exception/purging-client-exception.component';
import { ExpirationValuePipe } from './purging/pipes/expiration-value-pipe';
import { LogoComponent } from './feature/logo/logo.component';
import { PurgingClientDataDialogComponent } from './purging/components/purging-client-data-dialog/purging-client-data-dialog.component';
import { PurgingDialogConfirmationComponent } from './purging/components/purging-dialog-confirmation/purging-dialog-confirmation.component';
import { ComboBoxComponent } from './shared/combo-box/component/combo-box.component';
import { PurgingProjectExceptionComponent } from './purging/components/purging-project-exception/purging-project-exception.component';
import { PurgingProjectDataDialogComponent } from './purging/components/purging-project-data-dialog/purging-project-data-dialog.component';
import { CustomMatPaginatorComponent } from './feature/custom-mat-paginator/custom-mat-paginator.component';
import { ClientExpirationValuePipe } from './purging/pipes/client-expiration-value.pipe';
import { ProjectExpirationValuePipe } from './purging/pipes/project-expiration-value-pipe';
import { PurgingAuditLogComponent } from './purging/components/purging-audit-log/purging-audit-log.component';
import { AuditLogEventPipe } from './purging/pipes/audit-log-event-pipe';
import { DatetimeFormatPipe } from './purging/pipes/datetime-format-pipe';
import { ClientIdValuePipe } from './purging/pipes/client-id-value.pipe';
import { FalsyPipe } from './falsy.pipe';
import { StorageSyncStatePipe } from './storage-sync-state.pipe';
import { AddAccessTokenToEveryHttpRequest } from './add-access-token-to-every-http-request';
import { Handle403 } from './handle-403';
import { Handle401 } from './handle-401';
import { EncoderRootComponent } from './root/encoder-root.component';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { DefaultComponent } from './default/default.component';
import { UnknownComponent } from './unknown/unknown.component';
import { Handle409 } from './handle-409';
import { OptedOutDomainsComponent } from './opted-out-domains/opted-out-domains.component';
import { OptedOutDomainsService } from './services/opted-out-domains.service';
import { CopyMarksComponent } from './projects/copy-marks/copy-marks.component';
import { CopyMarksService } from './services/copy-marks.service';
import { MandatoryClientDataComponent } from './feature/mandatory-client-data/mandatory-client-data.component';
import { ClientService } from './services/client-service';
import { MandatoryClientDataService } from './services/mandatory-client-data-service';
import { MandatoryClientDataDetailsComponent } from './feature/mandatory-client-data/mandatory-client-data-dialog/mandatory-client-data-dialog.component';
import { MandatoryClientDataDialogConfirmationComponent } from './feature/mandatory-client-data/mandatory-client-data-dialog-confirmation/mandatory-client-data-dialog-confirmation.component';
import { TaniDashboardProjectService } from './services/tani-dashboard-project.service';
import { TaniDashboardProjectsComponent } from './feature/tani-dashboard/tani-dashboard-projects/tani-dashboard-projects.component';
import { TaniPersonsTableCellComponent } from './feature/tani-dashboard/tani-persons-table-cell/tani-persons-table-cell.component';
import { TaniRespondentsTableCellComponent } from './feature/tani-dashboard/tani-respondents-table-cell/tani-respondents-table-cell.component';
import { TaniDashboardSessionService } from './services/tani-dashboard-session.service';
import { TaniDashboardSessionsComponent } from './feature/tani-dashboard/tani-dashboard-sessions/tani-dashboard-sessions.component';
import { FeatureService } from './feature.service';
import { LoggedInComponent } from './logged-in/logged-in.component';

@NgModule({
  declarations: [
    AppComponent,
    EncoderRootComponent,
    ProjectsComponent,
    EncodersComponent,
    EncodersNewComponent,
    EncodersAtRiskComponent,
    LoginComponent,
    ProjectInnerViewComponent,
    EncoderInnerViewComponent,
    LoadingSpinnerComponent,
    TimezoneClockComponent,
    EncoderParametersComponent,
    EncoderNewDialogComponent,
    PurgingClientDataDialogComponent,
    ProjectSearchComponent,
    EncodersSearchComponent,
    EncodersFaultyComponent,
    EncoderLogComponent,
    EncodersVersionComponent,
    SelectedVersionsDialogComponent,
    VersionsComponent,
    UploadFilesComponent,
    UploadFileDialogComponent,
    ActivateEncoderDialogComponent,
    DeleteModalComponent,
    UploadComponent,
    OverriddenTimesModalComponent,
    ColumnVisibilityToolbarComponent,
    SimpleInformationModalComponent,
    DropdownFilterComponent,
    PurgingClientExceptionComponent,
    ExpirationValuePipe,
    LogoComponent,
    PurgingDialogConfirmationComponent,
    ComboBoxComponent,
    PurgingProjectExceptionComponent,
    PurgingProjectDataDialogComponent,
    CustomMatPaginatorComponent,
    ClientExpirationValuePipe,
    PurgingAuditLogComponent,
    OptedOutDomainsComponent,
    AuditLogEventPipe,
    DatetimeFormatPipe,
    ClientIdValuePipe,
    ProjectExpirationValuePipe,
    FalsyPipe,
    StorageSyncStatePipe,
    NoPermissionsComponent,
    DefaultComponent,
    UnknownComponent,
    CopyMarksComponent,
    MandatoryClientDataComponent,
    MandatoryClientDataDetailsComponent,
    MandatoryClientDataDialogConfirmationComponent,
    TaniDashboardProjectsComponent,
    TaniPersonsTableCellComponent,
    TaniDashboardSessionsComponent,
    TaniRespondentsTableCellComponent,
    LoggedInComponent,
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatGridListModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    AutofocusModule,
    MatProgressBarModule
  ],

  providers: [
    ProjectService,
    EncoderService,
    AppConfigService,
    LocationService,
    AgentUpdateService,
    LoadingSpinnerService,
    TimeUtil,
    StreamService,
    LoginService,
    CommonUtil,
    PaginationService,
    SortingUtil,
    ExpirationValuePipe,
    ClientExpirationValuePipe,
    OptedOutDomainsService,
    CopyMarksService,
    ClientService,
    MandatoryClientDataService,
    TaniDashboardProjectService,
    TaniDashboardSessionService,
    FeatureService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleAnyHttpError,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleAnyHttpResponse,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Handle401,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Handle403,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Handle404,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Handle409,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAccessTokenToEveryHttpRequest,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [
    EncoderNewDialogComponent,
    SelectedVersionsDialogComponent,
    UploadFileDialogComponent,
    ActivateEncoderDialogComponent,
    DeleteModalComponent,
    OverriddenTimesModalComponent,
    SimpleInformationModalComponent,
    PurgingClientDataDialogComponent,
    PurgingDialogConfirmationComponent,
    PurgingProjectDataDialogComponent,
    MandatoryClientDataDetailsComponent,
    MandatoryClientDataDialogConfirmationComponent,
  ]

})
export class AppModule { }

