import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LoadingSpinnerService } from '../util/loading-spinner/loading-spinner.service';
import { LoginService } from '../services/login.service';
import { AppConfigService } from '../services/app-config.service';
import { AgentUpdateService } from '../services/agent-update.service';
import { SortingUtil } from '../util/sorting-util';

export class UploadFilesDataSource implements DataSource<any> {
    private dataSessionSubject = new BehaviorSubject<string[]>([]);
    private sortField: string;
    private subs: { [key: string]: Subscription } = {};

    constructor(private appConfig: AppConfigService,
                private loginService: LoginService,
                private agentUpdateService: AgentUpdateService,
                private spinnerService: LoadingSpinnerService,
                private sort: MatSort,
                private paginator: MatPaginator) {

      this.paginator.length = 100;
      this.paginator.pageSize = 100;
      this.paginator.pageIndex = 0;

      // Subscription for sort event
      this.sort.sortChange.observers = [];
      this.subs['sortSubscription'] = this.sort.sortChange
        .asObservable()
        .subscribe(result => {
          if (result) {
            this.sortField = SortingUtil.getSortingMaping()['uploadFiles'][result.active];
            this.getResults(this.sortField, result.direction);
          }
        });

      // Get total count
      this.subs['filesTotalCount'] = this.agentUpdateService.filesTotalCount.subscribe(totalCount => {
        this.paginator.length = totalCount;
      });

      // Subscription for page event
      this.subs['paginatorSubscription'] = this.paginator.page.asObservable().subscribe(res => {
        this.getResults(this.sortField, this.sort.direction);
      });
    }

    connect(): Observable<string[]> {
      if (!this.sortField) {
        this.sortField = SortingUtil.getSortingMaping()['uploadFiles'][this.sort.active];
      }

      this.getResults(this.sortField, this.sort.direction);
      return this.dataSessionSubject.asObservable();
    }
   /**
   * @ngdoc method
   * @name getResults
   * @methodOf VersionsDataSource
   * @description This method should get results from server.
   */
    public getResults(sortField, order) {
      this.spinnerService.spinnerSubject.next(true);
      this.agentUpdateService.getFiles(sortField, order).subscribe(response => {
        this.handleResponse(response);
      });
    }

    disconnect() {}

    public reloadData(): void {
      this.getResults(this.sortField, this.sort.direction);
    }

  /**
   * @ngdoc method
   * @name handleResponse
   * @methodOf VersionsDataSource
   * @description This method should handle response from server
   */
  private handleResponse(response) {
    for (let i = 0; i < response.length; i++) {
      response[i].editArgumentLine = false;
      response[i].newArgumentLine = response[i].arguments;
    }

    this.dataSessionSubject.next(response);
    this.spinnerService.spinnerSubject.next(false);
  }

  public unsubscribeAll(): void {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
  }
}
