import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationStart } from '@angular/router';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs/Rx';
import { EncoderService } from '../../../services/encoder.service';
import { StreamService } from '../../../services/stream.service';
import { LocationService } from '../../../services/location.service';
import { LoginService } from '../../../services/login.service';
import { AppConfigService } from '../../../services/app-config.service';
import { LoadingSpinnerService } from '../../../util/loading-spinner/loading-spinner.service';
import { CommonUtil } from '../../../util/common-util';
import { TimeUtil } from '../../../util/time-util';
import { Encoder } from '../../../models/encoder';
import { DefaultParams, LocationDefaultParams } from '../../../models/default-params';
import { AllParams, LocationAllParams } from '../../../models/all-params';
import { EncoderNewDialogComponent } from './../../encoder-dialog/encoder-dialog.component';
import { ActivateEncoderDialogComponent } from './activate-encoder-dialog/activate-encoder-dialog.component';
import { StreamDestinationData } from '../../../models/stream-destination-data';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs';
import 'rxjs/add/observable/fromEvent';

import * as deepEqual from 'deep-equal';

declare var jwplayer: any;
import '../../../util/jwplayer-8.25.1/jwplayer.js';

@Component({
  selector: 'app-encoder-inner-view',
  templateUrl: './encoder-inner-view.component.html',
  styleUrls: ['./encoder-inner-view.component.scss']
})

/**
 * @ngdoc component
 * @name encoder-inner-view.component:EncoderInnerViewComponent
 * @description This component shows inner view for Encoders, New Encoders and Encoders at Risk
 */
export class EncoderInnerViewComponent implements OnInit {

  @ViewChild('siteInput') siteInput: ElementRef;

  LIVE_PROJECT_TYPE = 1;
  INTER_VU_PROJECT_TYPE = 2;
  PORTABLE_ENCODER_TYPE = 1;
  PERMANENT_ENCODER_TYPE = 2;

  public id: number;
  public encoder: Encoder = new Encoder();
  // This should set part of current route and return 'encoder-detail' or 'encoder-new-detail' from url
  public currentRoute = this.router.url.split('/')[2];
  public selectEncoderProjectTypeOptions = [
    {id: 1, value: this.LIVE_PROJECT_TYPE, name: 'Live'},
    {id: 2, value: this.INTER_VU_PROJECT_TYPE, name: 'InterVu'}
  ];
  public selectEncoderPortabilityOptions = [
    {id: 1, value: this.PORTABLE_ENCODER_TYPE, name: 'Portable'},
    {id: 2, value: this.PERMANENT_ENCODER_TYPE, name: 'Permanent'}
  ];
  public encoderNameValidationMessage = '';
  public isSaveAndApprovedButtonDisabled = true;
  public showThumbnail = true;
  public destinationServerOptions;
  public regionOptionsList = [];
  public siteOptionsList = [];
  public roomOptionsList = [];
  public siteInputValue: string;
  public showSiteInputFiled = false;
  public showRoomInputField = false;
  public emptySiteListMessage = '';
  public selectDestinationServerMessage = false;
  public encoderCopy: Encoder;
  public saveAndActivate = false;
  public saveAndDeactivate = false;
  private alphanumericRegex = /^([a-zA-Z0-9 _-]+)$/;
  private firstUrlPlayed = false;

  newEncoderDialogRef: MatDialogRef<EncoderNewDialogComponent>;
  activateEncoderDialogRef: MatDialogRef<ActivateEncoderDialogComponent>;

  constructor(private encoderService: EncoderService, public route: ActivatedRoute, private router: Router, public dialog: MatDialog,
              public spinnerService: LoadingSpinnerService, private snackBar: MatSnackBar, private streamService: StreamService,
              private commonUtil: CommonUtil, private timeUtil: TimeUtil, private locationService: LocationService,
              private appConfig: AppConfigService, private loginService: LoginService) {
    this.encoder.defaultParams = new DefaultParams();
    this.encoder.defaultParams.locationDefaultParams = new LocationDefaultParams();
    this.encoder.allParams = new AllParams();
    this.encoder.allParams.locationAllParams = new LocationAllParams();
  }

  ngOnInit() {
    this.spinnerService.spinnerSubject.next(true);
    this.encoder.defaultParams.locationDefaultParams = new LocationDefaultParams();
    // This part of the code should subscribe to route and get id from params
    this.route.params.subscribe(params => {
      this.id = params['id'];
      // This part of the code should subscribe to encoderService and get encoder by id
      this.encoderService.getOneEncoder(this.id).subscribe(encoder => {
        this.handleGetOneEncoderResponse(encoder);
      });
    });

    this.siteOptionsList = [];

    Observable.fromEvent(this.siteInput.nativeElement, 'keyup')
      // get value
      .map((evt: any) => evt.target.value)
      // emit after 1s of silence
      .debounceTime(1000)
      // emit only if data changes since the last emit
      .distinctUntilChanged()
      // subscription
      .subscribe((text: string) => this.setSiteDropdown(text));
  }

  /**
   * @ngdoc method
   * @name initialiseSiteAndRoom
   * @methodOf EncoderInnerViewComponent
   * @description This method should initialise dropdowns and values for site and room
   */
  initialiseSiteAndRoom() {
    if (this.encoder.defaultParams.locationDefaultParams.siteName !== null) {
      this.showSiteInputFiled = true;
      const site = {
        siteId: this.encoder.defaultParams.locationDefaultParams.siteId,
        name: this.encoder.defaultParams.locationDefaultParams.siteName
      };
      this.siteOptionsList.push(site);
      this.siteInputValue = this.encoder.defaultParams.locationDefaultParams.siteName;
    }

    if (this.encoder.defaultParams.locationDefaultParams.roomName !== null) {
      this.showRoomInputField = true;
      this.onSiteChange(false);
    }
  }

  /**
   * @ngdoc method
   * @name setSiteDropdown
   * @methodOf EncoderInnerViewComponent
   * @description This method should set Site dropdown autocomplete after 2 seconds of typing
   */
  public setSiteDropdown(siteKeyWord) {
    if (siteKeyWord.length !== 0) {
      this.locationService.getSites(this.encoder.defaultParams.locationDefaultParams.regionId, siteKeyWord).subscribe(sites => {
        this.handleGetSitesResponse(sites, siteKeyWord);
      });
    } else {
      this.resetLocationParams();
      this.emptySiteListMessage = '';
    }
    this.setIsSaveAndApprovedButtonDisabled();
  }

  /**
   * @ngdoc method
   * @name resetLocationParams
   * @methodOf EncoderInnerViewComponent
   * @description This method should reset site and room location params
   */
  public resetLocationParams() {
    this.showRoomInputField = false;
    this.siteInputValue = null;
    this.encoder.defaultParams.locationDefaultParams.siteId = null;
    this.encoder.defaultParams.locationDefaultParams.roomId = null;
  }

  /**
   * @ngdoc method
   * @name activateEncoder
   * @methodOf EncoderInnerViewComponent
   * @description This method should activate encoder
   */
  public activateEncoder() {
    this.spinnerService.spinnerSubject.next(true);
     this.encoderService.activateEncoder(this.encoder.id).subscribe(res => {
       this.handleAfterActivateOrDeactivateEncoder('Encoder activated!', 1);
       this.encoderNameValidationMessage = '';
     });
  }

  /**
   * @ngdoc method
   * @name activateAndSaveEncoder
   * @methodOf EncoderInnerViewComponent
   * @description This method should activate encoder on "Activate" button click or show modal if params changed
   */
  public activateAndSaveEncoder() {
    this.spinnerService.spinnerSubject.next(true);
    this.encoderService.activationTypeMessageOnEncoderModal.next('Activate');

    this.activateEncoderDialogRef = this.dialog.open(ActivateEncoderDialogComponent, {
      hasBackdrop: false
    });

    this.activateEncoderDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'OK') {
          this.encoderService.cancelButtonMessageOnEncoderModal.next(
            'If you just want to activate the encoder, please click on Cancel button.'
          );
          if (this.isEncoderEqualToEncoderCopy()) {
            this.activateEncoder();
          } else {
            this.saveAndActivate = true;
            this.save();
          }
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });

  }

  /**
   * @ngdoc method
   * @name deactivateAndSaveEncoder
   * @methodOf EncoderInnerViewComponent
   * @description This method should deactivate encoder on "Deactivate" button click or show modal if params changed
   */
  public deactivateAndSaveEncoder() {
    this.spinnerService.spinnerSubject.next(true);
    this.encoderService.activationTypeMessageOnEncoderModal.next('Deactivate');

    this.activateEncoderDialogRef = this.dialog.open(ActivateEncoderDialogComponent, {
      hasBackdrop: false
    });

    this.activateEncoderDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'OK') {
          this.encoderService.cancelButtonMessageOnEncoderModal.next(
            'If you just want to deactivate the encoder, please click on Cancel button.'
          );
          if (this.isEncoderEqualToEncoderCopy()) {
            this.deactivateEncoder();
          } else {
            this.saveAndDeactivate = true;
            this.save();
          }
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });

  }

  /**
   * @ngdoc method
   * @name activateAndDeactivateHandling
   * @methodOf EncoderInnerViewComponent
   * @description This method should call activete or deactivate after save if it is necessary
   */
  public activateAndDeactivateHandling() {
    if (this.saveAndActivate) {
      this.activateEncoder();
      this.saveAndActivate = false;
    }
    if (this.saveAndDeactivate) {
      this.deactivateEncoder();
      this.saveAndDeactivate = false;
    }
  }

  /**
   * @ngdoc method
   * @name isEncoderEqualToEncoderCopy
   * @methodOf EncoderInnerViewComponent
   * @description This method should checked if encoder params changed
   */
  public isEncoderEqualToEncoderCopy() {
    return deepEqual(this.encoder.defaultParams, this.encoderCopy.defaultParams, '===') &&
      deepEqual(this.encoder.encoderProjectType, this.encoderCopy.encoderProjectType, '===') &&
      deepEqual(this.encoder.encoderType, this.encoderCopy.encoderType, '===') &&
      deepEqual(this.encoder.name, this.encoderCopy.name, '===');
  }

  /**
   * @ngdoc method
   * @name deactivateEncoder
   * @methodOf EncoderInnerViewComponent
   * @description This method should deactivate encoder
   */
  public deactivateEncoder() {
    this.spinnerService.spinnerSubject.next(true);
     this.encoderService.deactivateEncoder(this.encoder.id).subscribe(res => {
       this.handleAfterActivateOrDeactivateEncoder('Encoder deactivated!', 2);
       this.encoderNameValidationMessage = '';
     });
  }

  /**
   * @ngdoc method
   * @name prepareEncodersParamsForSaveOrApprove
   * @methodOf EncoderInnerViewComponent
   * @description This method should prepare some encoder params before saving or approving
   */
  private prepareEncodersParamsForSaveOrApprove() {
    delete this.encoder['allParams'];
    this.setDefaultSiteRoomAndEncoderTypeIfProjectTypeIsInterVu();
    if (this.encoder.encoderType === this.PORTABLE_ENCODER_TYPE) {
      this.encoder.defaultParams.locationDefaultParams.roomId = 0;
    }
    this.encoderNameValidationMessage = '';
  }

  /**
   * @ngdoc method
   * @name prepareSaveOrApproveModaldialog
   * @methodOf EncoderInnerViewComponent
   * @description This method should prepare modal dialog for save or approve action
   */
  private prepareSaveOrApproveModalDialog() {
    this.spinnerService.spinnerSubject.next(true);
    this.newEncoderDialogRef = this.dialog.open(EncoderNewDialogComponent, {
      hasBackdrop: false
    });
  }

  /**
   * @ngdoc method
   * @name handleEncoderNameContainsSpecialCharacters
   * @methodOf EncoderInnerViewComponent
   * @description This method should set validation message, remove cancellation message
   * and avoid save and (de)activate actions
   */
  public handleEncoderNameContainsSpecialCharacters() {
    this.encoderNameValidationMessage = 'Please avoid special characters from encoder name.';
    this.saveAndActivate = false;
    this.saveAndDeactivate = false;
    this.encoderService.cancelButtonMessageOnEncoderModal.next('');
    this.spinnerService.spinnerSubject.next(false);
  }

  /**
   * @ngdoc method
   * @name approveEncoder
   * @methodOf EncoderInnerViewComponent
   * @description This method should approve new encoder, save default params
   * and switch user to list of new encoders on "Approve" button click
   */
  public approveEncoder(): void {
    if (!this.encoder.name.match(this.alphanumericRegex)) {
      this.handleEncoderNameContainsSpecialCharacters();
    } else if (this.encoder.name.length > 100) {
      this.encoderNameValidationMessage = 'Encoder name should not exceed 100 characters.';
      this.spinnerService.spinnerSubject.next(false);
    } else {

      this.prepareSaveOrApproveModalDialog();

      this.newEncoderDialogRef
        .afterClosed()
        .subscribe(message => {
          if (message === 'OK') {
            this.prepareEncodersParamsForSaveOrApprove();
            this.encoderService.approveEncoder(this.encoder).subscribe(res => {
              this.handleAfterApproveOrUpdateEncoder('Encoder approved!', '/encoders-new');
            });
          } else if (message === 'Cancel') {
            this.spinnerService.spinnerSubject.next(false);
          }
        });
    }
  }

  /**
   * @ngdoc method
   * @name setDefaultSiteRoomAndEncoderTypeIfProjectTypeIsInterVu
   * @methodOf EncoderInnerViewComponent
   * @description This method should set to 0 siteId and roomId, and portable for InterVu projects
   */
  public setDefaultSiteRoomAndEncoderTypeIfProjectTypeIsInterVu() {
    if (this.encoder.encoderProjectType === this.INTER_VU_PROJECT_TYPE) {
      this.encoder.encoderType = this.PORTABLE_ENCODER_TYPE;
      this.encoder.defaultParams.locationDefaultParams.siteId = 0;
      this.encoder.defaultParams.locationDefaultParams.roomId = 0;
    }
  }

  /**
   * @ngdoc method
   * @name save
   * @methodOf EncoderInnerViewComponent
   * @description This method should save/update encoder on "Save" button click
   */
  public save() {
    if (!this.encoder.name.match(this.alphanumericRegex)) {
      this.handleEncoderNameContainsSpecialCharacters();
    } else if (this.encoder.name.length > 100) {
      this.encoderNameValidationMessage = 'Encoder name should not exceed 100 characters.';
      this.spinnerService.spinnerSubject.next(false);
    } else {

      this.prepareSaveOrApproveModalDialog();

      this.newEncoderDialogRef
        .afterClosed()
        .subscribe(message => {
          if (message === 'OK') {
            this.prepareEncodersParamsForSaveOrApprove();
            this.encoderService.updateEncoder(this.encoder).subscribe(res => {
              this.handleAfterApproveOrUpdateEncoder('Encoder updated successfully!', '/encoders');
              this.activateAndDeactivateHandling();
            });
          } else if (message === 'Cancel') {
            this.activateAndDeactivateHandling();
            this.spinnerService.spinnerSubject.next(false);
          }
        });
    }
  }

  /**
   * @ngdoc method
   * @name preview
   * @methodOf EncoderInnerViewComponent
   * @description This method should send encoder to server, prepare test video data and get destination server link on button click
   */
  public preview() {
    this.firstUrlPlayed = false;
    if (!this.encoder.name.match(this.alphanumericRegex)) {
      this.handleEncoderNameContainsSpecialCharacters();
    } else if (this.encoder.name.length > 100) {
      this.encoderNameValidationMessage = 'Encoder name should not exceed 100 characters.';
      this.spinnerService.spinnerSubject.next(false);
    } else {

      this.encoderNameValidationMessage = '';
      document.getElementById('placeholder').style.display = 'none';

      this.setDefaultSiteRoomAndEncoderTypeIfProjectTypeIsInterVu();

      if (this.encoder.encoderType === this.PORTABLE_ENCODER_TYPE) {
        this.encoder.defaultParams.locationDefaultParams.roomId = 0;
        this.encoderCopy.defaultParams.locationDefaultParams.roomId = 0;
      }

      this.streamService.getStreamUrl(this.encoder).subscribe(destinationData => {
          this.spinnerService.spinnerSubject.next(false);
          this.jwPlayerSetup(destinationData);
          this.showThumbnail = false;
        }, error => {
          this.showThumbnail = false;
          this.setPlaceholder( '<img src=\"assets/PLACEHOLDER3.jpg\">', 'block', 'none');
        });
    }
  }

  /**
   * @ngdoc method
   * @name jwPlayerSetup
   * @methodOf EncoderInnerViewComponent
   * @description This method should setup jwplayer
   */
  public jwPlayerSetup(destinationData: StreamDestinationData) {

    const dateTo = this.timeUtil.toMilliseconds(destinationData.validTo);
    let durationOfVideoStream = dateTo - new Date().getTime();
    let expirationTime = null;
    let firstFrameLoaded = false;
    const eventArray = [];
    let timer;
    let endPlaceholderTimer;

    const jwplayerInstance = jwplayer('stream');

    jwplayerInstance.setup({
      file: destinationData.url,
      key: 'yjX4CrQ3Lvs3tQ85Q79VlAxHCUJy/pX0KRL15ouLqj6prqty',
      height: 435,
      width: 770,
      autostart: true
    });

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        jwplayerInstance.remove();
        clearInterval(timer);
        clearTimeout(endPlaceholderTimer);
      }
    });
    jwplayerInstance.play();

    this.setPlaceholder('<img src=\"assets/PLACEHOLDERSPINNER.gif\">', 'block', 'none');
    jwplayerInstance.on('error', (event) =>{
      var isForbiddenError = event.sourceError && event.sourceError.response && event.sourceError.response.code == 403;
      if (isForbiddenError && !this.firstUrlPlayed){
        this.firstUrlPlayed = true;
        jwplayerInstance.load({file: destinationData.oldUrl});
      }

      clearInterval(timer);

      expirationTime = dateTo - new Date().getTime();

      setTimeout(() => {
        jwplayerInstance.playlistNext();
      }, 5000);

      if (expirationTime < 0) {
        jwplayerInstance.remove();
        clearInterval(timer);
        this.setPlaceholder( '<img src=\"assets/PLACEHOLDER3.jpg\">', 'block', 'none');
      }
    });

    jwplayerInstance.on('all', function(event, param) {
      eventArray.push(param.type);

      if (param.type === 'providerFirstFrame' && !firstFrameLoaded) {

        firstFrameLoaded = true;

        document.getElementById('placeholder').style.display = 'none';
        document.getElementById('stream-wrapper').style.display = 'block';
        clearInterval(timer);

        timer = setInterval(() => {
          if (eventArray[eventArray.length - 1] === 'buffer' ||
              eventArray[eventArray.length - 1] === 'userActive' ||
              eventArray[eventArray.length - 1] === 'userInactive'
          ) {
            console.log('Stream is in BUFFER mode more than 30 seconds - refresh!');
            jwplayerInstance.playlistNext();
          }
        }, 30000);

        durationOfVideoStream = expirationTime !== null ? expirationTime : durationOfVideoStream;

        endPlaceholderTimer = setTimeout(() => {
          jwplayerInstance.remove();
          clearInterval(timer);
          document.getElementById('placeholder').innerHTML = '<img src=\"assets/PLACEHOLDERFINISHED.jpg\">';
          document.getElementById('placeholder').style.display = 'block';
          document.getElementById('stream-wrapper').style.display = 'none';
        }, durationOfVideoStream );

      }
    });
  }

  /**
   * @ngdoc method
   * @name setPlaceholder
   * @methodOf EncoderInnerViewComponent
   * @description This method should set initial placeholder
   */
  public setPlaceholder(image, placeholderDisplay, streamDisplay) {
    document.getElementById('placeholder').innerHTML = image;
    document.getElementById('placeholder').style.display = placeholderDisplay;
    document.getElementById('stream-wrapper').style.display = streamDisplay;
  }

  /**
   * @ngdoc method
   * @name detectParamsValueChange
   * @methodOf EncoderInnerViewComponent
   * @description This method should get all changed default params from EncoderParametersComponent
   */
  public detectParamsValueChange($event: DefaultParams) {
    this.encoder.defaultParams = $event;
  }

  /**
   * @ngdoc method
   * @name getEncoderStatusNameById
   * @methodOf EncoderInnerViewComponent
   * @description This method should return string value for encoder status name by encoder status id
   */
  public getEncoderStatusNameById(id) {
    let encoderStatusName: string;
    switch (id) {
      case 0:
        encoderStatusName = 'PENDING';
        break;
      case 1:
        encoderStatusName = 'APPROVED';
        break;
      case 2:
        encoderStatusName = 'DEACTIVATED';
        break;
    }
    return encoderStatusName;
  }

  /**
   * @ngdoc method
   * @name setZerosValuesToNullForEncoder
   * @methodOf EncoderInnerViewComponent
   * @description This method should set all params values to null if value is 0.
   * This is necessary because of validations and required directive. Zero value is valid, we need nulls.
   */
  public setZerosValuesToNullForEncoder(encoder) {
    this.encoder.encoderProjectType = encoder.encoderProjectType === 0 ? null : encoder.encoderProjectType;
    this.encoder.encoderType = encoder.encoderType === 0 ? null : encoder.encoderType;
    this.commonUtil.setZerosValuesToNull(this.encoder.defaultParams.inputDefaultParams);
    this.commonUtil.setZerosValuesToNull(this.encoder.defaultParams.outputDefaultParams);
    this.commonUtil.setZerosValuesToNull(this.encoder.defaultParams.locationDefaultParams);
  }

  /**
   * @ngdoc method
   * @name isSaveAndApprovedButtonDisabled
   * @methodOf EncoderInnerViewComponent
   * @description This method should set isSaveAndApprovedButtonDisabled to true if encoder contains properties with null values
   */
  public setIsSaveAndApprovedButtonDisabled() {
    if (this.encoder.encoderProjectType === this.LIVE_PROJECT_TYPE) {
      this.isSaveAndApprovedButtonDisabled =
        this.isNullEncoderRequiredField() ||
        this.encoder.encoderType === null ||
        this.encoder.encoderType === undefined ||
        (this.encoder.encoderType === this.PERMANENT_ENCODER_TYPE && this.encoder.defaultParams.locationDefaultParams.roomId === null) ||
        (this.encoder.encoderType === this.PERMANENT_ENCODER_TYPE && this.encoder.defaultParams.locationDefaultParams.roomId === undefined) ||
        (this.encoder.encoderType === this.PERMANENT_ENCODER_TYPE && this.encoder.defaultParams.locationDefaultParams.roomId === 0) ||
        this.encoder.defaultParams.locationDefaultParams.siteId === null ||
        this.encoder.defaultParams.locationDefaultParams.siteId === undefined ||
        this.encoder.defaultParams.locationDefaultParams.siteId === 0;
    } else if (this.encoder.encoderProjectType === this.INTER_VU_PROJECT_TYPE) {
      this.isSaveAndApprovedButtonDisabled = this.isNullEncoderRequiredField();
    }
  }

  /**
   * @ngdoc method
   * @name isNullEncoderRequiredField
   * @methodOf EncoderInnerViewComponent
   * @description This method should return true/false is some of encoder required fields (inter vu and live) is null
   */
  public isNullEncoderRequiredField() {
    return this.encoder.name === '' || this.encoder.name === null || this.encoder.encoderProjectType === null ||
      this.encoder.defaultParams.locationDefaultParams.regionId === null ||
      this.commonUtil.containsPropertiesWithNullValues(this.encoder.defaultParams.inputDefaultParams) ||
      this.commonUtil.containsPropertiesWithNullValues(this.encoder.defaultParams.outputDefaultParams);
  }

  /**
   * @ngdoc method
   * @name onRegionChange
   * @methodOf EncoderInnerViewComponent
   * @description This method should set destinationServerOptions depending on region and set destinationServerId to null
   */
  public onRegionChange() {
    if (this.encoder.defaultParams.locationDefaultParams.regionId) {
      this.selectDestinationServerMessage = true;
      this.showSiteInputFiled = true;
      this.siteInputValue = null;
      this.siteOptionsList = [];
      this.roomOptionsList = [];
      this.resetLocationParams();
      this.destinationServerOptions = this.encoder.allParams.locationAllParams.regions.find(
        region => region.id === this.encoder.defaultParams.locationDefaultParams.regionId).destinationServers;
      this.encoder.defaultParams.inputDefaultParams.destinationServerId = null;
    }
  }

  /**
   * @ngdoc method
   * @name onProjectTypeChange
   * @methodOf EncoderInnerViewComponent
   * @description This method should set site and room on null
   */
  public onProjectTypeChange() {

    if (this.encoder.encoderProjectType === this.LIVE_PROJECT_TYPE) {
      if (this.currentRoute === 'encoder-detail') {
        this.showSiteInputFiled = true;
      }
      this.encoder.encoderType = null;
    }

    if (!(this.encoder.encoderProjectType === this.LIVE_PROJECT_TYPE && this.encoder.defaultParams.locationDefaultParams.siteId !== null &&
         this.encoder.defaultParams.locationDefaultParams.roomId !== null && this.encoder.encoderType !== null)) {
      this.resetLocationParams();
      this.encoder.encoderType = null;
    }
  }

  /**
   * @ngdoc method
   * @name onPortabilityChange
   * @methodOf EncoderInnerViewComponent
   * @description This method should hide rooms dropdown if portability is Portable
   */
  public onPortabilityChange() {

    this.showRoomInputField = false;
    this.encoder.defaultParams.locationDefaultParams.roomId = null;

    if (this.encoder.encoderType === this.PORTABLE_ENCODER_TYPE) {
      this.showRoomInputField = false;
    } else {
      const site = this.siteOptionsList.find(siteOption => siteOption.name === this.siteInputValue);
      if (site !== undefined) {
        this.onSiteChange(false, site);
        this.showRoomInputField = true;
      }
    }
  }

  /**
   * @ngdoc method
   * @name onSiteChange
   * @methodOf EncoderInnerViewComponent
   * @description This method should set room input field and dropdown list
   */
  public onSiteChange(resetRoom, selectedSite?) {

    const site = selectedSite ? selectedSite : this.siteOptionsList.find(siteOption => siteOption.name === this.siteInputValue);

    if (site !== undefined) {
      this.encoder.defaultParams.locationDefaultParams.siteId = site.siteId;
      this.encoder.site = site.name;
      if (this.encoder.encoderType && this.encoder.encoderType !== this.PORTABLE_ENCODER_TYPE) {

        if (resetRoom) {
          this.encoder.defaultParams.locationDefaultParams.roomId = null;
        }
        this.showRoomInputField = true;
        this.locationService.getRooms(this.encoder.defaultParams.locationDefaultParams.siteId).subscribe(rooms => {
            this.roomOptionsList = rooms;
          });
      }
    } else {
      this.encoder.defaultParams.locationDefaultParams.roomId = null;
      this.roomOptionsList = [];
      this.showRoomInputField = false;
    }
  }

  /**
   * @ngdoc method
   * @name handleGetOneEncoderResponse
   * @methodOf EncoderInnerViewComponent
   * @description This method should handle response from server
   */
  private handleGetOneEncoderResponse(encoder) {
    this.encoder = encoder;
    this.regionOptionsList = encoder.allParams.locationAllParams.regions;
    this.initialiseSiteAndRoom();
    this.setZerosValuesToNullForEncoder(this.encoder);
    this.encoder.statusName = this.getEncoderStatusNameById(this.encoder.status);
    this.setDefaultSiteRoomAndEncoderTypeIfProjectTypeIsInterVu();

    const copy = JSON.stringify(this.encoder);
    this.encoderCopy = JSON.parse(copy);
    this.spinnerService.spinnerSubject.next(false);
  }

  /**
  * @ngdoc method
  * @name handleAfterApproveOrUpdateEncoder
  * @methodOf EncoderInnerViewComponent
  * @description This method should set params and run snackbar after approve or update encoder
  */
  private handleAfterApproveOrUpdateEncoder(message, route) {
    setTimeout(() => {
      this.snackBar.open(message, 'Ok', {duration: 2000});
      this.spinnerService.spinnerSubject.next(false);
      this.router.navigate([route]);
    }, 2000);
  }

  /**
   * @ngdoc method
   * @name handleAfterActivateOrDeactivateEncoder
   * @methodOf EncoderInnerViewComponent
   * @description This method should set params and run snackbar after activateEncoder
   */
  private handleAfterActivateOrDeactivateEncoder(message, status) {
    this.spinnerService.spinnerSubject.next(true);
    setTimeout(() => {
      this.encoder.status = status;
      this.encoder.statusName = this.getEncoderStatusNameById(this.encoder.status);
      this.spinnerService.spinnerSubject.next(false);
      this.snackBar.open(message, 'Ok', {duration: 2000});
      this.encoderService.cancelButtonMessageOnEncoderModal.next('');
    }, 2000);
  }


  /**
   * @ngdoc method
   * @name handleGetSitesResponse
   * @methodOf EncoderInnerViewComponent
   * @description This method should handle response from server
   */
  private handleGetSitesResponse(sites, siteKeyWord) {
    this.siteOptionsList = sites;
    this.emptySiteListMessage = this.siteOptionsList.length === 0 ? 'No results' : '';
    const site = this.siteOptionsList.find(siteOption => siteOption.name === siteKeyWord);
    this.encoder.defaultParams.locationDefaultParams.siteId = site === undefined ? null : site.siteId;
    if (site === undefined) {
      this.showRoomInputField = false;
      this.encoder.defaultParams.locationDefaultParams.siteId = null;
      this.encoder.defaultParams.locationDefaultParams.roomId = null;
      this.setIsSaveAndApprovedButtonDisabled();
    }
  }

  private goToUploadPage(encoder) {
    this.encoderService.setEncoderFileUploadData(encoder);
    this.router.navigate([`encoder/upload-file/${encoder.id}`]);
  }

}
