import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { EncoderService } from '../services/encoder.service';
import { LoadingSpinnerService } from '../util/loading-spinner/loading-spinner.service';
import { LoginService } from '../services/login.service';
import { AppConfigService } from '../services/app-config.service';
import { TimeUtil } from '../util/time-util';
import { LogMessage } from './encoder';
import { SortingUtil } from '../util/sorting-util';

export class EncoderLogDataSource implements DataSource<any> {
    private dataSessionSubject = new BehaviorSubject<LogMessage[]>([]);
    private sortField: string;
    private subs: { [key: string]: Subscription } = {};


    constructor(private appConfig: AppConfigService,
                private loginService: LoginService,
                private timeUtil: TimeUtil,
                private encoderService: EncoderService,
                private spinnerService: LoadingSpinnerService,
                private sort: MatSort,
                private paginator: MatPaginator,
                private currentRoute,
                private id) {

      this.paginator.length = 100;
      this.paginator.pageSize = 10;
      this.paginator.pageIndex = 0;

      // Subscription for sort event
      this.sort.sortChange.observers = [];
      this.subs['sortSubscription'] = this.sort.sortChange
        .asObservable()
        .subscribe(result => {
          if (result) {
            const sortBy = this.currentRoute === 'encoder-log-for-project' ? 'projects' : 'encoders';
            this.sortField = SortingUtil.getSortingMaping()[sortBy][result.active];
            this.getResults(this.id, this.currentRoute, this.paginator.pageIndex, this.sortField, result.direction);
          }
        });

      // Get total count
      this.subs['logMessagesTotalCount'] = this.encoderService.logMessagesTotalCount.subscribe(totalCount => {
        this.paginator.length = totalCount;
      });

      // Subscription for page event
      this.subs['paginatorSubscription'] = this.paginator.page.asObservable().subscribe(res => {
        this.getResults(this.id, this.currentRoute, this.paginator.pageIndex, this.sortField, this.sort.direction);
      });
    }

    connect(): Observable<LogMessage[]> {
      if (!this.sortField) {
        const sortBy = this.currentRoute === 'encoder-log-for-project' ? 'projects' : 'encoders';
        this.sortField = SortingUtil.getSortingMaping()[sortBy][this.sort.active];
      }

      this.getResults(this.id, this.currentRoute, this.paginator.pageIndex, this.sortField, this.sort.direction);
      return this.dataSessionSubject.asObservable();
    }

   /**
   * @ngdoc method
   * @name getResults
   * @methodOf EncoderLogDataSource
   * @description This method should get results from server.
   */
    public getResults(id, currentRoute, pageIndex, sortField, order) {
      this.spinnerService.spinnerSubject.next(true);
      this.encoderService.getEncoderLog(id, currentRoute, pageIndex, sortField, order).subscribe(response => {
          this.handleResponse(response);
      });
    }

    disconnect() {}


  /**
   * @ngdoc method
   * @name handleResponse
   * @methodOf EncoderLogDataSource
   * @description This method should handle response from server
   */
  private handleResponse(response) {
    for (let i = 0; i < response.messages.length; i++) {
      response.messages[i].sentAtEncoderTime = this.timeUtil.getDateTime(response.messages[i].sentAtEncoderTime);
      response.messages[i].receivedAtServerTime = this.timeUtil.getDateTime(response.messages[i].receivedAtServerTime);
    }

    this.dataSessionSubject.next(response.messages);
    this.spinnerService.spinnerSubject.next(false);
  }

  public unsubscribeAll(): void {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
  }
}
