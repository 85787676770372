import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RouteType } from './route-type.enum';
import { RouteService } from './route.service';

export class RouteTypeGuard implements CanActivate {
  constructor(private routeService: RouteService, private type: RouteType) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.routeService.Type = this.type;
    return true;
  }
}
