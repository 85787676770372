import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AppConfigService {

  public appUrl = '/api';

  public getHeaders(bodyParams?) {
     const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8' });
     return { headers: headers, params: bodyParams };
  }

  public getUrl(url: string) {
    return this.appUrl + url;
  }

  public getWalnutUrl(url: string): string {
    return '/purgingapi' + url;
  }

}
