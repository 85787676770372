import { Component, OnInit, OnDestroy } from '@angular/core';
import { Project } from '../../models/project';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ProjectService } from '../../services/project.service';
import { EncoderService } from '../../services/encoder.service';
import { LoginService } from '../../services/login.service';
import { AppConfigService } from '../../services/app-config.service';
import { TimezoneService } from '../../services/timezone.service';
import { Encoder } from '../../models/encoder';
import { EncoderSelect } from '../../models/encoder';
import { DefaultParams } from '../../models/default-params';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';
import { TimeUtil } from '../../util/time-util';
import { CommonUtil } from '../../util/common-util';
import { EncoderNewDialogComponent } from '../../feature/encoder-dialog/encoder-dialog.component';
import { OverriddenTimesModalComponent } from '../overridden-times-modal/overridden-times-modal.component';
import { SimpleInformationModalComponent } from '../../feature/simple-information-modal/simple-information-modal.component';
import { MatCheckboxChange } from '@angular/material';

import * as deepEqual from 'deep-equal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-project-inner-view',
  templateUrl: './project-inner-view.component.html',
  styleUrls: ['./project-inner-view.component.css']
})

/**
 * @ngdoc component
 * @name project-inner-view.component:ProjectInnerViewComponent
 * @description This component shows inner view for Projects
 */
export class ProjectInnerViewComponent implements OnInit, OnDestroy {
  public encoderList: EncoderSelect[] = [];
  public project: Project = new Project();
  public encoder: Encoder;
  public encoderCopy: Encoder;
  public selectedEncoder: string;
  public isSaveButtonDisabled = true;
  public currentRoute = this.router.url.split('/')[1];
  private twoCHDVRNTOptionId = 3;
  private stereoAudioChannelId = 2;
  private alive = true;
  private subs: { [key: string]: Subscription } = {};

  public updateAndStartRecording = false;

  public startVisibility: boolean;
  public stopVisibility: boolean;

  public startTimeShow;
  public actualStartTimeShow;
  public endTimeShow;
  public actualEndTimeShow;

  newEncoderDialogRef: MatDialogRef<EncoderNewDialogComponent>;
  overriddenTimesDialogRef: MatDialogRef<OverriddenTimesModalComponent>;
  simpleInformationModalDialogRef: MatDialogRef<SimpleInformationModalComponent>;

  constructor(private activatedRoute: ActivatedRoute, private projectService: ProjectService, public dialog: MatDialog,
    private encoderService: EncoderService, public spinnerService: LoadingSpinnerService, private router: Router,
    private snackBar: MatSnackBar, private timeUtil: TimeUtil, private commonUtil: CommonUtil,
    private appConfig: AppConfigService, private loginService: LoginService, private timezoneService: TimezoneService) {

    this.subs['timezone'] = timezoneService.timezone.takeWhile(() => this.alive).subscribe(timezone => {
      this.initialiseTimes();
    });

  }

  ngOnInit() {
    this.initialiseProject();
  }

  ngOnDestroy(): void {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
  }

  /**
   * @ngdoc method
   * @name initialiseTimes
   * @methodOf ProjectInnerViewComponent
   * @description This method should initialise times for showing on html
   */
  public initialiseTimes() {
    this.startTimeShow = this.timeUtil.getDateTime(this.project.startTime);
    this.actualStartTimeShow = this.timeUtil.getDateTime(this.project.actualStartTime);
    this.endTimeShow = this.timeUtil.getDateTime(this.project.endTime);
    this.actualEndTimeShow = this.timeUtil.getDateTime(this.project.actualEndTime);
  }


  /**
   * @ngdoc method
   * @name initialiseProject
   * @methodOf ProjectInnerViewComponent
   * @description This method should initialise project
   */
  public initialiseProject() {
    this.spinnerService.spinnerSubject.next(true);
    this.activatedRoute.params.subscribe(params => {
      this.projectService.getOneProject(params.id).subscribe(project => {
        this.handleGetOneProjectResponse(project);
      });
    });
  }

  /**
   * @ngdoc method
   * @name handleGetOneProjectResponse
   * @methodOf ProjectInnerViewComponent
   * @description This method should handle get one project response
   */
  private handleGetOneProjectResponse(project) {

    this.project = project;
    this.initialiseTimes();

    this.encoderService.getEncodersForProject(this.project.id).subscribe(encoders => {
      this.handleGetEncodersForProject(encoders);
    });
  }

  /**
   * @ngdoc method
   * @name changeEncoder
   * @methodOf ProjectInnerViewComponent
   * @description This method should change encoder and reset encoder params
   */
  public changeEncoder(selectedEncoder) {
    this.encoderService.getOneEncoder(selectedEncoder).subscribe(encoder => {
      this.handleGetOneEncoderResponse(encoder, true);
    });
  }

  /**
   * @ngdoc method
   * @name handleGetEncodersForProject
   * @methodOf ProjectInnerViewComponent
   * @description This method should handle response from server
   */
  private handleGetEncodersForProject(encoders) {
    this.encoderList = encoders;
    this.selectedEncoder = this.project.encoderId == '0' ? null : this.project.encoderId;
    this.checkStart();
    this.checkStop();
    if (this.selectedEncoder) {
      this.encoderService.getOneEncoder(this.selectedEncoder).subscribe(encoder => {
        this.handleGetOneEncoderResponse(encoder, false);
      });
    } else {
      this.setIsSaveAndApprovedButtonDisabled();
      this.spinnerService.spinnerSubject.next(false);
    }
  }

  /**
   * @ngdoc method
   * @name handleGetOneEncoderResponse
   * @methodOf ProjectInnerViewComponent
   * @description This method should handle response from server
   */
  private handleGetOneEncoderResponse(encoder, isOnChangeEncoder) {
    this.project.encoderId = encoder.id;
    this.encoder = encoder;
    if (this.project.encoderId && this.project.completed) {
      this.encoderList.push(encoder);
    }
    this.setValuesIfProjectIsTwoChannelAudio();
    if (isOnChangeEncoder) {
      this.setZerosValuesToNullForEncoder(this.encoder);
    } else {
      for (const key in this.encoder.defaultParams.inputDefaultParams) {
        if (this.project.projectParams.inputDefaultParams[key] !== null) {
          this.encoder.defaultParams.inputDefaultParams[key] = this.project.projectParams.inputDefaultParams[key];
        }
      }

      for (const key in this.encoder.defaultParams.outputDefaultParams) {
        if (this.project.projectParams.outputDefaultParams[key] !== null) {
          this.encoder.defaultParams.outputDefaultParams[key] = this.project.projectParams.outputDefaultParams[key];
        }
      }

      const copy = JSON.stringify(this.encoder);
      this.encoderCopy = JSON.parse(copy);
      this.spinnerService.spinnerSubject.next(false);
    }

  }

  /**
   * @ngdoc method
   * @name startRecording
   * @methodOf ProjectInnerViewComponent
   * @description This method should start recording on "Start recording" button click
   */
  public startRecording() {
    const inRange = this.timeUtil.isCurrentTimeBetweenTimeRange(this.project.actualStartTime, this.project.actualEndTime);
    if (inRange) {
      this.spinnerService.spinnerSubject.next(true);
      this.projectService.startRecordingLive(this.project.id).subscribe(res => {
        this.handelAfterStartRecording();
      });
    } else {
      this.openSimpleInformationModal(
        'Override is required',
        'This stream is not scheduled to start yet, manual override is required.',
        'warning');
    }
  }

  /**
   * @ngdoc method
   * @name stopRecording
   * @methodOf ProjectInnerViewComponent
   * @description This method should stop recording on "Stop recording" button click
   */
  public stopRecording() {
    this.spinnerService.spinnerSubject.next(true);
    this.projectService.stopRecordingLive(this.project.id).subscribe(res => {
      this.handelAfterStopRecording();
    });
  }

  /**
   * @ngdoc method
   * @name rejoinInterVuProject
   * @methodOf ProjectInnerViewComponent
   * @description This method should stop recording on "Stop recording" button click
   */
  public rejoinInterVuProject() {
    this.spinnerService.spinnerSubject.next(true);
    this.projectService.rejoinInterVuProject(this.project.id).subscribe(res => {
      this.handelAfterRejoinInterVuProject('Reconnecting');
    });
  }

  /**
   * @ngdoc method
   * @name handelAfterStartRecording
   * @methodOf ProjectInnerViewComponent
   * @description This method should close spinner and set visibility properties
   */
  public handelAfterStartRecording() {
    setTimeout(() => {
      this.updateAndStartRecording = false;
      this.stopVisibility = this.currentRoute === 'projects-at-risk' ? false : true;
      this.startVisibility = false;
      this.spinnerService.spinnerSubject.next(false);
      this.snackBar.open('Recording started!', 'Ok', { duration: 2000 });
    }, 2000);
  }

  /**
   * @ngdoc method
   * @name handelAfterStopRecording
   * @methodOf ProjectInnerViewComponent
   * @description This method should close spinner and set visibility properties
   */
  public handelAfterStopRecording() {
    setTimeout(() => {
      this.startVisibility = this.currentRoute === 'projects-at-risk' ? false : true;
      this.stopVisibility = false;
      this.spinnerService.spinnerSubject.next(false);
      this.snackBar.open('Recording stopped!', 'Ok', { duration: 2000 });
    }, 2000);
  }

  /**
   * @ngdoc method
   * @name handelAfterRejoinInterVuProject
   * @methodOf ProjectInnerViewComponent
   * @description This method should close spinner and set visibility properties
   */
  public handelAfterRejoinInterVuProject(msg_text) {
    setTimeout(() => {
      this.startVisibility = false;
      this.stopVisibility = true;
      this.spinnerService.spinnerSubject.next(false);
      this.snackBar.open(msg_text, 'Ok', { duration: 2000 });
    }, 2000);
  }

  /**
   * @ngdoc method
   * @name startRecordingAndSaveEncoder
   * @methodOf ProjectInnerViewComponent
   * @description This method should start recording or save and start if it is necessary
   */
  public startRecordingAndSaveEncoder() {
    if (this.isEncoderEqualToEncoderCopy()) {
      this.startRecording();
    } else {
      this.updateAndStartRecording = true;
      this.updateProject();
    }
  }

  /**
   * @ngdoc method
   * @name isEncoderEqualToEncoderCopy
   * @methodOf ProjectInnerViewComponent
   * @description This method should checked if encoder params changed
   */
  public isEncoderEqualToEncoderCopy() {
    if (this.encoderCopy !== undefined) {
      return deepEqual(this.encoder.defaultParams, this.encoderCopy.defaultParams, '===');
    } else {
      return false;
    }
  }


  /**
   * @ngdoc method
   * @name detectParamsValueChange
   * @methodOf ProjectInnerViewComponent
   * @description This method assigns encoders values from child component
   */
  public detectParamsValueChange($event: DefaultParams) {
    this.encoder.defaultParams = $event;
    this.project.projectParams = $event;
  }

  /**
   * @ngdoc method
   * @name updateProject
   * @methodOf ProjectInnerViewComponent
   * @description This method calls api for updating project
   */
  public updateProject() {

    this.spinnerService.spinnerSubject.next(true);
    this.project.callInfo = '0';

    this.newEncoderDialogRef = this.dialog.open(EncoderNewDialogComponent, {
      hasBackdrop: false
    });

    this.newEncoderDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'OK') {
          this.projectService.updateProject(this.project.id, this.project).subscribe(res => {
            this.handleAfterUpdateProject();
            if (this.updateAndStartRecording) {
              this.startRecording();
            }
          }, error => {
            if (error.status === 405) {
              this.spinnerService.spinnerSubject.next(false);
              const snackBarRef = this.snackBar.open('Completed project cannot be saved.', 'Ok', { duration: 4000 });
              snackBarRef.afterDismissed().subscribe(null, null, () => {
                this.initialiseProject();
              });
            }
          });
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });
  }

  /**
   * @ngdoc method
   * @name handleAfterUpdateProject
   * @methodOf ProjectInnerViewComponent
   * @description This method should handle after update project
   */
  private handleAfterUpdateProject() {
    setTimeout(() => {
      this.snackBar.open('Successfully updated!', 'Ok', { duration: 2000 });
      this.spinnerService.spinnerSubject.next(false);

      if (this.currentRoute === 'live-streaming') {
        this.router.navigate(['/live-streaming']);
      } else if (this.currentRoute === 'inter-vu') {
        this.router.navigate(['/intervu']);
      } else if (this.currentRoute === 'projects-at-risk') {
        this.router.navigate(['/projects-at-risk']);
      } else if (this.currentRoute === 'all-projects') {
        this.router.navigate(['/all-projects']);
      }
    }, 2000);
  }

  /**
   * @ngdoc method
   * @name isSaveAndApprovedButtonDisabled
   * @methodOf ProjectInnerViewComponent
   * @description This method should set isSaveAndApprovedButtonDisabled to true if encoder contains properties with null values
   */
  public setIsSaveAndApprovedButtonDisabled() {
    if (this.selectedEncoder === null || this.encoder === undefined || this.project.canceled || this.project.completed) {
      this.isSaveButtonDisabled = true;
    } else {
      if (this.commonUtil.containsPropertiesWithNullValues(this.encoder.defaultParams.inputDefaultParams) ||
        this.commonUtil.containsPropertiesWithNullValues(this.encoder.defaultParams.outputDefaultParams)) {
        this.isSaveButtonDisabled = true;
      } else {
        this.isSaveButtonDisabled = false;
      }
    }
  }

  /**
   * @ngdoc method
   * @name setZerosValuesToNullForEncoder
   * @methodOf ProjectInnerViewComponent
   * @description This method should set all params values to null if value is 0.
   * This is necessary because of validations and required directive. Zero value is valid, we need nulls.
   */
  public setZerosValuesToNullForEncoder(encoder) {
    this.commonUtil.setZerosValuesToNull(encoder.defaultParams.inputDefaultParams);
    this.commonUtil.setZerosValuesToNull(encoder.defaultParams.outputDefaultParams);
  }

  /**
   * @ngdoc method
   * @name checkStop
   * @methodOf ProjectInnerViewComponent
   * @description This method checks for status of Stop button.
   */
  public checkStop() {
    const inRange = this.timeUtil.inRange(this.project.startTime, this.project.endTime);
    if (this.project.manuallyStopped) {
      this.stopVisibility = false;
    }
    if (!this.project.manuallyStopped && inRange && this.project.status === 'STREAMING') {
      this.stopVisibility = true;
    }
  }

  /**
   * @ngdoc method
   * @name checkStart
   * @methodOf ProjectInnerViewComponent
   * @description This method checks for status of Start button.
   */
  public checkStart() {
    const inRange: boolean = this.timeUtil.inRange(this.project.startTime, this.project.endTime);

    if (this.project.manuallyStopped && inRange) {
      this.startVisibility = true;
    }

    if (!this.project.manuallyStopped && inRange) {
      if (this.project.status === 'STREAMING') {
        this.startVisibility = false;
      }
      if (this.project.status !== 'STREAMING') {
        this.startVisibility = true;
      }
    }

  }

  /**
   * @ngdoc method
   * @name goToEncoderPage
   * @methodOf ProjectInnerViewComponent
   * @description This method navigate from button click to specific encoder page
   */
  public goToEncoderPage() {
    this.router.navigate(['/encoder/encoder-detail/' + this.selectedEncoder]);
  }

  /**
   * @ngdoc method
   * @name setValuesIfProjectIsTwoChannelAudio
   * @methodOf ProjectInnerViewComponent
   * @description This method should set wowzapp id, audio id, and dropdown options if project is two channel audio
   */
  public setValuesIfProjectIsTwoChannelAudio() {
    if (this.project.twoChannelAudio) {
      this.encoder.allParams.inputEncoderAllParams.wowzaApps = this.encoder.allParams.inputEncoderAllParams.wowzaApps.filter(
        element => element.id === this.twoCHDVRNTOptionId);
      this.encoder.allParams.outputEncoderAllParams.audioChannels = this.encoder.allParams.outputEncoderAllParams.audioChannels.filter(
        element => element.id === this.stereoAudioChannelId);
      this.encoder.defaultParams.inputDefaultParams.wowzaAppId = this.twoCHDVRNTOptionId;
      this.encoder.defaultParams.outputDefaultParams.audioId = this.stereoAudioChannelId;
    }
  }

  /**
   * @ngdoc method
   * @name openSimpleInformationModal
   * @methodOf ProjectInnerViewComponent
   * @description This method should open simple information modal
   */
  public openSimpleInformationModal(modalTitle, modalMessageContent, modalType) {
    this.spinnerService.spinnerSubject.next(true);
    this.simpleInformationModalDialogRef = this.dialog.open(SimpleInformationModalComponent, {
      hasBackdrop: false,
      data: {
        modalTitle: modalTitle,
        modalMessageContent: modalMessageContent,
        modalType: modalType
      }
    });

    this.simpleInformationModalDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'OK') {
          this.openOverriddenTimesModal();
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });
  }

  /**
   * @ngdoc method
   * @name openOverriddenTimesModal
   * @methodOf ProjectInnerViewComponent
   * @description This method should open overridden times modal and submit times change
   */
  public openOverriddenTimesModal() {
    this.spinnerService.spinnerSubject.next(true);
    this.overriddenTimesDialogRef = this.dialog.open(OverriddenTimesModalComponent, {
      hasBackdrop: false,
      data: {
        startTime: this.project.startTime,
        endTime: this.project.endTime,
        actualEndTime: this.project.actualEndTime,
        actualStartTime: this.project.actualStartTime,
      }
    });

    this.overriddenTimesDialogRef
      .afterClosed()
      .subscribe(message => {
        if (message === 'OK') {
          this.projectService.overrideTimes(this.project.id, this.overriddenTimesDialogRef.componentInstance.data).subscribe(res => {
            this.initialiseProject();
            this.snackBar.open('Successfully overridden times!', 'Ok', { duration: 2000 });
          });
        } else if (message === 'Cancel') {
          this.spinnerService.spinnerSubject.next(false);
        }
      });
  }

  public onCheckBoxChange($event: MatCheckboxChange) {
    const data = { 'Automated': $event.checked };
    this.spinnerService.spinnerSubject.next(true);
    this.projectService.updateAutomated(this.project.id, data).subscribe(res => {
      this.initialiseProject();
      this.snackBar.open('Successfully updated!', 'Ok', { duration: 2000 });
    });
  }
}
