import { Credentials } from './credentials';
import { Injectable } from '@angular/core';
import { Permission } from './permission.enum';
import { LoginService } from './services/login.service';
import { Observable } from 'rxjs';
import { SessionRepoService } from './session-repo.service';
import { filter, tap } from 'rxjs/operators';
import { UserSession } from './user-session';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _loggedIn: boolean;
  private _initialized = false;
  private _permissions: Permission[] = [];
  private _username: string;

  constructor(private sessionRepo: SessionRepoService, private loginService: LoginService) { }

  init() {
    const refreshToken = this.sessionRepo.refreshToken;
    this._loggedIn = !!refreshToken;
    if (!this._loggedIn) {
      this._initialized = true;
      return;
    }

    this.refreshSession().subscribe(
      () => { },
      () => this._initialized = true,
      () => this._initialized = true);
  }

  login(credentials: Credentials): Observable<UserSession> {
    return this.loginService.login(credentials).pipe(
      tap(session => this.saveSession(session)));
  }

  refreshSession(): Observable<UserSession> {
    return this.loginService.refresh(this.sessionRepo.refreshToken).pipe(
      tap(session => this.saveSession(session),
      () => this.deleteSession()));
  }

  private saveSession(session: UserSession): void {
    this.sessionRepo.accessToken = session.accessToken;
    this.sessionRepo.refreshToken = session.refreshToken;
    this._loggedIn = true;
    this._username = session.username;
    this._permissions = session.permissions;
  }

  deleteSession(): void {
    this.sessionRepo.clear();
    this._loggedIn = false;
    this._username = null;
    this._permissions = [];
  }

  get loggedIn(): boolean {
    return this._loggedIn;
  }

  hasPermission(permission: Permission): boolean {
    return this._permissions.includes(permission);
  }

  get username(): string {
    return this._username;
  }

  get minimumPermissions(): boolean {
    return this._permissions.length > 0;
  }

  get initialized(): boolean {
    return this._initialized;
  }

  get defaultRoute(): string {
    if (this._permissions.includes(Permission.EncoderAutomation)) {
      return '/live-streaming';
    }

    if (this._permissions.includes(Permission.PurgingRead)) {
      return '/purge-client-exception';
    }

    return null;
  }
}
