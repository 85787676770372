import {Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PurgingDialogComfirmationModel } from 'src/app/models/purging-dialog-comfirmation.model';
import { Confirms } from 'src/app/models/confirms.type';

@Component({
  selector: 'app-purging-dialog-confirmation',
  templateUrl: './purging-dialog-confirmation.component.html',
  styleUrls: [
    './purging-dialog-confirmation.component.css'
  ]
})
export class PurgingDialogConfirmationComponent {
  public currentRoute = '';
  public type: string;

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<PurgingDialogConfirmationComponent, Confirms>,
              @Inject(MAT_DIALOG_DATA) public data: PurgingDialogComfirmationModel) {}

  submit() {
    this.dialogRef.close('OK');
  }

  cancel() {
    this.dialogRef.close('Cancel');
  }

}
