import { SessionRepoService } from './session-repo.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AddAccessTokenToEveryHttpRequest implements HttpInterceptor {
  constructor(private sessionRepo: SessionRepoService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.sessionRepo.accessToken;
    if (accessToken) {
      req = req.clone({ setHeaders: { Authorization: 'Bearer ' + accessToken } });
    }

    return next.handle(req);
  }
}
