import { UserMessageService } from './user-message.service';
import { NoPermissionsError } from './no-permissions-error';
import { UserService } from './user.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class Handle401 implements HttpInterceptor {
  constructor(private userService: UserService, private messageService: UserMessageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => {
      if (error.status !== 401) {
        return throwError(error);
      }

      return this.userService.refreshSession().pipe(
        catchError(errorRefreshing => {
          if (errorRefreshing instanceof NoPermissionsError) {
            this.messageService.showError('No permissions to view the page.');
          }

          return EMPTY;
        }),
        mergeMap(() => next.handle(req)));
    }));
  }
}
