export const DROPDOWN_OPTIONS_BY_ENCODER_TYPE = {
  1: [{
    label: 'status',
    className: 'encoders-status-dropdown',
    methodName: 'encodersIsSelectedListOfActiveStatus',
    placeholder: 'Filter list by status:',
    options: [
      {name: 'Active', value: 'APPROVED'},
      {name: 'Inactive', value: 'DEACTIVATED'},
      {name: 'All', value: 'ALL'}
    ]
  },
  {
    label: 'projectType',
    className: 'encoders-project-type-dropdown',
    methodName: 'encodersProjectType',
    placeholder: 'Filter list by project type:',
    options: [
      {name: 'All', value: 'ALL'},
      {name: 'InterVu', value: 'INTER_VU'},
      {name: 'Live', value: 'LIVE_STREAMING'}
    ]
  }],
  3: [{
    label: 'encodersAtRiskStatus',
    className: 'encoders-at-risk-status-dropdown',
    methodName: 'encodersAtRiskStatus',
    placeholder: 'Filter list by status:',
    options: [
      {name: 'All', value: 'ALL'},
      {name: 'Active', value: 'APPROVED'},
      {name: 'Inactive', value: 'DEACTIVATED'},
    ]
  }],
  5: [{
    label: 'encodersVersionStatus',
    className: 'encoder-version-status-dropdown',
    methodName: 'encodersVersionStatus',
    placeholder: 'Filter list by status:',
    options: [
      {name: 'All', value: 'ALL'},
      {name: 'Downloading', value: 'DOWNLOADING'},
      {name: 'Updating', value: 'UPDATING'},
      {name: 'Done', value: 'DONE'},
      {name: 'Error', value: 'ERROR'}
    ]
  }]
};

const EncoderStatusDropdown = [{
  label: 'encoderStatus',
  className: 'projects-encoder-status-dropdown',
  methodName: 'projectsEncoderStatus',
  placeholder: 'Filter list by encoder status:',
  options: [
    {name: 'All', value: 'ALL'},
    {name: 'Idle', value: 'IDLE'},
    {name: 'Streaming', value: 'STREAMING'},
    {name: 'Error', value: 'ERROR'},
    {name: 'None', value: 'NONE'}
  ]
}];

export const DROPDOWN_OPTIONS_BY_PROJECT_TYPE = {
  1: EncoderStatusDropdown,
  2: EncoderStatusDropdown,
  3: EncoderStatusDropdown
};
