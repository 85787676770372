import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const positiveInt32Validator : ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
   const maximumInt32Value = 2147483647;
   let valueToValidate = control.value;
   let positiveNumberRegex = new RegExp('^[1-9][0-9]*$');
   
   let isPositiveNumber = positiveNumberRegex.test(valueToValidate);
   return isPositiveNumber && valueToValidate <= maximumInt32Value ?
      null : {NotValidPositiveInt32: {value: valueToValidate}};
 }
