import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ClientTableModel } from "../models/client-table-model";
import { ClientTableModelDto } from "../models/client-table-model-dto";
import { AppConfigService } from "./app-config.service";

@Injectable({
    providedIn : 'root'
})
export class ClientService implements DataSource<ClientTableModel> {
    private dataSource: BehaviorSubject<ClientTableModel[]> = new BehaviorSubject<ClientTableModel[]>(null);
    readonly pageOffset = 1;

    constructor(private httpClient: HttpClient, private appConfig: AppConfigService){}

    connect(collectionViewer: CollectionViewer): Observable<ClientTableModel[]> {
        return this.dataSource.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        return this.dataSource.complete();
    }

    public loadData(sortDirection:string, filterQuery:string, pageIndex:number, itemsPerPage:number) : Observable<ClientTableModelDto>{ 
        const httpParams = {
            'order': sortDirection === "" ? "asc" : sortDirection,
            'page': (pageIndex + this.pageOffset).toString(),
            'size': itemsPerPage
        };
        if (filterQuery && filterQuery.length > 0) {
            httpParams['keyword'] = filterQuery;
        }
  
        const hdr = this.appConfig.getHeaders(httpParams);

        return this.httpClient.get<ClientTableModelDto>(
            this.appConfig.getWalnutUrl('/client'), hdr)
        .pipe(tap(res => {
            this.dataSource.next(res.clients);
        }));
    }
}
