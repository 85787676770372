import { UserService } from './../../../user.service';
import { StorageSyncTransition } from './../../../storage-sync-transition.enum';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { MatSort, MatDialog, MatSelect } from '@angular/material';
import { FormControl } from '@angular/forms';
import { TitleService } from 'src/app/services/title.service';
import { LoadingSpinnerService } from 'src/app/util/loading-spinner/loading-spinner.service';
import { ExpirationTypeFilterConfiguration } from '../../expiration-type-filter.configuration';
import { ComboBoxItem } from 'src/app/shared/combo-box/model/combo-box.item';
import { ExpirationType } from 'src/app/models/expiration-type';
import { ProjectExceptionService } from 'src/app/services/project-exception.service';
import { PurgingProjectDataDialogComponent } from '../purging-project-data-dialog/purging-project-data-dialog.component';
import { ExpirationProjectInformation } from '../../../models/expiration-project-information.model';
import { ProjectExceptionModel } from '../../../models/project-exception-model';
import { filter } from 'rxjs/operators';
import { CustomMatPaginatorComponent } from '../../../feature/custom-mat-paginator/custom-mat-paginator.component';
import { Permission } from 'src/app/permission.enum';
import { Subscription } from 'rxjs';
import { TimezoneService } from 'src/app/services/timezone.service';

@Component({
    selector: 'app-purging-project-exception',
    templateUrl : './purging-project-exception.component.html',
    styleUrls: [
        './purging-project-exception.component.css'
    ]
})
export class PurgingProjectExceptionComponent implements OnInit, AfterViewInit, OnDestroy {
    public dataSource: ProjectExceptionService;
    @ViewChild(CustomMatPaginatorComponent) paginator: CustomMatPaginatorComponent;
    @ViewChild(MatSort) sort: MatSort;
    public filter = new FormControl('', []);
    public expirationTypeFilterConfig = new ExpirationTypeFilterConfiguration();
    public readonly transitions = [new ComboBoxItem<StorageSyncTransition>('All', undefined)]
    .concat(
      Object.values(StorageSyncTransition).filter(_ => !isNaN(+_))
      .map(_ => +_)
      .map(_ => new ComboBoxItem<StorageSyncTransition>(StorageSyncTransition[_], _)));
    public readonly initialNextTransitionFilter: ComboBoxItem<StorageSyncTransition> = this.transitions[0];

    private currentId = '';
    private defaultExpirationValue: number;
    private expirationTypeFilter: ComboBoxItem<ExpirationType>;
    private nextTransitionFilter: ComboBoxItem<StorageSyncTransition> = this.initialNextTransitionFilter;
    public Permission = Permission;
    private timezoneServiceSubscription: Subscription;

    constructor(public appConfig: AppConfigService, private httpClient: HttpClient, projectExceptionService: ProjectExceptionService,
        private titleService: TitleService, private matDialog: MatDialog, private spinService: LoadingSpinnerService,
        public userService: UserService, private timezoneService: TimezoneService) {
        this.dataSource = projectExceptionService;
    }

    public columnsToDisplay = [
      'Id',
      'ClientName',
      'ClientId',
      'EndTime',
      'ExpirationValue',
      'Archive',
      'ProjectExpirationTime',
      'StorageSyncState',
      'NextTransition'
    ];
    ngOnInit(): void {
      this.sort.sortChange.subscribe(s => {
        this.paginator.pageIndex = 0;
        this.loadData();
      });
      this.paginator.page.subscribe(r => {
        this.loadData();
      });
      this.titleService.setTitle('Projects Expiration');
      this.expirationTypeFilter = this.expirationTypeFilterConfig.getInitialValue();
      this.timezoneServiceSubscription = this.timezoneService.timezone.subscribe(() => {
        this.loadData();
      });
    }

    loadData() {
      this.spinService.spinnerSubject.next(true);
      this.dataSource.loadData(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            this.currentId,
            this.expirationTypeFilter.itemValue,
            this.nextTransitionFilter.itemValue
            ).subscribe(r => {
              this.paginator.length = r.totalCount;
              this.paginator.moreExist = r.moreExist;
              this.defaultExpirationValue = r.defaultValue;
            }, e => {}, () => {
              this.spinService.spinnerSubject.next(false);
            });
    }

    ngAfterViewInit(): void {
      this.loadData();
    }

    projectRowClick(project: ProjectExceptionModel) {
      const diagRef = this.matDialog.open<PurgingProjectDataDialogComponent, ExpirationProjectInformation,
      ProjectExceptionModel>(PurgingProjectDataDialogComponent, {
        width: '550px',
        data: new ExpirationProjectInformation(this.defaultExpirationValue, project)
      });
      diagRef.afterClosed().pipe(filter(d => d != null)).subscribe(d => {
        this.dataSource.updateProjectStorageSettings(d).subscribe(r => {
          this.loadData();
        });
      });
    }

    search(): void {
      this.currentId = this.filter.value;
      this.paginator.pageIndex = 0;
      this.loadData();
    }

    clearFilter(): void {
        this.currentId = '';
        this.filter.reset();
        this.expirationTypeFilter = this.expirationTypeFilterConfig.getInitialValue();
        this.nextTransitionFilter = this.initialNextTransitionFilter;
        this.loadData();
      }

      expirationTypeFilterChanged(filterChanged: ComboBoxItem<ExpirationType>) {
        this.expirationTypeFilter = filterChanged;
        this.paginator.pageIndex = 0;
        this.loadData();
      }

      getFilterItem(): ComboBoxItem<ExpirationType> {
        return this.expirationTypeFilter;
      }

      onFilterByTransitionChanged(d: any) {
        this.nextTransitionFilter = d.value;
        this.paginator.pageIndex = 0;
        this.loadData();
      }

      ngOnDestroy(): void {
        this.timezoneServiceSubscription.unsubscribe();
      }
    }
