import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material';
import { TaniDashboardSessionService } from 'src/app/services/tani-dashboard-session.service';
import { LoadingSpinnerService } from 'src/app/util/loading-spinner/loading-spinner.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { TimezoneService } from 'src/app/services/timezone.service';

@Component({
    selector: 'app-tani-dashboard-sessions',
    templateUrl: './tani-dashboard-sessions.component.html',
    styleUrls: ['./tani-dashboard-sessions.component.css']
})

export class TaniDashboardSessionsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;

    public sessionsExist: boolean;
    public columnsToDisplay = ['startTime', 'endTime', 'respondents', 'moderators', 'technicians'];
    public projectId: number;
    private timezoneServiceSubscription: Subscription;

  constructor(
    public dataSource: TaniDashboardSessionService,
    private spinService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private timezoneService: TimezoneService
  ){}

  ngAfterViewInit(): void {
    this.loadData();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => this.projectId = params['project-id']);

    this.sort.sortChange.subscribe(() => {
      this.loadData();
    });
    this.timezoneServiceSubscription = this.timezoneService.timezone.subscribe(() => {
      this.loadData();
    });
  }

  loadData() {
    this.dataSource.loadData(this.projectId, this.sort.active, this.sort.direction)
    .subscribe(r => {
      this.sessionsExist = r.sessions.length > 0;
    });
  }

  ngOnDestroy(): void {
    this.timezoneServiceSubscription.unsubscribe();
  }
}
