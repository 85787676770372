import { Injectable } from '@angular/core';
import { TimezoneService } from '../services/timezone.service';
const Moment = require('moment');
const MomentRange = require('moment-range');

const moment = MomentRange.extendMoment(Moment);
@Injectable()
export class TimeUtil {

  public timezone = 'America/New_York';
  private alive = true;

  constructor(private timezoneService: TimezoneService) {
    timezoneService.timezone.takeWhile(() => this.alive).subscribe(timezone => { this.timezone = timezone; });
  }

  /**
   * @ngdoc method
   * @name getTime
   * @methodOf TimeUtil
   * @description This method should return only time format (HH:mm) from date input.
   */
  public getTime(date) {
    return moment(date).tz(this.timezone).format('hh:mm A');
  }

  /**
   * @ngdoc method
   * @name getDateTime
   * @methodOf TimeUtil
   * @description This method should return full date time format (MM/DD/YYYY HH:mm) from date input.
   */
  public getDateTime(date) {
    return moment(date).tz(this.timezone).format('MM/DD/YYYY hh:mm A');
  }

  /**
   * @ngdoc method
   * @name getDateTimeUTCFormat
   * @methodOf TimeUtil
   * @description This method should return full date time UTC format from date input.
   */
  public getDateTimeUTCFormat(date) {
    return moment(date).tz(this.timezone).format();
  }

  /**
   * @ngdoc method
   * @name isDateToBeforeDateFrom
   * @methodOf TimeUtil
   * @description This method should return true if dateTo is before dateFrom
   */
  public isDateToBeforeDateFrom(dateTo, dateFrom) {
    return moment(this.getDateTimeUTCFormat(dateTo)).isBefore(this.getDateTimeUTCFormat(dateFrom));
  }

  /**
   * @ngdoc method
   * @name toMilliseconds
   * @methodOf TimeUtil
   * @description This method should return milliseconds from passed date
   */
  public toMilliseconds(date) {
    return moment(date).tz(this.timezone).valueOf();
  }

  /**
   * @ngdoc method
   * @name getDateFromForQueryParam
   * @methodOf TimeUtil
   * @description This method should return dateFrom format prepared for query with 00:00:00 time
   */
  public getDateFromForQueryParam(dateFrom) {
    return moment(dateFrom).tz(this.timezone).set({h: 0, m: 0, s: 0}).utc().format();
  }

  /**
   * @ngdoc method
   * @name getDateToForQueryParam
   * @methodOf TimeUtil
   * @description This method should return dateTo format prepared for query with 23:59:59 time
   */
  public getDateToForQueryParam(dateTo) {
    return moment(dateTo).tz(this.timezone).set({h: 23, m: 59, s: 59}).utc().format();
  }

  /**
   * @ngdoc method
   * @name inRange
   * @methodOf TimeUtil
   * @description This method should return true or false based on if given start and end date are within the range of current time
   */
  public inRange(start, end) {
    const startDate  = new Date(start);
    const endDate    = new Date(end);
    const current = new Date();
    startDate.setHours(startDate.getHours() - 2);
    endDate.setHours(endDate.getHours() + 2);
    const range =  moment().range(startDate, endDate);
    return range.contains(current);
  }

  /**
   * @ngdoc method
   * @name isYearValid
   * @methodOf TimeUtil
   * @description This method should check is year is valid
   */
  public isYearValid(date) {
    return moment(date).year().toString().length === 4;
  }

  /**
   * @ngdoc method
   * @name isDateValid
   * @methodOf TimeUtil
   * @description This method should check is date is valid
   */
  public isDateValid(date) {
    return moment(date).isValid();
  }

  /**
   * @ngdoc method
   * @name getSimpleDateFormat
   * @methodOf TimeUtil
   * @description This method should return simple js Date
   */
  public getSimpleDateFormat(date) {
    return new Date(moment(date).tz(this.timezone).format('YYYY-MM-DD'));
  }

  /**
   * @ngdoc method
   * @name getHours
   * @methodOf TimeUtil
   * @description This method should extract hours from date
   */
  public getHours(date) {
    return moment(date).tz(this.timezone).format('hh');
  }

  /**
   * @ngdoc method
   * @name getMinutes
   * @methodOf TimeUtil
   * @description This method should extract minutes from date
   */
  public getMinutes(date) {
    return moment(date).tz(this.timezone).format('mm');
  }

  /**
   * @ngdoc method
   * @name getPeriod
   * @methodOf TimeUtil
   * @description This method should extract period from date
   */
  public getPeriod(date) {
    return moment(date).tz(this.timezone).format('A');
  }

  /**
   * @ngdoc method
   * @name formatFullDateFromDateHoursMinutesPeriod
   * @methodOf TimeUtil
   * @description This method should return full date from date, hours, minutes ans period
   */
  public formatFullDateFromDateHoursMinutesPeriod(date, hours, minutes, period) {
    const digitalTimeFormat = moment(`${hours}:${minutes} ${period}`, ['h:mm A']).format('HH:mm');
    return moment(date).local().format('YYYY-MM-DD') + `T${digitalTimeFormat}` + moment(date).tz(this.timezone).format('Z');
  }

  /**
   * @ngdoc method
   * @name addHoursToDate
   * @methodOf TimeUtil
   * @description This method should increase/decrease date for specific hours number
   */
  public addHoursToDate(date, hours) {
    return moment(date).add(hours, 'hours');
  }

  /**
   * @ngdoc method
   * @name isDateTimeSame
   * @methodOf TimeUtil
   * @description This method should check are two date-times same
   */
  public isDateTimeSame(firstDate, secondDate) {
    return moment(this.getDateTime(firstDate)).isSame(moment(this.getDateTime(secondDate)));
  }

  /**
   * @ngdoc method
   * @name isCurrentTimeBetweenTimeRange
   * @methodOf TimeUtil
   * @description This method should return true if current time is in time range
   */
  public isCurrentTimeBetweenTimeRange(start, end) {
    return moment().range(moment(start).tz(this.timezone), moment(end).tz(this.timezone)).contains(moment().tz(this.timezone));
  }
}
