import { OnInit, Injectable } from '@angular/core';
import { ColumnStateModel } from '../models/column-state';


@Injectable({
    providedIn: 'root'
})
export class ColumnStateService implements OnInit {

    public checkBoxState: Map<string, Map<string, ColumnStateModel>> = new Map<string, Map<string, ColumnStateModel>>();

    ngOnInit(): void {
    }

    getState(currentRoute: string, checkBoxName: string): ColumnStateModel {
        if (!this.checkBoxState.has(currentRoute)) {
            this.checkBoxState.set(currentRoute, new Map<string, ColumnStateModel>());
        }
        if (!this.checkBoxState.get(currentRoute).has(checkBoxName)) {
            this.checkBoxState.get(currentRoute).set(checkBoxName, new ColumnStateModel(checkBoxName, false));
        }
        return this.checkBoxState.get(currentRoute).get(checkBoxName);
    }
}
