import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, MatPaginator } from '@angular/material';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';
import { EncoderService } from '../../services/encoder.service';
import { EncoderDataSource } from 'src/app/models/encoder-data-source';
import { Router } from '@angular/router';
import { EncoderStatusUtil } from 'src/app/util/encoder-status-utils';

// list of filter dropdown params that don't use FormControl
const DROPDOWN_STRING_PARAM_LIST = ['status', 'encoderVersionStatus', 'encodersAtRiskStatus', 'projectType'];

@Component({
  selector: 'app-encoders-search',
  templateUrl: './encoders-search.component.html',
  styleUrls: ['./encoders-search.component.css']
})
export class EncodersSearchComponent implements OnInit {

  @Input() dataSourceInput: EncoderDataSource;
  @Input() sortInput: MatSort;
  @Input() paginatorInput: MatPaginator;
  @Input() encoderTypeFlagInput: number;
  @Input() defaultHeaderInput: ElementRef;
  @Input() placeHolders: {keyWord: string, id?: string};
  @Input() selectedEncoders: any[];
  @Output() dataSourceOutput = new EventEmitter<any>();
  @Output() updateSelected = new EventEmitter<any>();

  public projectIdValidationMessage = 'Project ID must be a number!';
  public keyWord = new FormControl('', []);
  public projectId = new FormControl('', []);
  public status: string = 'APPROVED';
  private encoderVersionStatus: string = null;
  private encodersAtRiskStatus: string = null;
  private projectType: string = null;
  private statusDefaultValue = undefined;
  private pageDefaultValue = undefined;
  private encoderVersionStatusDefaultValue = undefined;
  private encodersAtRiskStatusDefaultValue = undefined;

  constructor
  (
    public spinnerService: LoadingSpinnerService,
    private encodersService: EncoderService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.encodersService.encodersIsSelectedListOfActiveStatus.subscribe(status => {
      this.status = status !== null ? status : 'APPROVED';
    });
    this.refreshList();
  }

  testForNumber({ target }) {
    if (!/^\d+$/.test(target.value) && target.value.length > 0) {
      this.projectId.setErrors({'notNumber': true});
    } else {
      this.projectId.setErrors(null);
    }
  }

  /**
   * @ngdoc method
   * @name setSearchParamsOnView
   * @methodOf EncodersSearchComponent
   * @description This method should set search params for showing on HTML page after switching page
   */
  private setSearchParamsOnView(params): void {
    const paramsArray: Array<[string, any]> = Object.entries(params);
    for (let index = 0; index < paramsArray.length; index++) {
      if (DROPDOWN_STRING_PARAM_LIST.includes(paramsArray[index][0])) {
        this[paramsArray[index][0]] = paramsArray[index][1];
        this.setStatusState(params);
      } else {
        this[paramsArray[index][0]] = new FormControl(paramsArray[index][1], []);
      }
    }
  }

  public setStatusState(params: any): void {
    if (EncoderStatusUtil.isStatusValid(params)) {
      this.status = params.status;
    }
  }

  /**
   * @ngdoc method
   * @name search
   * @methodOf EncodersSearchComponent
   * @description This method should search projects by input parameters on "Search" button click
   */
  public search(): void {
    const status: string = this.encoderTypeFlagInput !== 1 ? null : this.status;
    if ((this.keyWord.value && this.keyWord.value.length) || (this.projectId.value && this.projectId.value.length)) {
        this.spinnerService.spinnerSubject.next(true);
        setTimeout(() => {
          const pageNo = 0;
          this.spinnerService.spinnerSubject.next(false);
          this.getDataSource(this.keyWord.value, this.projectId.value, status, pageNo, this.encoderVersionStatus, this.encodersAtRiskStatus, this.projectType);
        }, 2000);
    }
  }

  /**
   * @ngdoc method
   * @name clearFilter
   * @methodOf EncodersSearchComponent
   * @description This method should clear filter and set picker on current date on "Clear filter" button click
   */
  public clearFilter(): void {
    const {sort: defaultSort, direction: defaultDirection} = this.defaultHeaderInput.nativeElement.dataset;
    const {active, direction} = this.sortInput;
    const dontResetSort = active === defaultSort && direction === defaultDirection;
    const pageNo = 0;
    const status: string = this.encoderTypeFlagInput !== 1 ? null : this.status;

    this.keyWord = new FormControl('', []);
    this.projectId = new FormControl('', []);
    this.paginatorInput.pageIndex = pageNo;

    if (!dontResetSort) {
      this.dataSourceInput.changePage(this.router.url, pageNo);
      this.prepareAndSetSearchParams(this.keyWord.value, this.projectId.value, status, this.encoderVersionStatus, this.encodersAtRiskStatus, this.projectType);
      this.dataSourceInput.changeFilters(this.keyWord.value, this.projectId.value, status, this.encoderVersionStatus, this.encodersAtRiskStatus, this.projectType);
      this.defaultHeaderInput.nativeElement.click();
    } else {
      this.getDataSource(this.keyWord.value, this.projectId.value, status, pageNo, this.encoderVersionStatus, this.encodersAtRiskStatus, this.projectType);
    }
  }

  /**
   * @ngdoc method
   * @name refreshList
   * @methodOf EncodersSearchComponent
   * @description This method should refresh list on Refresh button click
   */
  public refreshList(): void {

    const status: string = this.encoderTypeFlagInput !== 1 ? null : this.status;

    this.setSearchParamsDependingOnEncoderTypeFlagInput();
    this.getDataSource(this.keyWord.value, this.projectId.value, status, this.paginatorInput.pageIndex, this.encoderVersionStatus, this.encodersAtRiskStatus, this.projectType);
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf EncodersSearchComponent
   * @description This method should set dataSource
   */
  public getDataSource(keyWord: string, projectId?: string, status?: string, page?: number, encoderVersionStatus?: string, encodersAtRiskStatus?: string, projectType?): void {
    const statusIsActive: string = this.encoderTypeFlagInput !== 1 ? null : this.status;
    this.prepareAndSetSearchParams(keyWord, projectId, statusIsActive, encoderVersionStatus, encodersAtRiskStatus, projectType);
    this.dataSourceInput
      .fetchFreshData(
        keyWord,
        projectId,
        page,
        statusIsActive,
        this.router.url,
        this.encoderVersionStatus,
        this.encodersAtRiskStatus,
        this.projectType
      );
    this.dataSourceOutput.emit(this.dataSourceInput);
  }

  /**
   * @ngdoc method
   * @name prepareAndSetSearchParams
   * @methodOf EncodersSearchComponent
   * @description This method should prepare and set search params
   */
  private prepareAndSetSearchParams(
    keyWord: string,
    projectId: string,
    status: string,
    encoderVersionStatus?: string,
    encodersAtRiskStatus?: string,
    projectType?: string
  ): void {
    switch (this.encoderTypeFlagInput) {
      case 1:
        this.encodersService.encodersKeyword.next(keyWord);
        this.encodersService.encodersIsSelectedListOfActiveStatus.next(status);
        this.encodersService.encodersProjectType.next(projectType);
        break;
      case 2:
        this.encodersService.encodersNewKeyword.next(keyWord);
        break;
      case 3:
        this.encodersService.encodersAtRiskKeyword.next(keyWord);
        this.encodersService.encodersAtRiskStatus.next(encodersAtRiskStatus);
        break;
      case 4:
        this.encodersService.encodersWithErrorKeyword.next(keyWord);
        this.encodersService.encodersWithErrorProjectId.next(projectId);
        break;
      case 5:
        this.encodersService.encodersVersionKeyword.next(keyWord);
        this.encodersService.encodersVersionStatus.next(encoderVersionStatus);
        break;
    }
  }

  /**
   * @ngdoc method
   * @name setSearchParamsDependingOnEncoderTypeFlagInput
   * @methodOf EncodersSearchComponent
   * @description This method should prepare and set search params depending on project type flag input
   */
  private setSearchParamsDependingOnEncoderTypeFlagInput(): void {
    let keyword, status, projectId, encoderVersionStatus, encodersAtRiskStatus, projectType;
    const params = {};
    switch (this.encoderTypeFlagInput) {
      case 1:
        keyword = this.encodersService.encodersKeyword.getValue();
        status = this.encodersService.encodersIsSelectedListOfActiveStatus.getValue();
        projectType = this.encodersService.encodersProjectType.getValue();

        if (keyword !== this.keyWord.value) {
          params['keyWord'] = keyword;
        }
        if (status !== this.status) {
          params['status'] = status;
        }
        if (projectType !== this.projectType) {
          params['projectType'] = projectType;
        }
        this.setSearchParamsOnView(params);
        break;
      case 2:
        this.encodersService.encodersNewKeyword.subscribe((keyWord: string) =>
          keyWord !== this.keyWord.value && this.setSearchParamsOnView({keyWord}));
        break;
      case 3:
        keyword = this.encodersService.encodersAtRiskKeyword.getValue();
        encodersAtRiskStatus = this.encodersService.encodersAtRiskStatus.getValue();
        if (keyword !== this.keyWord.value) {
          params['keyWord'] = keyword;
        }
        if (encodersAtRiskStatus && encodersAtRiskStatus !== this.encodersAtRiskStatus) {
          params['encodersAtRiskStatus'] = encodersAtRiskStatus;
        }
        this.setSearchParamsOnView(params);
        break;
      case 4:
        keyword = this.encodersService.encodersWithErrorKeyword.getValue();
        projectId = this.encodersService.encodersWithErrorProjectId.getValue();
        if (keyword !== this.keyWord.value) {
          params['keyWord'] = keyword;
        }
        if (projectId !== this.projectId.value) {
          params['projectId'] = projectId;
        }
        this.setSearchParamsOnView(params);
        break;
      case 5:
        keyword = this.encodersService.encodersVersionKeyword.getValue();
        encoderVersionStatus = this.encodersService.encodersVersionStatus.getValue();
        if (keyword !== this.keyWord.value) {
          params['keyWord'] = keyword;
        }
        if (encoderVersionStatus !== this.encoderVersionStatus) {
          params['encoderVersionStatus'] = encoderVersionStatus;
        }
        this.setSearchParamsOnView(params);
        break;
    }
  }

  /**
   * @ngdoc method
   * @name onChangeStatus
   * @methodOf EncodersSearchComponent
   * @description This method should change list of active encoders with list of all encoders and vice versa
   */
  public onChangeStatus(event): void {
    let keyWord = null, projectId = null;
    if (event.source.selected) {
      this.paginatorInput.pageIndex = 0;
      switch (this.encoderTypeFlagInput) {
        case 1:
          this[event.label] = event.value;
          keyWord = this.encodersService.encodersKeyword.getValue();
          this.getDataSource(keyWord, projectId, this.status, this.pageDefaultValue, this.encoderVersionStatusDefaultValue, this.encodersAtRiskStatusDefaultValue, this.projectType);
          break;
        case 3:
            this.encodersAtRiskStatus = event.value;
            keyWord = this.encodersService.encodersAtRiskKeyword.getValue();
            this.getDataSource(keyWord, this.projectId.value, this.statusDefaultValue, this.pageDefaultValue, this.encoderVersionStatusDefaultValue, event.value);
          break;
        case 5:
          this.encoderVersionStatus = event.value;
          keyWord = this.encodersService.encodersVersionKeyword.getValue();
        this.getDataSource(keyWord, this.projectId.value, this.statusDefaultValue, this.pageDefaultValue, event.value);
          break;
      }
    }
  }


  /**
   * @ngdoc method
   * @name callUpdate
   * @methodOf EncodersSearchComponent
   * @description This method should call parrent updateSelected method
   */
  public callUpdate(): void {
    this.updateSelected.emit();
  }
}
