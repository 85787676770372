import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService} from './app-config.service';
import { StreamDestinationData } from '../models/stream-destination-data';
import { Observable } from 'rxjs';
import { Encoder } from '../models/encoder';

@Injectable()
export class StreamService {

  constructor(private http: HttpClient, private appConfig: AppConfigService) { }

  public getStreamUrl(encoder: Encoder): Observable<StreamDestinationData> {
     return this.http.post<StreamDestinationData>(this.appConfig.getUrl(`/stream`), encoder, this.appConfig.getHeaders());
  }
}
