import { UserService } from './../../../user.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { MatSort, MatDialog } from '@angular/material';
import { ClientExceptionService } from 'src/app/services/client-exception.service';
import { ClientExceptionModel } from 'src/app/models/client-exception-model';
import { FormControl } from '@angular/forms';
import { TitleService } from 'src/app/services/title.service';
import { PurgingClientDataDialogComponent } from '../purging-client-data-dialog/purging-client-data-dialog.component';
import { filter } from 'rxjs/operators';
import { ExpirationClientInformation } from 'src/app/models/expiration-client-information.model';
import { LoadingSpinnerService } from 'src/app/util/loading-spinner/loading-spinner.service';
import { ExpirationTypeFilterConfiguration } from '../../expiration-type-filter.configuration';
import { ComboBoxItem } from 'src/app/shared/combo-box/model/combo-box.item';
import { ExpirationType } from 'src/app/models/expiration-type';
import { CustomMatPaginatorComponent } from 'src/app/feature/custom-mat-paginator/custom-mat-paginator.component';
import { ClientStatusFilterConfiguration } from '../../client-status-filter.configuration';
import { ClientStatus } from '../../../models/client-status';
import { Permission } from 'src/app/permission.enum';

@Component({
    selector: 'app-purging-client-exception',
    templateUrl : './purging-client-exception.component.html',
    styleUrls: [
        './purging-client-exception.component.css'
    ]
})
export class PurgingClientExceptionComponent implements OnInit, AfterViewInit {
    public dataSource: ClientExceptionService;
    @ViewChild(CustomMatPaginatorComponent) paginator: CustomMatPaginatorComponent;
    @ViewChild(MatSort) sort: MatSort;
    public filter = new FormControl('', []);
    public expirationTypeFilterConfig = new ExpirationTypeFilterConfiguration();
    public clientStatusFilterConfig = new ClientStatusFilterConfiguration();

    private currentFilter = '';
    private defaultExpirationValue: number;
    private expirationTypeFilter: ComboBoxItem<ExpirationType>;
    private clientStatusFilter: ComboBoxItem<ClientStatus>;
    public Permission = Permission;


    constructor(public appConfig: AppConfigService, private httpClient: HttpClient, clientExceptionService: ClientExceptionService,
        private titleService: TitleService, private matDialog: MatDialog, private spinService: LoadingSpinnerService,
        public userService: UserService) {
        this.dataSource = clientExceptionService;
    }

    public columnsToDisplay = [
        'ClientName',
        'ClientId',
        'ClientStatus',
        'ExpirationValue'
    ];
    ngOnInit(): void {
        this.sort.sortChange.subscribe(s => {
            this.paginator.pageIndex = 0;
            this.loadData();
        });
        this.paginator.page.subscribe(r => {
            this.loadData();
        });
        this.titleService.setTitle('Clients Expiration');
        this.expirationTypeFilter = this.expirationTypeFilterConfig.getInitialValue();
        this.clientStatusFilter = this.clientStatusFilterConfig.getInitialValue();
    }

    loadData() {
        this.spinService.spinnerSubject.next(true);
        this.dataSource.loadData(this.paginator.pageIndex,
            this.paginator.pageSize, this.sort.active, this.sort.direction, this.currentFilter,
            this.expirationTypeFilter.itemValue, this.clientStatusFilter.itemValue).subscribe(r => {
            this.paginator.length = r.totalCount;
            this.paginator.moreExist = r.moreExist;
            this.defaultExpirationValue = r.defaultValue;
        }, e => {}, () => {
            this.spinService.spinnerSubject.next(false);
        });
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    clientRowClick(client: ClientExceptionModel) {
        const diagRef = this.matDialog.open<PurgingClientDataDialogComponent, ExpirationClientInformation,
        ClientExceptionModel>(PurgingClientDataDialogComponent, {
            width: '500px',
            data: new ExpirationClientInformation(this.defaultExpirationValue, client)
        });
        diagRef.afterClosed().pipe(filter(d => d != null)).subscribe(d => {
            this.dataSource.updateClientStorageSettings(d).subscribe(r => {
                this.loadData();
            });
        });
    }

    search(): void {
        this.currentFilter = this.filter.value;
        this.paginator.pageIndex = 0;
        this.loadData();
    }

    clearFilter(): void {
        this.currentFilter = '';
        this.filter.reset();
        this.expirationTypeFilter = this.expirationTypeFilterConfig.getInitialValue();
        this.clientStatusFilter = this.clientStatusFilterConfig.getInitialValue();
        this.loadData();
    }

    expirationTypeFilterChanged(filterChanged: ComboBoxItem<ExpirationType>) {
        this.expirationTypeFilter = filterChanged;
        this.paginator.pageIndex = 0;
        this.loadData();
    }

    getFilterItemExpirationType(): ComboBoxItem<ExpirationType> {
        return this.expirationTypeFilter;
    }

    clientStatusFilterChanged(filterChanged: ComboBoxItem<ClientStatus>) {
        this.clientStatusFilter = filterChanged;
        this.paginator.pageIndex = 0;
        this.loadData();
    }

    getFilterItemClientStatus(): ComboBoxItem<ClientStatus> {
        return this.clientStatusFilter;
    }
}
