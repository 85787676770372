import { Component, OnInit , ViewChild, ChangeDetectionStrategy, ElementRef, OnDestroy } from '@angular/core';
import { MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { EncoderService } from '../../services/encoder.service';
import { LoginService } from '../../services/login.service';
import { AppConfigService } from '../../services/app-config.service';
import { PaginationService } from '../../services/pagination.service';
import { EncoderDataSource} from '../../models/encoder-data-source';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';
import { DynamicPlaceholders } from 'src/app/models/search-filter.model';

@Component({
  selector: 'app-encoders-at-risk',
  templateUrl: './encoders-at-risk.component.html',
  styleUrls: ['./encoders-at-risk.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * @ngdoc component
 * @name encoders-at-risk.component:EncodersAtRiskComponent
 * @description This component shows list of Encoders At Risk
 */

export class EncodersAtRiskComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('default', { read: ElementRef }) defaultHeader: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public columnsToDisplay = ['name', 'mac', 'pcName', 'facility', 'room', 'statusName', 'systemType', 'error', 'tooltip'];
  public pageSize = 10;
  public dataSource: EncoderDataSource;
  public currentRoute: string = this.router.url;
  public selectedListStatus = 'ALL';
  public encoderTypeFlag = 3;
  public pageIndexForType: Map<string, number> = new Map<string, number>();
  public searchPlaceholder: DynamicPlaceholders = {
    keyWord: 'Search by name, MAC, PC name and location',
  };
  private encoderVersionStatusDefaultValue = undefined;
  private selectedTypeDefaultValue = undefined;
  private systemTypeDefaultValue = undefined;

  constructor(
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private encoderService: EncoderService,
    private router: Router,
    public spinnerService: LoadingSpinnerService,
    private paginationService: PaginationService
  ) {}

  ngOnInit() {
    this.getDataSource();
  }

  ngOnDestroy(): void {
    this.dataSource.unsubscribeAll();
  }

  /**
   * @ngdoc method
   * @name detectDataSourceChange
   * @methodOf EncodersAtRiskComponent
   * @description This method should get new data source form project-search component
   */
  public detectDataSourceChange(event: EncoderDataSource): void {
    this.dataSource = event;
  }


  /**
   * @ngdoc method
   * @name errorMessage
   * @methodOf EncodersAtRiskComponent
   * @description This method should return substring of error message if it is too long
   */
  public errorMessage(error) {
    return error.length < 40 ? error : error.substring(0, 40) + '...';
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf EncodersAtRiskComponent
   * @description This method should set dataSource
   */
  public getDataSource(isFilter?) {
    let key = '';
    const encodersAtRiskStatus = this.encoderService.encodersAtRiskStatus.getValue() || 'ALL';

    this.encoderService.encodersAtRiskKeyword.subscribe(keyWord => {
      key = keyWord !== undefined && keyWord !== null ? keyWord : '';
      this.paginator.pageIndex = keyWord
        ? 0 : this.paginationService.encodersAtRiskPageNumber;
    });

    this.dataSource = new EncoderDataSource(
      this.paginationService,
      this.appConfig,
      this.loginService,
      this.encoderService,
      this.spinnerService,
      this.sort,
      this.paginator,
      this.currentRoute,
      this.selectedListStatus,
      key,
      this.selectedTypeDefaultValue,
      this.systemTypeDefaultValue,
      this.encoderVersionStatusDefaultValue,
      encodersAtRiskStatus
    );
  }
}
