import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OnInit, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OptedOutDomainModel } from '../models/opted-out-domain.model';
import { OptedOutDomainsTableModel } from '../models/opted-out-domains-table.model';
import { AppConfigService } from './app-config.service';


@Injectable({
  providedIn: 'root'
})
export class OptedOutDomainsService implements DataSource<any> {
  readonly pageOffset = 1;
  private dataSource: BehaviorSubject<OptedOutDomainModel[]> = new BehaviorSubject<OptedOutDomainModel[]>(null);

  constructor(private httpClient: HttpClient, private appConfig: AppConfigService){}

  connect(collectionViewer: CollectionViewer): Observable<OptedOutDomainModel[]> {
    return this.dataSource.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    return this.dataSource.complete();
  }

  public loadData(sortDirection:string, filterQuery:string, pageIndex:number, itemsPerPage:number): Observable<OptedOutDomainsTableModel>{
    const httpParams = {
      'order': sortDirection === "" ? "asc" : sortDirection,
      'page': (pageIndex + this.pageOffset).toString(),
      'size': itemsPerPage
    };
    if (filterQuery && filterQuery.length > 0) {
      httpParams['keyword'] = filterQuery;
    }

    const hdr = this.appConfig.getHeaders(httpParams);

    return this.httpClient.get<OptedOutDomainsTableModel>(this.appConfig.getWalnutUrl('/OptedOutDomains'), hdr)
    .pipe(tap(res => {
        this.dataSource.next(res.domains);
      }));
  }

  public addDomain(domain: string) : Observable<any>{
    const body = {
      headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8' }),
      domain: domain
    };
    return this.httpClient.post<OptedOutDomainModel>(this.appConfig.getWalnutUrl('/OptedOutDomains'), body);
  }

  public deleteDomain(id: number) : Observable<any>{
    this.appConfig.appUrl
    return this.httpClient.delete<OptedOutDomainModel>(this.appConfig.getWalnutUrl('/OptedOutDomains/') + id);
  }
}
