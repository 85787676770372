import { RouteService } from './route.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Feature } from './feature';
import { FeatureToggle } from './feature-toggle';

@Injectable({
    providedIn: 'root'
  })
export class FeatureToggleGuard implements CanActivate {
    constructor(private routeService: RouteService) {
    }
    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
      this.routeService.feature = new Feature(FeatureToggle.Enabled, route.data.featureName);

      return true;
    }
}
