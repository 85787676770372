import { UserMessageService } from './../user-message.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unknown',
  templateUrl: './unknown.component.html',
  styleUrls: ['./unknown.component.css']
})
export class UnknownComponent implements OnInit {

  constructor(private messageService: UserMessageService) { }

  ngOnInit() {
    this.messageService.showError('Page not found.');
  }

}
