import { RouteService } from './route.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Feature } from './feature';
import { FeatureToggle } from './feature-toggle';

@Injectable({
  providedIn: 'root'
})
export class FeatureEnabledGuard implements CanActivate {
  constructor(private routeService: RouteService) {
  }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    this.routeService.feature = new Feature(FeatureToggle.Disabled, undefined);

    return true;
  }
}
