import { AuthorizationGuard } from './authorization.guard';
import { RouteService } from './route.service';
import { Injectable } from '@angular/core';
import { Permission } from './permission.enum';

@Injectable({
  providedIn: 'root'
})
export class EncoderAutomationGuard extends AuthorizationGuard {
  constructor(routeService: RouteService) {
    super(routeService, Permission.EncoderAutomation);
  }
}
