import { ProjectRoutesConfig } from '../models/project';

export const PROJECT_ROUTES_CONFIGURATION: ProjectRoutesConfig = {
  'live-streaming': {
    projectTypeFlag: 1,
    searchParamsMethodName: 'liveProjectsSearchParams',
    paginationMethodName: 'liveStreamingProjectsPageNumber',
    columnsToDisplay: [
      'projectId',
      'name',
      'time',
      'actualTime',
      'overridden',
      'facility',
      'room',
      'encoderName',
      'status',
      'completed',
      'numberOfErrors'
    ],
    columnsForToolbar: [
      { name: 'Region', value: 'region' },
      { name: 'Video Insights Region', value: 'videoInsightsRegion' },
      { name: 'Video Insights', value: 'videoInsights' },
      { name: 'Canceled', value: 'canceled' },
      { name: 'Halted', value: 'manuallyStopped' },
    ]
  },
  'intervu': {
    projectTypeFlag: 2,
    searchParamsMethodName: 'intervuProjectsSearchParams',
    paginationMethodName: 'interVuProjectsPageNumber',
    columnsToDisplay: [
      'projectId',
      'name',
      'time',
      'actualTime',
      'overridden',
      'facility',
      'encoderName',
      'status',
      'completed',
      'numberOfErrors'
    ]
  },
  'projects-at-risk': {
    projectTypeFlag: 3,
    searchParamsMethodName: 'atRiskProjectsSearchParams',
    paginationMethodName: 'projectsAtRiskPageNumber'
  },
  'all-projects': {
    projectTypeFlag: null,
    searchParamsMethodName: 'allProjectsSearchParams',
    paginationMethodName: 'allProjectsPageNumber',
    columnsToDisplay: [
      'projectId',
      'name',
      'time',
      'actualTime',
      'overridden',
      'projectType',
      'facility',
      'encoderName',
      'status',
      'completed',
      'numberOfErrors'
    ],
  },
  'defaultValues': {
    projectTypeFlag: null,
    searchParamsMethodName: '',
    paginationMethodName: '',
    columnsToDisplay: [
      'projectId',
      'name',
      'time',
      'actualTime',
      'overridden',
      'facility',
      'room',
      'encoderName',
      'status',
      'completed',
      'numberOfErrors'
    ],
    columnsForToolbar: [
      { name: 'Region', value: 'region' },
      { name: 'Video Insights Region', value: 'videoInsightsRegion' },
      { name: 'Video Insights', value: 'videoInsights' },
      { name: 'Canceled', value: 'canceled' },
      { name: 'Halted', value: 'manuallyStopped' },
      { name: 'Automated', value: 'automated' }
    ]
  }
};
