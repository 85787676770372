import { UserMessageService } from './user-message.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class Handle404 implements HttpInterceptor {
  constructor(private messageService: UserMessageService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => {
      if (error.status !== 404 || !error.error.errorSource) {
        return throwError(error);
      }

      this.messageService.showError(error.error.errorSource + ' missing.');

      return EMPTY;
    }));
  }
}
