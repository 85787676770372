import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ElementRef, OnDestroy } from '@angular/core';
import { MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { EncoderService } from '../../services/encoder.service';
import { LoginService } from '../../services/login.service';
import { AppConfigService } from '../../services/app-config.service';
import { PaginationService } from '../../services/pagination.service';
import { EncoderDataSource} from '../../models/encoder-data-source';
import { DynamicPlaceholders} from '../../models/search-filter.model';
import { LoadingSpinnerService } from '../../util/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-encoders',
  templateUrl: './encoders.component.html',
  styleUrls: ['./encoders.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * @ngdoc component
 * @name encoders.component:EncodersComponent
 * @description This component shows list of Encoders
 */
export class EncodersComponent implements OnInit, OnDestroy {
  public columnsToDisplay = ['name', 'mac', 'pcName', 'region', 'facility', 'room', 'encoderProjectType', 'statusName'];
  public pageSize = 10;
  public dataSource: EncoderDataSource;
  public encoderTypeFlag = 1;
  public searchPlaceholder: DynamicPlaceholders = {
    keyWord: 'Search by name, MAC, facility or room',
    filter: 'Filter list by status:'
  };
  private projectIdDefaultValue = undefined;
  private systemTypeDefaultValue = undefined;
  private encoderVersionStatus = undefined;
  private encodersAtRiskStatus = undefined;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('default', { read: ElementRef }) defaultHeader: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public currentRoute: string = this.router.url;

  constructor(
    private appConfig: AppConfigService,
    private loginService: LoginService,
    private encoderService: EncoderService,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private paginationService: PaginationService
  ) {}

  public ngOnInit(): void {
    this.getDataSource();
  }

  ngOnDestroy(): void {
    this.dataSource.unsubscribeAll();
  }

  /**
   * @ngdoc method
   * @name getRecord
   * @methodOf EncodersComponent
   * @description This method should navigate from "encoder name" label to "/encoder-detail" page
   */
  public getRecord(data): void {
    this.router.navigate(['encoder/encoder-detail', data.id]);
  }

  /**
   * @ngdoc method
   * @name detectDataSourceChange
   * @methodOf EncodersComponent
   * @description This method should get new data source form project-search component
   */
  public detectDataSourceChange(event: EncoderDataSource): void {
    this.dataSource = event;
  }

  /**
   * @ngdoc method
   * @name getDataSource
   * @methodOf EncodersComponent
   * @description This method should set dataSource
   */
  public getDataSource(): void {
    const key = '';
    const status = 'APPROVED';
    const projectType = 'ALL';

    this.dataSource = new EncoderDataSource(
      this.paginationService,
      this.appConfig,
      this.loginService,
      this.encoderService,
      this.spinnerService,
      this.sort,
      this.paginator,
      this.currentRoute,
      status,
      key,
      this.projectIdDefaultValue,
      this.systemTypeDefaultValue,
      this.encoderVersionStatus,
      this.encodersAtRiskStatus,
      projectType
    );
  }
}

