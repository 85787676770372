import { MandatoryClientDataModel } from "./mandatory-client-data-model";

export class MandatoryClientDataDialogConfirmationModel{
    constructor (
        public clientName: string,
        public mandatoryData: MandatoryClientDataModel,
        public modalTitle: string,
        public clientId: string
    ){}
}
