import { IComboBoxConfiguration } from '../shared/combo-box/combo-box.configuration';
import { ComboBoxItem } from '../shared/combo-box/model/combo-box.item';
import { ExpirationType } from '../models/expiration-type';

export class ExpirationTypeFilterConfiguration implements IComboBoxConfiguration {
    readonly options: ComboBoxItem<ExpirationType>[];

    constructor() {
        this.options = [
            new ComboBoxItem<ExpirationType>('All', null),
            new ComboBoxItem<ExpirationType>('Never', 'Never'),
            new ComboBoxItem<ExpirationType>('Default', 'Default'),
            new ComboBoxItem<ExpirationType>('Custom', 'Custom')
        ];
    }
    getInitialValue(): ComboBoxItem<any> {
        return this.options[0];
    }
    getOptions(): ComboBoxItem<any>[] {
        return this.options;
    }
    getPlaceHolderText(): string {
        return 'Pick filter by expiration rule';
    }

}
