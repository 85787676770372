import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Inject } from '@angular/core';
import { TimeUtil } from '../../util/time-util';

@Component({
  selector: 'app-overridden-times-modal',
  templateUrl: './overridden-times-modal.component.html',
  styleUrls: ['./overridden-times-modal.component.css']
})
export class OverriddenTimesModalComponent implements OnInit {

  form: FormGroup;

  public hoursList = this.getHoursArray();
  public minutesList = this.getMinutesArray();

  public dayPeriods = ['AM', 'PM'];
  public isDefault = false;
  public dataCopy;
  public validationMessage = '';

  constructor(private formBuilder: FormBuilder,
              private timeUtil: TimeUtil,
              public dialogRef: MatDialogRef<OverriddenTimesModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.dataCopy = JSON.parse((JSON.stringify(this.data)));
    this.form = this.formBuilder.group({
      actualStartTimeDate: this.timeUtil.getSimpleDateFormat(this.data.actualStartTime),
      actualStartTimeHour: this.timeUtil.getHours(this.data.actualStartTime),
      actualStartTimeMinute: this.timeUtil.getMinutes(this.data.actualStartTime),
      actualStartTimePeriod: this.timeUtil.getPeriod(this.data.actualStartTime),
      actualEndTimeDate: this.timeUtil.getSimpleDateFormat(this.data.actualEndTime),
      actualEndTimeHour: this.timeUtil.getHours(this.data.actualEndTime),
      actualEndTimeMinute: this.timeUtil.getMinutes(this.data.actualEndTime),
      actualEndTimePeriod: this.timeUtil.getPeriod(this.data.actualEndTime)
    });
    this.setDialogDataObject();
  }

  /**
   * @ngdoc method
   * @name submit
   * @methodOf SelectedVersionsDialogComponent
   * @description This method should submit modal dialog form and send 'OK' value if answer is 'OK'
   */
  submit() {
    if (!this.timeUtil.isDateValid(this.data.actualStartTime) || !this.timeUtil.isDateValid(this.data.actualEndTime)) {
      this.validationMessage = 'Please set valid date.';
    } else if (this.timeUtil.isDateTimeSame(this.timeUtil.getDateTimeUTCFormat(this.dataCopy.actualStartTime), this.data.actualStartTime) &&
       this.timeUtil.isDateTimeSame(this.timeUtil.getDateTimeUTCFormat(this.dataCopy.actualEndTime), this.data.actualEndTime)) {
      this.validationMessage = 'Please change actual start and end time. This times are currently set.';
    } else if (this.timeUtil.isDateToBeforeDateFrom(this.data.actualEndTime, this.data.actualStartTime)) {
      this.validationMessage = 'Actual start time should be before actual end time.';
    } else {
      this.dialogRef.close(`${'OK'}`);
    }
  }

  /**
   * @ngdoc method
   * @name cancel
   * @methodOf SelectedVersionsDialogComponent
   * @description This method should cancel modal dialog and send 'Cancel' value if answer is 'Cancel'
   */
  cancel() {
    this.dialogRef.close(`${'Cancel'}`);
  }

  public getMinutesArray() {
    const minutesArray = [];
    for (let i = -1; i < 59; i++) {
      minutesArray.push((i + 1).toString().length === 1 ? `0${(i + 1)}` : (i + 1).toString());
    }
    return minutesArray;
  }

  public getHoursArray() {
    const hoursArray = [];
    for (let i = 0; i < 12; i++) {
      hoursArray.push((i + 1).toString().length === 1 ? `0${(i + 1)}` : (i + 1).toString());
    }
    return hoursArray;
  }

  public onCheckBoxChange() {
    if (this.isDefault) {
      this.validationMessage = '';
      const defaultStartTime = this.timeUtil.addHoursToDate(this.dataCopy.startTime, -2);
      const defaultEndTime = this.timeUtil.addHoursToDate(this.dataCopy.endTime, 2);
      this.form = this.formBuilder.group({
        actualStartTimeDate: this.timeUtil.getSimpleDateFormat(defaultStartTime),
        actualStartTimeHour: this.timeUtil.getHours(defaultStartTime),
        actualStartTimeMinute: this.timeUtil.getMinutes(defaultStartTime),
        actualStartTimePeriod: this.timeUtil.getPeriod(defaultStartTime),
        actualEndTimeDate: this.timeUtil.getSimpleDateFormat(defaultEndTime),
        actualEndTimeHour: this.timeUtil.getHours(defaultEndTime),
        actualEndTimeMinute: this.timeUtil.getMinutes(defaultEndTime),
        actualEndTimePeriod: this.timeUtil.getPeriod(defaultEndTime),
      });

      this.setDialogDataObject();

    }
  }

  public setDialogDataObject() {
    const actualStartTime = this.timeUtil.formatFullDateFromDateHoursMinutesPeriod(
      this.form.value.actualStartTimeDate,
      this.form.value.actualStartTimeHour,
      this.form.value.actualStartTimeMinute,
      this.form.value.actualStartTimePeriod);

    const actualEndTime = this.timeUtil.formatFullDateFromDateHoursMinutesPeriod(
      this.form.value.actualEndTimeDate,
      this.form.value.actualEndTimeHour,
      this.form.value.actualEndTimeMinute,
      this.form.value.actualEndTimePeriod);

    this.data = { actualStartTime: actualStartTime, actualEndTime: actualEndTime, manuallyOverrideTime: !this.isDefault };
  }

}
