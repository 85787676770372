import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { TaniSessionTableModel } from "../models/tani/tani-session-table";
import { TaniSessionTableModelDto } from "../models/tani/tani-session-table-dto";
import { AppConfigService } from "./app-config.service";

@Injectable()
export class TaniDashboardSessionService implements DataSource<TaniSessionTableModel> {
    private dataSource: BehaviorSubject<TaniSessionTableModel[]> = new BehaviorSubject<TaniSessionTableModel[]>(null);

    constructor(private httpClient: HttpClient,
        private appConfig: AppConfigService
    ){}

    connect(collectionViewer: CollectionViewer): Observable<TaniSessionTableModel[]>{
        return this.dataSource.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void{
        return this.dataSource.complete();
    }

    public loadData(projectId: number, sortBy: string, sortDirection:string){
        const httpParams = {
            'projectId': projectId,
            'sortBy': sortBy,
            'order': sortDirection
        };

        const hdr = this.appConfig.getHeaders(httpParams);

        return this.httpClient.get<TaniSessionTableModelDto>(
            this.appConfig.getWalnutUrl('/tani/sessions'), hdr)
            .pipe(tap(res => {
            this.dataSource.next(res.sessions);
        }));
    }
}
