import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { MatSort, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { TitleService } from 'src/app/services/title.service';
import { LoadingSpinnerService } from 'src/app/util/loading-spinner/loading-spinner.service';
import { CustomMatPaginatorComponent } from 'src/app/feature/custom-mat-paginator/custom-mat-paginator.component';
import { AuditLogService } from '../../../services/audit-log.service';
import { TimezoneService } from 'src/app/services/timezone.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-purging-audit-log',
    templateUrl : './purging-audit-log.component.html',
    styleUrls: [
        './purging-audit-log.component.css'
    ]
})
export class PurgingAuditLogComponent implements OnInit, AfterViewInit, OnDestroy {
    public dataSource: AuditLogService;
    @ViewChild(CustomMatPaginatorComponent) paginator: CustomMatPaginatorComponent;
    @ViewChild(MatSort) sort: MatSort;
    public filter = new FormControl('', []);

    private currentFilter = '';
    private timezoneServiceSubscription: Subscription;

    constructor(public appConfig: AppConfigService, private httpClient: HttpClient, auditLogService: AuditLogService,
        private titleService: TitleService, private matDialog: MatDialog, private spinService: LoadingSpinnerService, private timezoneService: TimezoneService) {
        this.dataSource = auditLogService;
    }

    private columnsToDisplay = [
      'CreatedAt',
      'ClientID',
      'Event',
    ];
    ngOnInit(): void {
        this.sort.sortChange.subscribe(s => {
            this.paginator.pageIndex = 0;
            this.loadData();
        });
        this.paginator.page.subscribe(r => {
            this.loadData();
        });
        this.titleService.setTitle('Audit Logs');
        this.timezoneServiceSubscription = this.timezoneService.timezone.subscribe(() => {
          this.loadData();
        });
    }

    loadData() {
        this.spinService.spinnerSubject.next(true);
        this.dataSource.loadData(this.paginator.pageIndex,
            this.paginator.pageSize, this.sort.active, this.sort.direction, this.currentFilter,
            ).subscribe(r => {
            this.paginator.length = r.totalCount;
            this.paginator.moreExist = r.moreExist;
        }, e => {}, () => {
            this.spinService.spinnerSubject.next(false);
        });
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    search(): void {
        this.currentFilter = this.filter.value;
        this.paginator.pageIndex = 0;
        this.loadData();
    }

    clearFilter(): void {
        this.currentFilter = '';
        this.filter.reset();
        this.loadData();
    }

    ngOnDestroy(): void {
      this.timezoneServiceSubscription.unsubscribe();
    }
}
