import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Encoder, EncoderSelect, EncoderResponse, NewEncoderResponse, RiskEncoderResponse,
         FaultyEncoderResponse, EncoderLog, EncodersVersionResponse, EncoderFilesResponse } from './../models/encoder';
import { AppConfigService } from '../services/app-config.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

/**
 * @ngdoc service
 * @name encoder.service.EncoderService
 * @requires HttpClient
 * @description This service should handle all encoders data from API
 **/
export class EncoderService {

  private encoderFaultyData = new BehaviorSubject<Encoder>(null);
  private encoderFileUploadData = new BehaviorSubject<Encoder>(null);

  public encoderList: Encoder[];
  public headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
  public encodersTotalCount = new BehaviorSubject<number>(null);
  public encoderFilesTotalCount = new BehaviorSubject<number>(null);
  public logMessagesTotalCount = new BehaviorSubject<number>(null);
  public highestMessageId = new BehaviorSubject<number>(null);
  public encoderFaultyDataOutput = this.encoderFaultyData.asObservable();
  public encoderFileUploadDataOutput = this.encoderFileUploadData.asObservable();
  public encodersKeyword = new BehaviorSubject<string>(null);
  public encodersIsSelectedListOfActiveStatus = new BehaviorSubject<string>('APPROVED');
  public encodersVersionStatus = new BehaviorSubject<string>(null);
  public encodersAtRiskStatus = new BehaviorSubject<string>(null);
  public encodersProjectType = new BehaviorSubject<string>(null);
  public encodersAtRiskKeyword = new BehaviorSubject<string>(null);
  public encodersNewKeyword = new BehaviorSubject<string>(null);
  public encodersVersionKeyword = new BehaviorSubject<string>(null);
  public encodersWithErrorKeyword = new BehaviorSubject<string>(null);
  public encodersWithErrorProjectId = new BehaviorSubject<string>(null);
  public cancelButtonMessageOnEncoderModal = new BehaviorSubject<string>(null);
  public activationTypeMessageOnEncoderModal = new BehaviorSubject<string>(null);


  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.encoderList = [];
  }

  /**
   * @ngdoc method
   * @name getEncoders
   * @methodOf EncoderService
   * @params routeParam, page, isListOfActive - optional, keyWord - optional
   * @returns Observable< Encoder(Type)Response[]> Observable list of encoders
   * @description This method should return list of encoders from API
   */
  public getEncoders(routeParam, page, sortField, order, systemType?: string,  isListOfActive?, keyWord?, projectId?, encoderVersionStatus?, encodersAtRiskStatus?, encodersProjectType?): Observable<Encoder[]> {
    let body = new HttpParams();
    body = body.set('page', page + 1);
    body = body.set('size', '10');

    if (systemType && systemType.length) {
      body = body.set('systemType', systemType);
    }

    if (isListOfActive && isListOfActive !== 'ALL') {
      body = body.set('status', isListOfActive);
    }

    if (encodersProjectType && encodersProjectType !== 'ALL') {
      body = body.set('projectType', encodersProjectType);
    }

    if (sortField !== undefined && sortField.length !== 0) {
      body = body.set('field', sortField);
    }

    if (order !== undefined && order.length !== 0) {
      body = body.set('order', order);
    }

    if (typeof keyWord === 'string' && keyWord.length !== 0) {
      body = body.set('keyword', keyWord);
    }

    if (projectId !== null && projectId !== undefined && projectId.length !== 0) {
      body = body.set('projectId', projectId);
    }

    if (encoderVersionStatus && encoderVersionStatus !== 'ALL') {
      body = body.set('status', encoderVersionStatus);
    }

    if (encodersAtRiskStatus && encodersAtRiskStatus !== 'ALL') {
      body = body.set('status', encodersAtRiskStatus);
    }

    const url = this.getUrlByRoute(routeParam);

    switch (routeParam) {
      case 'encoders': {
        return this.http.get<EncoderResponse>(this.appConfig.getUrl(url), this.appConfig.getHeaders(body)).pipe(map(res => {
          this.encodersTotalCount.next(res.totalCount);
          return res.availableEncoders;
        }));
      }
      case 'encoders-new': {
        return this.http.get<NewEncoderResponse>(this.appConfig.getUrl(url), this.appConfig.getHeaders(body)).pipe(map(res => {
          this.encodersTotalCount.next(res.totalCount);
          return res.newEncoders;
        }));
      }
      case 'encoders-at-risk': {
        return this.http.get<RiskEncoderResponse>(this.appConfig.getUrl(url), this.appConfig.getHeaders(body)).pipe(map(res => {
          this.encodersTotalCount.next(res.totalCount);
          return res.riskEncoders;
        }));
      }
      case 'encoders-faulty': {
        return this.http.get<FaultyEncoderResponse>(this.appConfig.getUrl(url), this.appConfig.getHeaders(body)).pipe(map(res => {
          this.encodersTotalCount.next(res.totalCount);
          return res.faultyEncoders;
        }));
      }
      case 'encoders-version': {
        return this.http.get<EncodersVersionResponse>(this.appConfig.getUrl(url), this.appConfig.getHeaders(body)).pipe(map(res => {
          this.encodersTotalCount.next(res.totalCount);
          return res.encoderSoftwareVersions;
        }));
      }

    }

  }

  /**
   * @ngdoc method
   * @name getOneEncoder
   * @params id
   * @methodOf EncoderService
   * @returns Observable< Encoder[]> Observable list of encoders
   * @description This method should return single encoder from API
   */
  public getOneEncoder(id): Observable<Encoder> {
    const url = this.getUrlByRoute('encoder-detail', id);
    return this.http.get<Encoder>(this.appConfig.getUrl(url), this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name getEncoderLog
   * @params id
   * @methodOf EncoderService
   * @returns Observable< Encoder[]> Observable list of encoder log
   * @description This method should return log for encoder from API
   */
  public getEncoderLog(id, routeParam, page,  sortField, order): Observable<EncoderLog> {

    let body = new HttpParams();
    body = body.set('page', page + 1);
    body = body.set('size', '10');

    if (sortField !== undefined && sortField.length !== 0) {
      body = body.set('field', sortField);
    }

    if (order !== undefined && order.length !== 0) {
      body = body.set('order', order);
    }

    const url = this.getUrlByRoute(routeParam, id);

    switch (routeParam) {

      case 'encoder-log': {
        return this.http.get<EncoderLog>(this.appConfig.getUrl(url), this.appConfig.getHeaders(body)).pipe(map(res => {
          this.logMessagesTotalCount.next(res.totalCount);
          this.highestMessageId.next(res.highestMessageId);
          return res;
        }));
      }

      case 'encoder-log-for-project': {
        return this.http.get<EncoderLog>(this.appConfig.getUrl(url), this.appConfig.getHeaders(body)).pipe(map(res => {
          this.logMessagesTotalCount.next(res.totalCount);
          return res;
        }));
      }

    }

  }

  /**
   * @ngdoc method
   * @name markErrorsFixed
   * @params encoderId, highestMessageId
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should mark errors fixed
   */
  public markErrorsFixed(encoderId, highestMessageId): Observable<any> {
    const url = this.getUrlByRoute('mark-errors-fixed', encoderId);
    return this.http.post(this.appConfig.getUrl(url),
      {'highestMessageId': highestMessageId},
            this.appConfig.getHeaders()).pipe(map(res => {}));
  }

  /**
   * @ngdoc method
   * @name activateEncoder
   * @params Encoder
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should activate encoder
   */
  public activateEncoder(encoderId): Observable<any> {
    const url = this.getUrlByRoute('activate', encoderId);
    return this.http.post(this.appConfig.getUrl(url), this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name deactivateEncoder
   * @params Encoder
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should deactivate encoder
   */
  public deactivateEncoder(encoderId): Observable<any> {
    const url = this.getUrlByRoute('deactivate', encoderId);
    return this.http.post(this.appConfig.getUrl(url), this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name updateEncoder
   * @params Encoder
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should update encoder
   */
  public updateEncoder(encoder: Encoder): Observable<any> {
    const url = this.getUrlByRoute('update', encoder.id);
    return this.http.put<Encoder>(this.appConfig.getUrl(url), encoder, this.appConfig.getHeaders()).pipe(map(res => {}));
  }

  /**
   * @ngdoc method
   * @name approveEncoder
   * @params Encoder
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should approve new encoder and save default params
   */
  public approveEncoder(encoder: Encoder): Observable<any> {
    const url = this.getUrlByRoute('approve', encoder.id);
    return this.http.post<Encoder>(this.appConfig.getUrl(url), encoder, this.appConfig.getHeaders());
  }


  /**
   * @ngdoc method
   * @name getEncodersForProject
   * @params projectId
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should return encoders for specific project related to projectId
   */
  public getEncodersForProject(projectId): Observable<EncoderSelect[]> {
    const url = this.getUrlByRoute('encoders-for-project', projectId);
    return this.http.get<EncoderSelect[]>(this.appConfig.getUrl(url), this.appConfig.getHeaders());
  }

  /**
   * @ngdoc method
   * @name setEncoderFaultyData
   * @params encoder
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should set encoder from Encoder Faulty page for subscribing on Encoder Log Component
   */
  public setEncoderFaultyData(encoder: Encoder) {
    this.encoderFaultyData.next(encoder);
  }

  /**
   * @ngdoc method
   * @name setEncoderFileUploadData
   * @params encoder
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should set encoder from Encoder list page for subscribing on Encoder Upload Component
   */
  public setEncoderFileUploadData(encoder: Encoder) {
    this.encoderFileUploadData.next(encoder);
  }

  /**
   * @ngdoc method
   * @name deleteEncoder
   * @params id
   * @methodOf EncoderService
   * @returns TODO
   * @description This method should delete single encoder
   */
  public deleteEncoder(id) {
    const url = this.getUrlByRoute('encoder-delete', id);
    return this.http.delete(this.appConfig.getUrl(url), this.appConfig.getHeaders()).pipe(map(res => {}));
  }

  /**
   * @ngdoc method
   * @name getFiles
   * @methodOf EncoderService
   * @description This method should return files
   */
  public getFiles(encoderKey): Observable<EncoderFilesResponse> {
    return this.http.get<EncoderFilesResponse>(this.appConfig.getUrl(`/encoders/${encoderKey}/files`),
      this.appConfig.getHeaders()).pipe(map(files => {
        this.encoderFilesTotalCount.next(files.filenames.length);
        return files;
    }));
  }

  /**
   * @ngdoc method
   * @name uploadFile
   * @params version
   * @methodOf EncoderService
   * @description This method should upload file
   */
  public uploadFile(fileName, encoderKey): Observable<any> {
    return this.http.put<any>(this.appConfig.getUrl(
      `/encoders/${encoderKey}/uploads/${fileName}`),
          this.appConfig.getHeaders()).pipe(map(res => {}));
  }

  /**
   * @ngdoc method
   * @name getUrlByRoute
   * @methodOf EncoderService
   * @params routeParam
   * @returns string Specific apiURL
   * @description This method should return URL related depending on route
   */
  getUrlByRoute(routeParam, id?) {

    let url: string;

    switch (routeParam) {
      case 'encoders': {
        url = `/encoders/available`;
        break;
      }
      case 'encoders-new': {
        url = `/encoders/new`;
        break;
      }
      case 'encoders-at-risk': {
        url =  `/encoders/risk`;
        break;
      }
      case 'encoders-faulty': {
        url =  `/encoders/faulty`;
        break;
      }
      case 'encoder-detail': {
        url =  `/encoders/${id}`;
        break;
      }
      case 'encoder-log': {
        url =  `/encoders/${id}/log`;
        break;
      }
      case 'encoder-log-for-project': {
        url =  `/encoders/project/${id}/log`;
        break;
      }
      case 'mark-errors-fixed':
      {
        url = `/encoders/${id}/mark-errors-fixed`;
        break;
      }
      case 'activate':
      {
        url = `/encoders/${id}/activate`;
        break;
      }
      case 'approve':
      {
        url = `/encoders/${id}/activate-new`;
        break;
      }
      case 'deactivate':
      {
        url = `/encoders/${id}/deactivate`;
        break;
      }
      case 'update':
      {
        url =  `/encoders/${id}`;
        break;
      }
      case 'encoders-for-project':
      {
        url =  `/encoders/projects/${id}`;
        break;
      }
      case 'encoders-version':
      {
        url =  `/update/encoders/` ;
        break;
      }
      case 'encoder-delete':
      {
        url = `/encoders/${id}`;
        break;
      }
    }

    return url;

  }

}
